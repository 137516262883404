import { gql } from '@apollo/client';

export const RECAPTCHA_KEY_CONFIG = gql`
  query RECAPTCHA_KEY_CONFIG {
    config {
      reCaptchaSiteKey: string(name: "reCaptchaSiteKey")
    }
  }
`;

export const INVISIBLE_RECAPTCHA_KEY_CONFIG = gql`
  query INVISIBLE_RECAPTCHA_KEY_CONFIG {
    config {
      invisibleRecaptchaSiteKey: string(name: "invisibleRecaptchaSiteKey")
    }
  }
`;

export const VERIFY_RECAPTCHA = gql`
  mutation VERIFY_RECAPTCHA($token: String!, $isInvisible: Boolean) {
    isValidReCaptcha: verifyRecaptcha(token: $token, isInvisible: $isInvisible) 
  }
`;
