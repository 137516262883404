import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  ErrorMessage,
  Icon,
  LegalText,
} from 'anf-core-react';
import { useWindowWidth } from '@react-hook/window-size';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import ButtonState from '../Common/ButtonState/ButtonState';
import { refProp, textProp } from '../../tools/customProps';
import TmntHtml from '../Common/Text/TmntHtml';
import TmntText from '../Common/Text/TmntText';

const CheckboxProps = PropTypes.shape({
  description: textProp,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  optionValue: PropTypes.string.isRequired,
  text: textProp.isRequired,
});

const CheckboxGroupProps = PropTypes.shape({
  label: textProp.isRequired,
  options: PropTypes.arrayOf(CheckboxProps).isRequired,
});

const SectionedCheckboxGroupProps = PropTypes.shape({
  label: textProp.isRequired,
  optionSections: PropTypes.arrayOf(
    PropTypes.arrayOf(CheckboxProps),
  ).isRequired,
});

const propTypes = {
  checkboxGroups: PropTypes.arrayOf(PropTypes.oneOfType([
    CheckboxGroupProps,
    SectionedCheckboxGroupProps,
  ])).isRequired,
  formRef: refProp,
  isEdited: PropTypes.bool,
  isErrorState: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onUnsuccessfulSubmit: PropTypes.func,
  text: PropTypes.shape({
    cancel: textProp,
    formError: textProp,
    legalLinks: textProp,
    pleaseTryAgain: textProp,
    processing: textProp,
    save: textProp,
    success: textProp,
    legalDisclaimer: textProp,
  }),
};

const defaultProps = {
  formRef: undefined,
  isEdited: false,
  isErrorState: false,
  onCancel: undefined,
  onSubmit: undefined,
  onUnsuccessfulSubmit: undefined,
  text: {
    cancel: undefined,
    formError: undefined,
    legalLinks: undefined,
    pleaseTryAgain: undefined,
    processing: undefined,
    save: undefined,
    success: undefined,
    legalDisclaimer: undefined,
  },
};

export default function MarketingPreferencesForm({
  checkboxGroups,
  formRef,
  isEdited,
  isErrorState,
  onCancel,
  onSubmit,
  onUnsuccessfulSubmit,
  text,
}) {
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  /* Member variables */
  const {
    cancel,
    formError,
    legalLinks,
    pleaseTryAgain,
    processing,
    save,
    success,
    legalDisclaimer,
  } = text;

  /* Render */
  const renderCheckboxDescription = ({ desc, title }) => (
    <>
      <span className="h4">
        <TmntText tmnt={title} />
      </span>
      { desc && <TmntText tmnt={desc} /> }
    </>
  );

  const renderCheckbox = (box) => {
    const {
      description,
      isChecked,
      onChange,
      optionValue,
    } = box;

    const value = optionValue;
    const id = `marketingPreferencesCheckbox_${value}`;

    return (
      <Checkbox
        description={renderCheckboxDescription({
          desc: description,
          title: box.text,
        })}
        isChecked={isChecked}
        id={id}
        key={id}
        /*
          Note: Checkbox anf-core-react v7.129.0 "name" property was dropped and uses
          the "description" parameter. However, it is a node and can technically be
          an object. This will need to updated, leaving in hope support for "name"
          will return.
        */
        name="marketingPreferencesCheckbox[]"
        onChange={onChange}
        value={value}
      />
    );
  };

  const renderCheckboxGroup = ({ group, title }) => {
    const type = group?.__typename; // eslint-disable-line no-underscore-dangle
    return (
      <CheckboxGroup title={title}>
        {
          type !== 'FieldOptionList' ? (
            // SectionedFieldOptionList
            group.optionSections.map((section, index) => {
              const i = index;
              return (
                <React.Fragment key={`marketingPrefsSectionFragment_${i}`}>
                  { section.map((box) => renderCheckbox(box)) }
                  <hr />
                </React.Fragment>
              );
            })
          ) : (
            <>
              {
                // FieldOptionList
                group.options.map((box, index) => {
                  const i = index;
                  return (
                    <React.Fragment key={`marketingPrefsCheckboxFragment_${i}`}>
                      { renderCheckbox(box) }
                    </React.Fragment>
                  );
                })
              }
              <hr />
            </>
          )
        }
      </CheckboxGroup>
    );
  };

  useEffect(() => { setWindowWidth(retrievedWindowWidth); }, [retrievedWindowWidth]);

  return (
    <form
      className="marketing-preferences-form"
      name="marketingPreferencesForm"
      onSubmit={(e) => e.preventDefault()}
      ref={formRef}
    >
      <FormWrapper>
        <FormGroup>
          {
            checkboxGroups.map((group, index) => {
              const { label } = group;
              const i = index;
              return (
                <FormCell key={`marketingPreferencesGroup${i}`}>
                  { renderCheckboxGroup({ group, title: <TmntText tmnt={label} /> }) }
                </FormCell>
              );
            })
          }
          <TmntText tmnt={legalDisclaimer} />
          {
            legalLinks && (
              <FormCell>
                <LegalText>
                  <TmntHtml tmnt={legalLinks} />
                </LegalText>
              </FormCell>
            )
          }
          {
            isErrorState && formError && (
              <FormCell>
                <ErrorMessage id="marketingPreferencesForm_errorMessage">
                  <TmntText tmnt={formError} />
                </ErrorMessage>
              </FormCell>
            )
          }
          <FormCell>
            <ButtonGroup variant={windowWidth > 700 ? 'horizontal-spaced' : 'vertical-spaced'}>
              <ButtonState
                asyncOnClick={onSubmit}
                onError={onUnsuccessfulSubmit}
                initial={(
                  <Button type="submit" variant="secondary" isDisabled={!isEdited}>
                    <TmntText tmnt={save} />
                  </Button>
                )}
                processing={(
                  <Button variant="secondary" isProcessing isDisabled>
                    <TmntText tmnt={processing} />
                  </Button>
                )}
                error={(
                  <Button variant="secondary" isDisabled>
                    <TmntText tmnt={pleaseTryAgain} />
                  </Button>
                )}
                success={(
                  <Button variant="secondary" isDisabled>
                    <Icon icon="check" />
                    <TmntText tmnt={success} />
                  </Button>
                )}
              />
              <Button
                isDisabled={!isEdited}
                variant="borderless"
                onClick={onCancel}
              >
                <TmntText tmnt={cancel} />
              </Button>
            </ButtonGroup>
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

MarketingPreferencesForm.propTypes = propTypes;
MarketingPreferencesForm.defaultProps = defaultProps;
