import React from 'react';
import { ErrorMessage, InputField } from 'anf-core-react';
import EmailInputField from '../Common/EmailInputField/EmailInputField';
import PasswordField from '../Common/PasswordInputField/PasswordField';
import { TmntText } from '../Common/Text';
import maskEmail from '../../tools/maskEmail';
import { StepOnePropTypes, StepOneDefaultProps } from './props';

const noop = () => {};

export default function StepOne({
  control,
  email,
  fields,
  isRemembered,
  onEmailFocus,
  onPasswordFocus,
  password,
  formId,
  showLabel,
  hideLabel,
}) {
  const renderEmailField = ({
    error,
    id,
    label,
    name,
  }) => {
    if (!isRemembered) {
      return (
        <EmailInputField
          control={control}
          defaultValue={email}
          id={id}
          isRequired
          key={id}
          label={label?.value}
          name={name}
          onFocus={onEmailFocus}
        >
          <ErrorMessage>
            <TmntText tmnt={error} />
          </ErrorMessage>
        </EmailInputField>
      );
    }

    return ( // Remembered state, no validation
      <React.Fragment key={id}>
        <input
          hidden
          id={id}
          name={name}
          onChange={noop}
          value={email}
        />
        <InputField
          autoComplete="off"
          id={`masked-${id}`}
          isDisabled
          isRequired
          label={label?.value}
          name={`masked-${name}`}
          onChange={noop}
          onFocus={onEmailFocus}
          value={maskEmail(email)}
        />
      </React.Fragment>
    );
  };

  const renderField = (field) => {
    const { name, label, error } = field;
    const formState = control.getFieldState(name);
    const errorMessage = formState?.error?.message || error;
    const id = `sign-in-${name}`;

    switch (name) {
      case 'email':
        return renderEmailField({
          error: errorMessage,
          id,
          label,
          name,
        });
      case 'password':
        return (
          <PasswordField
            control={control}
            defaultValue={password}
            id={id}
            key={id}
            label={label?.value}
            showLabel={showLabel}
            hideLabel={hideLabel}
            name={name}
            onFocus={onPasswordFocus}
            autoComplete={formId === 'join-form' ? 'new-password' : 'password'}
          >
            <ErrorMessage>
              <TmntText tmnt={errorMessage} />
            </ErrorMessage>
          </PasswordField>
        );
      default:
        return null;
    }
  };

  const renderFields = (currentFields) => currentFields?.map((field) => renderField(field));

  return renderFields(fields);
}

StepOne.propTypes = StepOnePropTypes;
StepOne.defaultProps = StepOneDefaultProps;
