import React from 'react';
import { propTypes, defaultProps } from './props';
import ControlledPasswordInputField from './ControlledPasswordInputField';

export default function PasswordField(props) {
  const { control } = props;

  if (!control) {
    // eslint-disable-next-line no-console
    console.warn(
      `${PasswordField.name} warning - no control passed please use "PasswordInputField with usePasswordValidaton"`,
    );

    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ControlledPasswordInputField {...props} control={control} />;
}

PasswordField.propTypes = propTypes;
PasswordField.defaultProps = defaultProps;
