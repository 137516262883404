import React, { useState } from 'react';
import {
  FieldGroup,
  Icon,
  Link as DSLink,
  PipeDelimitedLinks,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { Form } from '../Common/Form';
import { FormErrorCell, LoyaltyConversionToggle } from './SignInFormHelpers';
import { KEEP_ME_SIGN_IN_INPUT_NAME } from '../Common/KeepMeSignedIn/KeepMeSignedInCheckbox';
import { REMEMBERED_SIGN_IN_FORM_QUERY } from './operations';
import { LOYALTY_CONVERSION_INPUT_NAME } from './LoyaltyConversion';
import { TmntText } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import KeepMeSignedIn from '../Common/KeepMeSignedIn/KeepMeSignedIn';
import StepOne from './StepOne';
import useButtonState from '../Common/ButtonState/useButtonState';
import { useSignInFormLogin } from '../../hooks';
import { RememberedSignInFormPropTypes, RememberedSignInFormDefaultProps } from './props';
import ModalContextProvider from '../../context/ModalContext/ModalContextProvider';
import CreateAPasswordModal from '../CreateAPasswordFlow/CreateAPasswordModal';

let textCache = null;

export default function RememberedSignInForm({
  email,
  onFailedSubmission,
  onNotYouClick,
  onSuccessfulSubmission,
}) {
  const [submitCall, setSubmitCall] = useState();
  const [renderButton, registerPromise] = useButtonState();

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    setError,
  } = useForm();

  const [runLogin, isLoyaltyConversionShown] = useSignInFormLogin({
    getValues,
    getPrimaryFormError: () => textCache?.errorSomethingWentWrong,
    onFailedSubmission,
    onSuccessfulSubmission,
    setError,
  });

  const {
    data = {}, loading: queryLoading, error: queryError,
  } = useQuery(REMEMBERED_SIGN_IN_FORM_QUERY);

  /* Handlers */
  const handleOnInvalidForm = () => setError('form', { message: textCache?.rememberedSignInForm?.error });

  const handleOnValidForm = async (formData) => {
    const { password } = formData;

    const variables = {
      email,
      password,
      keepMeSignedIn: formData[KEEP_ME_SIGN_IN_INPUT_NAME],
      isLoyaltyConversionTermsAccepted: formData[LOYALTY_CONVERSION_INPUT_NAME],
    };

    try {
      const call = new Promise((resolve, reject) => {
        runLogin({ variables })
          .then(({ data: responseData }) => {
            const { login } = responseData;
            const { success } = login;
            if (!success) reject();
            else resolve();
          })
          .catch(() => reject());
      });

      setSubmitCall(call);
      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleOnSubmit = (event) => {
    clearErrors();
    return handleSubmit(handleOnValidForm, handleOnInvalidForm)(event);
  };

  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { rememberedSignInForm = {} } = textFor;

  textCache = textFor;

  const renderFormLabel = (text, isLoading) => {
    const { label, welcomeMessage, enterPassword } = text;

    return (
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={isLoading}>
          <p className="h2">
            <TmntText tmnt={label} />
          </p>
        </FormStructure.FormCell>
        <FormStructure.FormCell isLoading={isLoading} align="center">
          <p className="h3">
            <TmntText tmnt={welcomeMessage} />
          </p>
          <p>
            <TmntText tmnt={enterPassword} />
          </p>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
    );
  };

  const renderPrimaryGroup = (groups = []) => {
    if (groups?.length < 1) return null;

    const group = groups[0];
    const { label, fieldList } = group;

    /* Only one primary group - email/password */
    return (
      <FieldGroup legend={label?.value}>
        <StepOne
          control={control}
          email={email}
          fields={fieldList}
          isRemembered
        />
      </FieldGroup>
    );
  };

  const {
    id = 'sign-in-form',
    label,
    formGroupList,
  } = rememberedSignInForm;

  return (
    <Form
      id={`remembered-${id}`}
      label={
        renderFormLabel(
          {
            label,
            welcomeMessage: textFor?.welcomeMessage,
            enterPassword: textFor?.enterPassword,
          },
          queryLoading,
        )
      }
      isValidated={false}
      onSubmit={handleOnSubmit}
    >
      <FormStructure.FormGroup>
        <FormStructure.FormCell isLoading={queryLoading}>
          { renderPrimaryGroup(formGroupList) }
        </FormStructure.FormCell>
        <FormStructure.FormCell isLoading={queryLoading}>
          <KeepMeSignedIn
            control={control}
            id="remembered-keep-me-signed-in"
          />
        </FormStructure.FormCell>
        <LoyaltyConversionToggle
          control={control}
          isLoading={queryLoading}
          isLoyaltyConversionShown={isLoyaltyConversionShown}
          submitCall={submitCall}
        >
          <FormErrorCell formState={formState} />
          <FormStructure.FormCell isLoading={queryLoading}>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={textFor?.signIn} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={textFor?.processing} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={textFor?.pleaseTryAgain} />),
                  isFullWidth: true,
                  variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={textFor?.success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormStructure.FormCell>
          <FormStructure.FormCell isLoading={queryLoading} align="center">
            <PipeDelimitedLinks
              links={[
                <ModalContextProvider>
                  <CreateAPasswordModal id="forgot-password" />
                </ModalContextProvider>,
                <DSLink onClick={onNotYouClick} align="center" key="not-you-link">
                  <TmntText tmnt={textFor?.notYou} />
                </DSLink>,
              ]}
            />
          </FormStructure.FormCell>
        </LoyaltyConversionToggle>
      </FormStructure.FormGroup>
    </Form>
  );
}

RememberedSignInForm.propTypes = RememberedSignInFormPropTypes;
RememberedSignInForm.defaultProps = RememberedSignInFormDefaultProps;
