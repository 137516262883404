import React from 'react';
import { Button } from 'anf-core-react';
import Icon from '../Common/Icon/Icon';

export const ERROR_MESSAGE = (
  <p role="alert" data-testid="generic-error-message">
    Looks like we are having a problem, and are working to resolve the issue.
    Please try again in a few minutes.
  </p>
);

export const LOADING_MESSAGE = <p data-testid="generic-loading-message">Loading...</p>;

export function LoadingButton() {
  return (
    <Button classList="loading" variant="borderless" isDisabled>
      <Icon icon="spinner" />
      { LOADING_MESSAGE }
    </Button>
  );
}
