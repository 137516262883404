import React from 'react';
import PropTypes from 'prop-types';
import LoggedOutMenu from './LoggedOutMenu';
import LoggedInMenu from './LoggedInMenu';

const propTypes = { isLoggedIn: PropTypes.bool, location: PropTypes.string };
const defaultProps = { isLoggedIn: false, location: undefined };

export default function SignInMenu({ isLoggedIn, location }) {
  const isMobile = location === 'mobile-toaster';

  return (
    <div className="signin-menu">
      {
        isLoggedIn
          // MobileView is to pass the orientation='stacked' to DiscountCard Component
          ? <LoggedInMenu isMobileView={isMobile} />
          : <LoggedOutMenu isMobileToaster={isMobile} />
      }
    </div>
  );
}

SignInMenu.propTypes = propTypes;
SignInMenu.defaultProps = defaultProps;
