import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import React, { useEffect, useState } from 'react';
import { TmntText } from '../Common/Text';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import PointsRecord from './PointsRecord';
import { GET_POINTS_HISTORY } from './operations';

export default function PointsList() {
  const {
    data,
    loading,
    error,
    refetch,
  } = useQuery(GET_POINTS_HISTORY);

  const [numberOfRecordsToShow,
    setNumberOfRecordsToShow] = useState(0);

  const handleLoadMore = () => {
    setNumberOfRecordsToShow(data.pointsHistory.pointsHistoryItems.length);
    refetch();
  };
  useEffect(
    () => {
      if (data) {
        setNumberOfRecordsToShow(data.pointsHistory.intitialNumberOfRecordsToShow);
      }
    },
    [data],
  );

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;
  const { pointsHistory: { pointsHistoryItems, intitialNumberOfRecordsToShow }, textFor } = data;
  const showLoadMoreButton = pointsHistoryItems.length > intitialNumberOfRecordsToShow
  && numberOfRecordsToShow < pointsHistoryItems.length;
  return (
    <div className="points-list" data-testid="points-list">
      <span className="screen-reader-text"><TmntText tmnt={textFor.listDescription} /></span>
      {pointsHistoryItems.length === 0 ? (
        <p className="points-list__no-records">
          <TmntText tmnt={textFor.noRecords} />
        </p>
      ) : (
        <>
          <ul className="points-list__records">
            {pointsHistoryItems.slice(0, numberOfRecordsToShow).map(({
              id,
              createdAt,
              details,
              isGiftTier,
              pointBalance,
              status,
            }) => (
              <PointsRecord
                key={`points-record-${id}`}
                createdAt={createdAt}
                details={details}
                isGiftTier={isGiftTier}
                pointBalance={pointBalance}
                status={status}
              />
            ))}
          </ul>
          {showLoadMoreButton && (
            <div className="points-list__load-more">
              <Button
                variant="tertiary-light"
                isFullWidth
                onClick={handleLoadMore}
              >
                <TmntText tmnt={textFor?.buttonLoadMore} />
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
