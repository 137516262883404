import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Heading from './PickedForYouHeading';
import { textPairFragment } from '../../query/fragments';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import RecommendationSlidersContainer from '../Recommendations/RecommendationSlidersContainer';

const pickedForYouAuthQuery = gql`
  query PickedForYouText {
    textFor {
      pickedForYou: pair(pairKey: "pickedForYou") {...textPair}
      pickedForYouAuthSubText: pair(pairKey: "pickedForYouAuthSubText") {...textPair}
    }
  }

  ${textPairFragment}
`;

export default function PickedForYouAuthenticatedExperience() {
  const { loading, error, data } = useQuery(pickedForYouAuthQuery);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;

  const {
    pickedForYou,
    pickedForYouAuthSubText,
  } = textFor;

  const recommendationsProps = {
    clientId: 'picked-for-you-auth-1',
    placements: [
      'generic_page.PFU1',
      'generic_page.PFU2',
      'generic_page.PFU3',
    ],
  };

  return (
    <PageContentWrapper mainClass="picked-for-you">
      <div className="picked-for-you-auth">
        <div className="auth-header">
          <Heading
            headingText={pickedForYou}
            subText={pickedForYouAuthSubText}
          />
        </div>
        <RecommendationSlidersContainer
          clientId={recommendationsProps.clientId}
          placements={recommendationsProps.placements}
        />
      </div>
    </PageContentWrapper>
  );
}
