import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useMutation, useQuery } from '@apollo/client';
import { INVISIBLE_RECAPTCHA_KEY_CONFIG, VERIFY_RECAPTCHA } from './operations';
import { ERROR_MESSAGE } from '../../Messages/Messages';
import { invisibleReCaptchaPropTypes } from './props';

const InvisibleReCaptcha = forwardRef(({ onValidation }, ref) => {
  const { loading, error: queryError, data: queryData } = useQuery(INVISIBLE_RECAPTCHA_KEY_CONFIG);

  const [verifyRecaptchaMutation] = useMutation(VERIFY_RECAPTCHA, {
    onCompleted: ({ isValidReCaptcha }) => { onValidation({ isValid: isValidReCaptcha }); },
    onError: () => { onValidation({ isValid: false }); },
  });

  if (loading) return null;
  if (queryError) return ERROR_MESSAGE;

  const { invisibleRecaptchaSiteKey } = queryData.config;

  const handleRecaptchaChange = async (token) => {
    if (!token) return;
    verifyRecaptchaMutation({ variables: { token, isInvisible: true } });
  };

  return (
    <ReCAPTCHA
      data-testid="invisible-recaptcha"
      onChange={handleRecaptchaChange}
      ref={ref}
      sitekey={invisibleRecaptchaSiteKey}
      size="invisible"
    />
  );
});

InvisibleReCaptcha.propTypes = invisibleReCaptchaPropTypes;

export default InvisibleReCaptcha;
