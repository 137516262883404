import { useQuery } from '@apollo/client';
import {
  DataCard,
  Icon,
  IconBlock,
  ProgressBar,
} from 'anf-core-react';
import React from 'react';
import Loading from '../Common/Loading/Loading';
import TmntText from '../Common/Text/TmntText';
import * as FormStructure from '../FormStructure';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { LOYALTY_POINTS_CARD_QUERY } from './operations';

export default function LoyaltyPointsCard() {
  const { loading, error: queryError, data: queryData = {} } = useQuery(LOYALTY_POINTS_CARD_QUERY, {
    ssr: false,
    context: { batch: true },
  });

  if (queryError) return ERROR_MESSAGE;

  const {
    myPointsDescription,
    pointsForReview,
    pointsForSubmittingReviewDesc,
    pointsForAddingPhoto,
    pointsForAddingAPhotoDesc,
  } = queryData?.textFor || {};

  const { maxProgressBarValue, currentProgressBarValue } = queryData?.config || {};

  const myRewardDescription = () => (
    <div className="write-a-review-next-reward-until">
      <IconBlock
        orientation="horizontal"
        icon={<Icon icon="star-filled" size="s" />}
      >
        <TmntText tmnt={myPointsDescription} />
      </IconBlock>
    </div>
  );

  const pointsForReviewDescription = () => (
    <div className="write-a-review-points-desc">
      <div className="points-for-review-block">
        <TmntText classNames="h4" tmnt={pointsForReview} />
        <TmntText tmnt={pointsForSubmittingReviewDesc} />
      </div>
      <div className="points-for-adding-photo-block">
        <TmntText classNames="h4" tmnt={pointsForAddingPhoto} />
        <TmntText tmnt={pointsForAddingAPhotoDesc} />
      </div>
    </div>
  );

  return (
    <DataCard
      theme="primary-light"
      variant="boxed"
    >
      <div className="write-a-review-loyalty-card" data-testid="write-a-review-loyalty-card">
        <Loading isLoading={loading}>
          <FormStructure.FormWrapper>
            <FormStructure.FormGroup>
              <FormStructure.FormCell isLoading={loading}>
                {myRewardDescription()}
              </FormStructure.FormCell>
              <FormStructure.FormCell isLoading={loading}>
                <ProgressBar
                  currentValue={currentProgressBarValue || 0}
                  maximumValue={maxProgressBarValue || 100}
                />
              </FormStructure.FormCell>
              <FormStructure.FormCell isLoading={loading}>
                {pointsForReviewDescription()}
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
          </FormStructure.FormWrapper>
        </Loading>
      </div>
    </DataCard>
  );
}
