import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { paymentFragment, addressFragment, textPairFragment } from '../../query/fragments';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import TmntText from '../Common/Text/TmntText';
import { Column, Row } from '../Common/Grid';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

import DefaultAddress from './DefaultAddress';
import DefaultPayment from './DefaultPayment';

const CHECKOUT_INFO_BLOCK_QUERY = gql`
  query CheckoutInfoBlockQuery {
    getDefaultPayment { ...payment }
    getDefaultAddress { ...address }
    getAddressPageURL
    getPaymentPageURL
    textFor {
      sectionHeading: pair(pairKey: "checkoutInfoBlockHeading") { ...textPair }
      addressCardTitle: pair(pairKey: "addressCardTitle") { ...textPair }
      addressCardDefault: pair(pairKey: "addressCardDefault") { ...textPair }
      changeText: pair(pairKey: "change") { ...textPair }
      noDefaultAddress: pair(pairKey: "noDefaultAddress") { ...textPair }
      noDefaultPayment: pair(pairKey: "noDefaultPayment") { ...textPair }
      paymentCardTitle: pair(pairKey: "paymentCardTitle") { ...textPair }
      paymentCardDefault: pair(pairKey: "paymentCardDefault") { ...textPair }
      selectAddress: pair(pairKey: "selectAddress") { ...textPair }
      selectPayment: pair(pairKey: "selectPayment") { ...textPair }
    }
  }

  ${addressFragment}
  ${paymentFragment}
  ${textPairFragment}
`;

export default function CheckoutInfoBlock() {
  const { loading, error, data } = useQuery(CHECKOUT_INFO_BLOCK_QUERY, {
    context: { batch: true },
  });

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const {
    addressCardTitle,
    addressCardDefault,
    changeText,
    noDefaultAddress,
    noDefaultPayment,
    paymentCardTitle,
    paymentCardDefault,
    sectionHeading,
    selectAddress,
    selectPayment,
  } = data.textFor;

  const {
    getDefaultPayment: defaultPayment,
    getDefaultAddress: defaultAddress,
    getAddressPageURL: addressPageURL,
    getPaymentPageURL: paymentPageURL,
  } = data;

  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <h2 className="h3">
            <TmntText tmnt={sectionHeading} />
          </h2>
        </SectionHeading>
      )}
    >
      <Row>
        <Column md="4" xs="8">
          <DefaultAddress
            address={defaultAddress}
            cardTitle={addressCardTitle}
            cardDefaultText={addressCardDefault}
            changeText={changeText}
            noDefaultAddress={noDefaultAddress}
            pageLink={addressPageURL}
            selectAddressText={selectAddress}
          />
        </Column>
        <Column md="4" xs="8">
          <DefaultPayment
            payment={defaultPayment}
            cardTitle={paymentCardTitle}
            cardDefaultText={paymentCardDefault}
            changeText={changeText}
            noDefaultPayment={noDefaultPayment}
            pageLink={paymentPageURL}
            selectPaymentText={selectPayment}
          />
        </Column>
      </Row>
    </AccountBlock>
  );
}
