import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import {
  Button, Icon, IconBlock, Modal,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import FormWrapper from '../FormStructure/FormWrapper';
import FormGroup from '../FormStructure/FormGroup';
import FormCell from '../FormStructure/FormCell';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const unsubscribeModalQuery = gql`
  {
    textFor {
      buttonText: pair (pairKey: "unsubscribeSuccessModalButtonText") {...values}
      closeButtonText: pair (pairKey: "unsubscribeSuccessModalCloseButtonText") {...values}
      headingText: pair (pairKey: "unsubscribeSuccessModalHeading") {...values}
      iconHeadingText: pair (pairKey: "unsubscribeSuccessModalIconHeadingText") {...values}
      messageText: pair (pairKey: "unsubscribeSuccessModalMessage") {...values}

      # Note: query keys below match checkbox values from unsubscribe form
      loyaltyAnf: pair (pairKey: "anfLoyalty") {...values}
      anf: pair (pairKey: "abercrombie") {...values}
      gh: pair (pairKey: "gillyHicks") {...values}
      hol: pair (pairKey: "hollister") {...values}
      kids: pair (pairKey: "abercrombieKids") {...values}
      loyaltyHol: pair (pairKey: "clubCali") {...values}
      st: pair (pairKey: "socialTourist") {...values}
    }
  }

  fragment values on TextPair {
    key
    value
  }
`;

export default function UnsubscribeSuccessModal({
  isOpen,
  onClose,
  selectedBrands,
}) {
  const { loading, error: queryError, data: queryData } = useQuery(unsubscribeModalQuery);
  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    buttonText,
    closeButtonText,
    headingText,
    iconHeadingText,
    messageText,
  } = queryData.textFor;
  const { textFor } = queryData;

  const brandTextList = selectedBrands.map((brand) => {
    const brandText = textFor[brand];
    return (
      <div key={brand}>
        <TmntText tmnt={brandText} />
      </div>
    );
  });

  return (
    <Modal
      isOpen={isOpen}
      heading={<TmntText tmnt={headingText} />}
      id="email-unsubscribe-success-modal"
      closeButtonLabel={closeButtonText.value}
      closeButtonLabelKey={closeButtonText.key}
      variant="headerless"
      onClose={onClose}
    >
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <IconBlock orientation="vertical" icon={<Icon icon="checkmark-circle" size="l" />}>
              <span className="h2">
                <TmntText tmnt={iconHeadingText} />
              </span>
            </IconBlock>
            <div style={{ textAlign: 'center', fontSize: '15px' }}>
              <TmntText tmnt={messageText} />
            </div>
          </FormCell>
          <hr className="hr" />
          <FormCell>
            <div style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            >
              { brandTextList }
            </div>
          </FormCell>
          <FormCell>
            <Button
              variant="secondary"
              type="button"
              isFullWidth
              onClick={onClose}
            >
              <TmntText tmnt={buttonText} />
            </Button>
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </Modal>
  );
}

UnsubscribeSuccessModal.defaultProps = {
  isOpen: false,
  onClose: undefined, // noop
  selectedBrands: [],
};

UnsubscribeSuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  selectedBrands: PropTypes.arrayOf(PropTypes.string),
};
