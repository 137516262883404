import React, { useRef, useState } from 'react';
import { Button, FieldGroup, Icon } from 'anf-core-react';
import { useForm } from 'react-hook-form';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { useDatabridge } from '@xp-utilities/web';
import { Form } from '../Common/Form';
import { TmntText } from '../Common/Text';
import * as FormStructure from '../FormStructure';
import { CREATE_USER_MUTATION, JOIN_NOW_FORM_QUERY, USER_EXISTS_QUERY } from './operations';
import { ERROR_MESSAGE } from '../Messages/Messages';
import KeepMeSignedIn from '../Common/KeepMeSignedIn/KeepMeSignedIn';
import { KEEP_ME_SIGN_IN_INPUT_NAME } from '../Common/KeepMeSignedIn/KeepMeSignedInCheckbox';
import useButtonState from '../Common/ButtonState/useButtonState';
import ESpot from '../ESpot/ESpot';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { FormErrorCell, LoyaltyAboutLinkFormCell } from './SignInFormHelpers';
import { JoinFormPropTypes, JoinFormDefaultProps } from './props';
import useTealiumTrigger, { useCustomTealiumTrigger } from '../../hooks/useTealiumTrigger';
import sha256 from '../../tools/encryption/sha256';
import InvisibleReCaptcha from '../Common/ReCaptcha/InvisibleReCaptcha';
import PasswordStrengthMeter from '../Common/PasswordStrengthMeter/PasswordStrengthMeter';

let textCache = null;

export const trackInvalidJoin = (errorKey, analyticsTrackingFunctions) => {
  const {
    trackInvalidPassword, trackInvalidCreateAccount, trackInvalidSignIn,
  } = analyticsTrackingFunctions;

  const errorKeyToFunction = {
    ACT_PasswordCharacterLimits2: trackInvalidPassword,
    _ERR_MISSING_PARMS: trackInvalidCreateAccount,
    default: trackInvalidSignIn,
  };

  const trackActionFunction = errorKeyToFunction[errorKey] || errorKeyToFunction.default;
  trackActionFunction();
};

const getReferralCodeAndCampaignId = () => {
  const url = window.location.href;
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);

  const referralCode = searchParams.get('referral_code');
  const fastEnrollCampaignId = searchParams.get('campaignId');

  return { referralCode, fastEnrollCampaignId };
};

export default function JoinForm({
  onFailedSubmission,
  onIsExistingUser,
  onSignInClick,
  onSuccessfulSubmission,
}) {
  const recaptchaRef = useRef(null);
  const [getIsInvisibleRecaptchEnabled] = useDatabridge('flag.customer-invisible-recaptcha-switches.join', false);
  const [isStepTwoEnabled, setIsStepTwoEnabled] = useState(false);
  const [renderButton, registerPromise] = useButtonState();

  const {
    clearErrors,
    control,
    formState,
    getValues,
    handleSubmit,
    reset,
    setError,
    watch,
  } = useForm();

  const passwordInput = watch('password');

  const { data = {}, loading: queryLoading, error: queryError } = useQuery(JOIN_NOW_FORM_QUERY);

  /* Analytics setup */
  const { userType, userCountry, signifydId } = data;

  const analyticsTrackingFunctions = {
    trackInvalidPassword: useTealiumTrigger('universal', 'INVALID_PASSWORD'),
    trackInvalidSignIn: useTealiumTrigger('universal', 'SIGN_IN_FAIL'),
    trackInvalidCreateAccount: useTealiumTrigger('universal', 'INVALID_CREATE_ACCOUNT'),
  };

  const analyticsData = {
    eventName: 'analytics.account_create_success',
    eventData: {
      event_type: 'account_create_success',
      user_state: userType || '',
      site_country: userCountry || '',
      user_device_id: signifydId || '',
    },
  };

  const trackSuccessfulCreateAccount = useCustomTealiumTrigger(analyticsData);

  /* Success Modal should be displayed here */
  const onSuccessfulUserCreation = (createUserRes) => onSuccessfulSubmission(createUserRes);

  const onFailedUserCreation = (createUserRes) => {
    const { errors } = createUserRes;
    const message = errors?.find((err) => err?.message)?.message;

    if (!message) return;

    const { key: errorKey } = message;
    trackInvalidJoin(errorKey, analyticsTrackingFunctions);

    setError('form', { message });
    onFailedSubmission(createUserRes);
  };

  const [runCreateUser] = useMutation(CREATE_USER_MUTATION, {
    onCompleted: ({ createUser }) => {
      const { success } = createUser;

      if (success) {
        onSuccessfulUserCreation(createUser);
        return;
      }

      onFailedUserCreation(createUser);
    },
    onError: () => { /* do nothing */ },
  });

  const [runUserExistsCheck] = useLazyQuery(USER_EXISTS_QUERY, {
    onCompleted: ({ isExistingUser }) => {
      if (!isExistingUser) {
        setIsStepTwoEnabled(true);
        return;
      }

      if (!onIsExistingUser) return;

      const formData = getValues();
      const { email, password } = formData;

      onIsExistingUser({ email, password, keepMeSignedIn: formData[KEEP_ME_SIGN_IN_INPUT_NAME] });
    },
  });

  const buildSubmissionData = (formData) => {
    const submissionData = { ...formData };
    const brandCheckboxGroup = formData['marketing-brands'];

    const emailOptions = brandCheckboxGroup?.map((brand) => ({ key: brand, value: true }));

    submissionData.emailOptions = emailOptions;
    submissionData.keepMeSignedIn = formData[KEEP_ME_SIGN_IN_INPUT_NAME];

    return submissionData;
  };

  /* Handlers */
  const setInitialFormError = (errors = {}) => {
    if (Object.hasOwnProperty.call(errors, 'password') && errors.password.type === 'validate') {
      setError('password', { message: textCache?.createPasswordRulesText });
    }
    setError('form', { message: textCache?.joinForm?.error });
  };

  const handleResetStepTwo = () => {
    if (!isStepTwoEnabled) return;

    const formData = getValues();
    const { email, password } = formData;
    const keepMeSignedIn = formData[KEEP_ME_SIGN_IN_INPUT_NAME];

    // Keep all StepOne values - email, password, and keep me signed
    reset({ email, password, [KEEP_ME_SIGN_IN_INPUT_NAME]: keepMeSignedIn });
    setIsStepTwoEnabled(false); // hide step 2
  };

  const handleOnInvalidSubmit = (errors) => setInitialFormError(errors);

  const handleCreateUser = async (fieldValues) => {
    const { referralCode, fastEnrollCampaignId } = getReferralCodeAndCampaignId();

    const {
      email,
      password,
      keepMeSignedIn,
      'first-name': firstName,
      'last-name': lastName,
      phone: primaryPhone,
      emailOptions,
      'age-consent-legal-check': ageAboveOrBelow,
    } = fieldValues;

    const userMarketingFlag = String(emailOptions.some((brandOpt) => brandOpt.value === true));

    analyticsData.eventData = {
      ...analyticsData.eventData,
      user_hashed_email: await sha256(email),
      keep_me_logged_in_flag: keepMeSignedIn.toString(),
      user_marketing_flag: userMarketingFlag,
    };

    try {
      const call = new Promise((resolve, reject) => {
        runCreateUser({
          variables: {
            email,
            password,
            keepMeSignedIn,
            firstName,
            lastName,
            primaryPhone,
            emailOptions,
            ageAboveOrBelow,
            referralCode,
            fastEnrollCampaignId,
          },
        })
          .then(({ data: responseData }) => {
            const { createUser } = responseData;
            const { success } = createUser;
            if (!success) reject();
            else {
              trackSuccessfulCreateAccount();
              resolve();
            }
          })
          .catch(() => reject());
      });

      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const handleOnValidSubmit = (formData) => {
    if (!isStepTwoEnabled) {
      const { email } = formData;
      runUserExistsCheck({ variables: { email } });
      return;
    }

    const submissionData = buildSubmissionData(formData);
    const isInvisibleRecaptchaEnabled = getIsInvisibleRecaptchEnabled();

    if (!isInvisibleRecaptchaEnabled) handleCreateUser(submissionData);
    else {
      recaptchaRef.current?.reset();
      recaptchaRef.current?.execute();
    }
  };

  const handleOnRecaptchaValidation = ({ isValid }) => {
    if (!isValid) {
      setError('form', { message: textCache?.errorSomethingWentWrong });
      return;
    }

    const formData = getValues();
    handleCreateUser(buildSubmissionData(formData));
  };

  const handleOnSubmit = async (event) => {
    clearErrors();
    return handleSubmit(handleOnValidSubmit, handleOnInvalidSubmit)(event);
  };

  if (queryError) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = data;
  const { joinForm = {} } = textFor;

  textCache = textFor;

  /* Render Methods */
  const renderFormLabel = (label, isLoading) => (
    <FormStructure.FormGroup>
      <FormStructure.FormCell isLoading={isLoading} isHeader>
        <span className="h1">
          <TmntText tmnt={label} />
        </span>
      </FormStructure.FormCell>
    </FormStructure.FormGroup>
  );

  const { id = 'join-form', label: joinFormLabel, formGroupList } = joinForm;

  const renderPrimaryGroup = (groups = []) => {
    if (groups?.length < 1) return null;

    const group = groups[0];
    const { label, fieldList } = group;

    const primaryGroup = (
      <>
        <FieldGroup legend={label?.value}>
          <StepOne
            fields={fieldList}
            onEmailFocus={handleResetStepTwo}
            onPasswordFocus={handleResetStepTwo}
            control={control}
            formId={id}
            showLabel={textCache?.showPasswordText?.value}
            hideLabel={textCache?.hidePasswordText?.value}
          />
        </FieldGroup>
        {data?.isPasswordStrengthEnabled && (
          <PasswordStrengthMeter password={passwordInput} />
        )}
      </>
    );

    return primaryGroup;
  };

  const renderCTAButtons = (isStepTwoShown = isStepTwoEnabled) => renderButton({
    initial: {
      children: (
        <TmntText tmnt={isStepTwoShown ? textFor?.createAccount : textFor?.continue} />
      ),
      isFullWidth: true,
      type: 'submit',
      variant: 'secondary',
    },
    processing: {
      children: (<TmntText tmnt={textFor?.processing} />),
      isFullWidth: true,
      variant: 'secondary',
    },
    error: {
      children: (<TmntText tmnt={textFor?.pleaseTryAgain} />),
      isFullWidth: true,
      variant: 'secondary',
    },
    success: {
      children: (
        <>
          <Icon icon="check" />
          <TmntText tmnt={textFor?.success} />
        </>
      ),
      isFullWidth: true,
      variant: 'secondary',
    },
  });

  const renderSecondaryGroup = (isStepTwoShown) => {
    if (!isStepTwoShown) {
      return (
        <>
          <FormErrorCell formState={formState} />
          <FormStructure.FormCell isLoading={queryLoading}>
            { renderCTAButtons(isStepTwoEnabled) }
          </FormStructure.FormCell>
          {
            !isStepTwoEnabled && (
              <FormStructure.FormGroup>
                <FormStructure.FormCell isLoading={queryLoading}>
                  <Button
                    variant="tertiary-dark"
                    onClick={onSignInClick}
                    type="button"
                    isFullWidth
                  >
                    <TmntText tmnt={textFor?.signIn} />
                  </Button>
                </FormStructure.FormCell>
              </FormStructure.FormGroup>
            )
          }
          <LoyaltyAboutLinkFormCell
            isLoading={queryLoading}
            isLoyaltyEnabled={config?.isLoyaltyEnabled}
            link={config?.loyaltyAboutLink}
            label={textFor?.loyaltyAboutLabel}
          />
        </>
      );
    }

    return (
      <>
        <StepTwo
          control={control}
          queryData={data}
        />
        <FormErrorCell formState={formState} />
        <FormStructure.FormCell isLoading={queryLoading}>
          { renderCTAButtons(isStepTwoEnabled) }
        </FormStructure.FormCell>
      </>
    );
  };

  const renderEspot = (espotId, isLoyaltyEnabled, isLoading) => {
    if (!isLoyaltyEnabled) return null;

    return (
      <FormStructure.FormCell isLoading={isLoading}>
        <ESpot espotId={espotId} />
      </FormStructure.FormCell>
    );
  };

  return (
    <Form
      id={id}
      label={renderFormLabel(joinFormLabel, queryLoading)}
      isValidated={false}
      onSubmit={handleOnSubmit}
    >
      <FormStructure.FormGroup>
        { renderEspot('customer-join-header', config?.isLoyaltyEnabled, queryLoading) }
        <FormStructure.FormCell isLoading={queryLoading}>
          { renderPrimaryGroup(formGroupList) }
        </FormStructure.FormCell>
        <FormStructure.FormCell isLoading={queryLoading}>
          <KeepMeSignedIn id="join-form-keep-me-signed-in" control={control} />
        </FormStructure.FormCell>
        { renderSecondaryGroup(isStepTwoEnabled) }
        {
          !getIsInvisibleRecaptchEnabled()
            ? null
            : (
              <InvisibleReCaptcha
                onValidation={handleOnRecaptchaValidation}
                ref={recaptchaRef}
              />
            )
        }
      </FormStructure.FormGroup>
    </Form>
  );
}

JoinForm.propTypes = JoinFormPropTypes;
JoinForm.defaultProps = JoinFormDefaultProps;
