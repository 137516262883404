import { prng_newstate, rng_psize } from './prng4';
import crypto from 'crypto';

let rng_state;
let rng_pool;
let rng_pptr;

// Mix in a 32-bit integer into the pool
function rng_seed_int(x) {
  rng_pool[rng_pptr++] ^= x & 255;
  rng_pool[rng_pptr++] ^= (x >> 8) & 255;
  rng_pool[rng_pptr++] ^= (x >> 16) & 255;
  rng_pool[rng_pptr++] ^= (x >> 24) & 255;
  if (rng_pptr >= rng_psize)
    rng_pptr -= rng_psize;
}

// Mix in the current time (w/milliseconds) into the pool
function rng_seed_time() {
  rng_seed_int(new Date().getTime());
}

function rng_get_byte() {
  if (rng_state == null) {
    rng_seed_time();
    rng_state = prng_newstate();
    rng_state.init(rng_pool);
    for (rng_pptr = 0; rng_pptr < rng_pool.length; ++rng_pptr) {
      rng_pool[rng_pptr] = 0;
    }
    rng_pptr = 0;
  }
  // TODO: allow reseeding after first request
  return rng_state.next();
}

// Initialize the pool with junk if needed.
if (rng_pool == null) {
  rng_pool = new Array();
  rng_pptr = 0;
  let t;

  if (crypto && crypto.getRandomValues) {
    // Use webcrypto if available
    const ua = new Uint8Array(32);
    crypto.getRandomValues(ua);
    for (t = 0; t < 32; ++t)
      rng_pool[rng_pptr++] = ua[t];
  }

  while (rng_pptr < rng_psize) { // extract some randomness from Math.random()
    t = Math.floor(65536 * Math.random());
    rng_pool[rng_pptr++] = t >>> 8;
    rng_pool[rng_pptr++] = t & 255;
  }
  rng_pptr = 0;
  rng_seed_time();
}

export class SecureRandom {
  // nextBytes(ba:number[])
  nextBytes(ba) {
    for (let i = 0; i < ba.length; ++i) {
      ba[i] = rng_get_byte();
    }
  }
}
