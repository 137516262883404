import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import FooterEmailSubscribeModal from './FooterEmailSubscribeModal';

const footerEmailSubscribeButtonQuery = gql`
  {
    textFor {
      footerSubscribeSignUpForEmailsButtonText: pair(pairKey: "footerSubscribeSignUpForEmailsButtonText") {...textPair}
    }
  }

  ${textPairFragment}
`;

export default function FooterEmailSubscribeButton() {
  const [isModalOpen, setModalOpen] = useState(false);
  const { loading, error: queryError, data: queryData } = useQuery(footerEmailSubscribeButtonQuery);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    footerSubscribeSignUpForEmailsButtonText,
  } = queryData.textFor;

  return (
    <>
      <Button
        isFullWidth
        variant="tertiary-light"
        onClick={() => setModalOpen(true)}
      >
        <TmntText tmnt={footerSubscribeSignUpForEmailsButtonText} />
      </Button>
      <FooterEmailSubscribeModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}
