import React from 'react';
import PropTypes from 'prop-types';
import { IconBlock, Icon } from 'anf-core-react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';

const propTypes = {
  formHeadingText: textProp.isRequired,
  formDescriptionText: textProp.isRequired,
  formHeadingIcon: PropTypes.string,
};

const defaultProps = { formHeadingIcon: 'mail-large-anf' };

export default function FormHeading({
  formHeadingText,
  formDescriptionText,
  formHeadingIcon,
}) {
  return (
    <>
      <IconBlock
        orientation="vertical"
        icon={<Icon icon={formHeadingIcon} size="l" />}
      >
        <TmntText tmnt={formHeadingText} classNames="h1" />
      </IconBlock>
      <div style={{
        fontSize: '15px',
        marginTop: '10px',
        textAlign: 'center',
      }}
      >
        <TmntHtml tmnt={formDescriptionText} tag="p" />
      </div>
    </>
  );
}

FormHeading.propTypes = propTypes;
FormHeading.defaultProps = defaultProps;
