import React from 'react';
import { Button, ButtonGroup } from 'anf-core-react';
import { gql, useQuery } from '@apollo/client';
import Heading from './PickedForYouHeading';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import TmntText from '../Common/Text/TmntText';
import { textPairFragment } from '../../query/fragments';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import RecommendationSlidersContainer from '../Recommendations/RecommendationSlidersContainer';

const pickedForYouNonAuthQuery = gql`
  query PickedForYouText {
    textFor {
      pickedForYou: pair(pairKey: "pickedForYou") {...textPair}
      pickedForYouNonAuthSubText: pair(pairKey: "pickedForYouNonAuthSubText") {...textPair}
      pickedForYouNonAuthSignInButton: pair(pairKey: "pickedForYouNonAuthSignInButton") {...textPair}
      pickedForYouNonAuthJoinButton: pair(pairKey: "pickedForYouNonAuthJoinButton") {...textPair}
    }
  }

  ${textPairFragment}
`;

export default function SignedOutExperience() {
  const dispatchLoginEvent = (tab) => {
    document.dispatchEvent(new CustomEvent('authentication-modal:open', { detail: { isSignIn: tab } }));
  };

  const { loading, error, data } = useQuery(pickedForYouNonAuthQuery);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;

  const {
    pickedForYou,
    pickedForYouNonAuthSubText,
    pickedForYouNonAuthSignInButton,
    pickedForYouNonAuthJoinButton,
  } = textFor;

  const recommendationsProps = {
    clientId: 'picked-for-you-non-auth-1',
    placements: ['generic_page.PFU4'],
  };

  return (
    <PageContentWrapper mainClass="picked-for-you">
      <div className="picked-for-you-non-auth">
        <div className="non-auth-header">
          <Heading
            headingText={pickedForYou}
            subText={pickedForYouNonAuthSubText}
          />
          <div className="sign-in-join-buttons">
            <ButtonGroup
              marginTop="l"
              variant="grid"
            >
              <Button
                variant="secondary"
                onClick={() => dispatchLoginEvent(true)}
              >
                <TmntText tmnt={pickedForYouNonAuthSignInButton} />
              </Button>
              <Button
                variant="secondary"
                onClick={() => dispatchLoginEvent(false)}
              >
                <TmntText tmnt={pickedForYouNonAuthJoinButton} />
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <RecommendationSlidersContainer
          clientId={recommendationsProps.clientId}
          placements={recommendationsProps.placements}
        />
      </div>
    </PageContentWrapper>
  );
}
