import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'anf-core-react';
import PhoneNumberModal from './PhoneNumberModal';
import { useTealiumTrigger } from '../../../hooks';

export default function ViewPhoneNumber({ modalHeaderText, buttonLabel }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataAnalyticsTrackAction = useTealiumTrigger('universal', 'VIEW_PHONE_NUMBERS');

  const handleOpenModal = () => {
    setIsModalOpen(true);
    dataAnalyticsTrackAction();
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderHeader = () => (
    <span className="h2" data-testid="phonenumbers-modal-header">
      {modalHeaderText}
    </span>
  );

  return (
    <>
      <Button
        classList="customer-care-button"
        theme="inverse"
        variant="tertiary-dark"
        onClick={handleOpenModal}
      >
        {buttonLabel}
      </Button>
      <Modal
        id="view-phonenumber-modal"
        isOpen={isModalOpen}
        heading={renderHeader()}
        onClose={handleCloseModal}
      >
        <PhoneNumberModal />
      </Modal>
    </>
  );
}

ViewPhoneNumber.defaultProps = {
  modalHeaderText: undefined,
  buttonLabel: undefined,
};

ViewPhoneNumber.propTypes = {
  modalHeaderText: PropTypes.node,
  buttonLabel: PropTypes.node,
};
