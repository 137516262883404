import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Button, Icon, LegalText } from 'anf-core-react';
import isValidEmail from '../../../tools/isValidEmail';
import ButtonState from '../../Common/ButtonState/ButtonState';
import { TmntHtml, TmntText } from '../../Common/Text';
import * as FormStructure from '../../FormStructure';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../../Messages/Messages';
import { EMAIL_US_FORM_TEXT_QUERY, SEND_EMAIL_MUTATION } from './emailUsOperations';
import EmailUsForm from './EmailUsForm';

const REQUIRED_EMAIL_US_FIELDS = ['name', 'email', 'contactReason', 'comment'];

const PREVALIDATION_EMAIL_US_FIELDS = new Map([
  ['email', { prevalidation: isValidEmail }],
]);

export default function EmailUsContainer({ onSuccess }) {
  const formRef = useRef(null);
  const [formIsInvalid, setFormIsInvalid] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const { data, loading, error } = useQuery(EMAIL_US_FORM_TEXT_QUERY);

  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION, {
    // let the modal know that the form submitted successfully
    onCompleted: () => { onSuccess(); },
  });

  const isRequiredField = (name) => REQUIRED_EMAIL_US_FIELDS.includes(name);

  const prevalidateField = (name, value) => {
    // check if field needs prevalidation and return true (valid) if not
    const isPrevalidationField = PREVALIDATION_EMAIL_US_FIELDS.has(name);
    if (!isPrevalidationField) return true;

    // grab prevalidation func from map and test if valid
    const { prevalidation } = PREVALIDATION_EMAIL_US_FIELDS.get(name);
    if (prevalidation(value)) return true;

    return false;
  };

  const getInvalidFields = (fields) => (
    Object.entries(fields).reduce((acc, [key, value]) => {
      // check if field is required and push it to array if no value
      const isRequired = isRequiredField(key);
      if (!isRequired) return acc;
      if (!value) acc.push(key);

      // check if field needs prevalidation and push it to array if not valid
      const isPrevalidated = prevalidateField(key, value);
      if (!isPrevalidated) acc.push(key);

      return acc;
    }, [])
  );

  const getFormData = (form) => Object.fromEntries([...new FormData(form).entries()]);

  const validate = () => {
    const fields = getFormData(formRef.current);
    const invalidList = getInvalidFields(fields);

    setInvalidFields([...invalidList]);

    return !invalidList.length; // form is valid if invalidList has no length
  };

  const handleOnSendEmail = async () => (
    sendEmail({ variables: { emailForm: getFormData(formRef.current) } })
      .catch(() => Promise.reject())
  );

  const handleOnSubmit = async () => {
    const valid = validate();

    setFormIsInvalid(!valid);

    if (!valid) return Promise.reject();

    return handleOnSendEmail();
  };

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const {
    buttonProcessing,
    buttonSubmit,
    buttonSuccess,
    formDisputeLabel,
    formLegal,
    formResponseNote,
    modalHeading,
  } = data.textFor;

  const renderButtonState = () => (
    <ButtonState
      asyncOnClick={handleOnSubmit}
      initial={(
        <Button variant="primary" isFullWidth type="submit">
          <TmntText tmnt={buttonSubmit} />
        </Button>
      )}
      processing={(
        <Button
          variant="primary"
          isProcessing
          isDisabled
          isFullWidth
        >
          <TmntText tmnt={buttonProcessing} />
        </Button>
      )}
      error={(
        <Button variant="primary" isFullWidth type="submit">
          <TmntText tmnt={buttonSuccess} />
        </Button>
      )}
      success={(
        <Button variant="primary" isDisabled isFullWidth>
          <Icon icon="check" />
          <TmntText tmnt={buttonSuccess} />
        </Button>
      )}
    />
  );

  const renderDispluteSection = () => formDisputeLabel?.value !== '' && (
    <FormStructure.FormGroup>
      <FormStructure.FormCell>
        <section className="dispute-section">
          <div className="dispute-content" data-testid="email-us-dispute">
            <TmntHtml tmnt={formDisputeLabel} />
          </div>
        </section>
      </FormStructure.FormCell>
    </FormStructure.FormGroup>
  );

  return (
    <EmailUsForm
      id="internal-email-us-form"
      label={modalHeading}
      formRef={formRef}
      onSubmit={(e) => e.preventDefault()}
      formIsInvalid={formIsInvalid}
      invalidFields={invalidFields}
      text={data.textFor}
    >
      <FormStructure.FormGroup>
        <FormStructure.FormCell>
          <TmntHtml tmnt={formResponseNote} />
        </FormStructure.FormCell>
        <FormStructure.FormCell>
          <LegalText>
            <TmntHtml tmnt={formLegal} />
          </LegalText>
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      <FormStructure.FormGroup>
        <FormStructure.FormCell>
          {renderButtonState()}
        </FormStructure.FormCell>
      </FormStructure.FormGroup>
      {renderDispluteSection()}
    </EmailUsForm>
  );
}

const propTypes = { onSuccess: PropTypes.func.isRequired };

EmailUsContainer.propTypes = propTypes;
