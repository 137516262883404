import { useQuery } from '@apollo/client';
import React from 'react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { MY_REWARDS_QUERY } from './operations';
import DiscountCardContainer from '../Common/DiscountCard/DiscountCardContainer';

export default function MyRewards() {
  const { loading, error, data: queryData } = useQuery(MY_REWARDS_QUERY);

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor = {}, coupons = [] } = queryData;

  const {
    myRewardsText,
    redeemInBagText,
    exclusionsApplyLabelText,
  } = textFor;

  const renderDiscountDetails = (text, href) => (
    <a
      target="_self"
      rel="noreferrer"
      href={href}
      style={{ display: 'block' }}
      className="open-modal-link"
    >
      {text}
    </a>
  );

  const renderDiscountCard = ({
    title, description, badge, expiration, exclusionLink, id,
  }) => (
    <DiscountCardContainer
      key={`discountcard_${id}`}
      discountTitle={title.value}
      discountSecondaryTitle={description?.key ? description?.value : ''}
      graphicBadge={badge}
      discountDescription={expiration.value}
      id={id}
      discountMessage={redeemInBagText.value}
      discountDetails={
        renderDiscountDetails(exclusionsApplyLabelText?.value, exclusionLink)
      }
      orientation={null}
    />
  );

  const renderCoupons = (nCoupons) => nCoupons.map((coupon) => (
    renderDiscountCard(coupon)
  ));

  const isCouponsAvailable = coupons?.length;

  return (
    isCouponsAvailable ? (
      <div className="myRewards-container" data-testid="my-rewards-container">
        <h2 className="h1">{myRewardsText.value}</h2>
        <div className="discount-list">
          {renderCoupons(coupons)}
        </div>
        <hr className="hr" />
      </div>
    ) : null
  );
}
