import React from 'react';
import { InputField } from 'anf-core-react';
import { useController } from 'react-hook-form';
import { propTypes, defaultProps } from './props';

export default function ControlledInputField({
  autoComplete,
  children,
  customValidation,
  control,
  defaultValue,
  id,
  isDisabled,
  isRequired, // defaulted to true
  label,
  maxLength,
  name,
  type,
}) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: { required: isRequired, validate: customValidation },
  });

  return (
    <InputField
      autoComplete={autoComplete}
      id={id}
      isDisabled={isDisabled}
      isInvalid={fieldState.invalid}
      isRequired={isRequired}
      label={label}
      maxLength={maxLength}
      name={field.name}
      onChange={field.onChange}
      type={type}
      value={field.value}
    >
      { children }
    </InputField>
  );
}

ControlledInputField.propTypes = propTypes;
ControlledInputField.defaultProps = defaultProps;
