export const REQUIRED_ADDRESS_FORM_FIELDS = [
  'address1',
  'apoFpoRegion',
  'city',
  'district',
  'firstName',
  'lastName',
  'name',
  'phoneticName',
  'postalCode',
  'prefecture',
  'province',
  'region',
  'state',
  'zone',
];

const maxLengths = {
  address1: 70,
  address2: 50,
  city: 35,
  firstName: 64,
  lastName: 64,
  name: 64,
  phoneticName: 23,
  postalCode: 35,
};

export const ADDRESS_FORM_MAX_LENGTHS = new Map(Object.entries(maxLengths));

export const COUNTRIES_WITH_LOCALITY_QUERIES = ['CN', 'HK'];
