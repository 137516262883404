import { useQuery } from '@apollo/client';
import {
  AccountBlock,
} from 'anf-core-react';
import React from 'react';
import Column from '../Common/Grid/Column';
import Row from '../Common/Grid/Row';
import Loading from '../Common/Loading/Loading';
import { TmntText } from '../Common/Text';
import { ERROR_MESSAGE } from '../Messages/Messages';
import MissingPoints from './MissingPoints';
import MissingPointsForm from './MissingPointsForm';
import PointsHistoryFindOrderDetails from './PointsHistoryFindOrderDetails';
import PointsList from './PointsList';
import { POINTS_HISTORY_QUERY } from './operations';

export default function PointsHistoryPage() {
  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(POINTS_HISTORY_QUERY, {
    context: { batch: true },
    ssr: false,
  });

  if (error) return ERROR_MESSAGE;

  const textFor = data?.textFor || {};
  const config = data?.config || {};
  const { disableMissingPointsForm = false } = data || {};
  const {
    inStorePurchaseText,
    findOrderDetailText,
    findOrderStoreText,
    barcodeText,
    onlinePurchaseText,
    emailText,
    findOrderOnlineText,
    title,
    missingPointsTitle,
    missingPointsDescription,
    instoreAltText,
    onlineAltText,
  } = textFor;

  const { onlineURL, inStoreURL } = config;

  return (
    <Loading isLoading={queryLoading}>
      {!queryLoading && (
        <div className="points-history-container">
          <AccountBlock
            heading={(
              <h3 className="h3" data-testid="points-history-page-header">
                <TmntText tmnt={title} />
              </h3>
            )}
          >
            <Row>
              <Column xs="8" md="4">
                <PointsList />
              </Column>
              <Column xs="8" md="4">
                <Row>
                  <MissingPoints
                    title={missingPointsTitle}
                    description={missingPointsDescription}
                  />
                  {!disableMissingPointsForm && <MissingPointsForm />}
                </Row>
                <Row>
                  <div className="find-order-details">
                    <TmntText tmnt={findOrderDetailText} />
                  </div>
                </Row>
                <Row>
                  <PointsHistoryFindOrderDetails
                    findOrderText={findOrderStoreText}
                    imageURL={inStoreURL}
                    linkText={barcodeText}
                    purchaseText={inStorePurchaseText}
                    altText={instoreAltText?.value}
                  />
                </Row>
                <Row>
                  <PointsHistoryFindOrderDetails
                    findOrderText={findOrderOnlineText}
                    imageURL={onlineURL}
                    linkText={emailText}
                    purchaseText={onlinePurchaseText}
                    altText={onlineAltText?.value}
                  />
                </Row>
              </Column>
            </Row>
          </AccountBlock>
        </div>
      )}
    </Loading>
  );
}
