import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, ButtonGroup, Modal } from 'anf-core-react';
import PropTypes from 'prop-types';
import Icon from '../Common/Icon/Icon';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import ButtonState from '../Common/ButtonState/ButtonState';
import { textPairFragment, apiResponseFragment } from '../../query/fragments';

const cancelRnEModalTextQuery = gql`
  {
    textFor {
      headingText: pair(pairKey: "cancelRNEModalHeading") { ...textPair }
      cancelText: pair(pairKey: "cancelRNEModalText") { ...textPair }
      buttonText: pair(pairKey: "cancelRNEModalButtonText") { ...textPair }
      linkText: pair(pairKey: "cancelRNEModalLinkButtonText") { ...textPair }
      reason: pair(pairKey: "cancelRNEModalReasonText") { ...textPair }
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
    }
  }

  ${textPairFragment}
`;

const cancelRneMutation = gql`
  mutation CancelRne($orderNumber: String!, $email: String!, $reason: String) {
    cancelOrder(orderNumber: $orderNumber, email: $email, reason: $reason) {
      ...response
    }
  }

  ${apiResponseFragment}
`;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string,
  onClose: PropTypes.func,
  orderNumber: PropTypes.string,
};

const defaultProps = {
  email: undefined,
  onClose: undefined,
  orderNumber: undefined,
};

let textCache;

export default function CancelReturnExchangeModal({
  email, isOpen, onClose, orderNumber,
}) {
  const [runCancelRne] = useMutation(cancelRneMutation);

  const { loading, error, data: textData } = useQuery(cancelRnEModalTextQuery);

  const handleOnSuccessfulSubmit = () => {
    onClose(); // close
    document.dispatchEvent(new CustomEvent('orderHistory:update')); // update
  };

  const handleOnSubmit = async () => new Promise((resolve, reject) => {
    runCancelRne({ variables: { orderNumber, email, reason: textCache.reason?.value } })
      .then(({ data }) => {
        const { cancelOrder } = data;
        const { success } = cancelOrder;
        if (!success) reject();
        else resolve();
      })
      .catch(() => { reject(); });
  });

  textCache = textData?.textFor || null;

  return (
    <Modal
      isOpen={isOpen}
      heading={
        textCache?.headingText
          ? (
            <span className="h2">
              <TmntText tmnt={textCache.headingText} />
            </span>
          )
          : null
      }
      id="rneCancelModal"
      onClose={onClose}
    >
      {
        (() => {
          if (loading) return LOADING_MESSAGE;
          if (error) return ERROR_MESSAGE;

          const {
            buttonText,
            processing,
            success,
            pleaseTryAgain,
          } = textCache;

          return (
            <form
              style={{ textAlign: 'center' }}
              onSubmit={(e) => { e.preventDefault(); }}
            >
              <TmntText tmnt={textCache.cancelText} />
              <div style={{ marginTop: '15px' }}>
                <ButtonGroup variant="vertical-spaced">
                  <ButtonState
                    asyncOnClick={handleOnSubmit}
                    onSuccess={handleOnSuccessfulSubmit}
                    initial={(
                      <Button type="submit" variant="secondary">
                        <TmntText tmnt={buttonText} />
                      </Button>
                    )}
                    processing={(
                      <Button variant="secondary" isProcessing isDisabled>
                        <TmntText tmnt={processing} />
                      </Button>
                    )}
                    error={(
                      <Button variant="secondary" isDisabled>
                        <TmntText tmnt={pleaseTryAgain} />
                      </Button>
                    )}
                    success={(
                      <Button variant="secondary" isDisabled>
                        <Icon icon="check" />
                        <TmntText tmnt={success} />
                      </Button>
                    )}
                  />
                  <Button variant="borderless" onClick={onClose} classList="button-with-link-hyperlink">
                    <TmntText tmnt={textCache.linkText} />
                  </Button>
                </ButtonGroup>
              </div>
            </form>
          );
        })()
      }
    </Modal>
  );
}

CancelReturnExchangeModal.propTypes = propTypes;
CancelReturnExchangeModal.defaultProps = defaultProps;
