import React from 'react';
import hydrate from '../lib/hydrate';
import SignInBlock from '../lib/components/SignIn/SignInBlock';
import { SessionContextProvider } from '../lib/context/SessionContext/SessionContext';
import {
  AuthenticationModalContext,
  ModalContextProvider,
  SignInToasterContext,
} from '../lib/context/ModalContext';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

const frontend = 'SignInBlockFrontend';

function SignInBlockFrontend() {
  return (
    <SessionContextProvider frontend={frontend}>
      <ModalContextProvider context={AuthenticationModalContext}>
        <ModalContextProvider context={SignInToasterContext}>
          <AuthenticationFormContextProvider>
            <SignInBlock />
          </AuthenticationFormContextProvider>
        </ModalContextProvider>
      </ModalContextProvider>
    </SessionContextProvider>
  );
}

hydrate({
  frontend,
  component: SignInBlockFrontend,
  persist: true,
});
