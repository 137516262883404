/* eslint-disable react/jsx-props-no-spreading */
import {
  DropDown,
  ErrorMessage,
  InputField,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { textProp } from '../../../tools/customProps';
import TmntText from '../../Common/Text/TmntText';

const baseFieldProp = {
  error: textProp.isRequired,
  label: textProp.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func,
};

const baseDefaultProps = {
  value: undefined,
  isDisabled: false,
  isInvalid: false,
  isRequired: false,
  onChange: undefined,
};

export const inputPropsTypes = {
  ...baseFieldProp,
  type: PropTypes.string,
  maxLength: PropTypes.number,
};

const defaultInputProps = {
  ...baseDefaultProps,
  type: undefined,
  maxLength: undefined,
};

export function AddressInput({
  value,
  error,
  type,
  isDisabled,
  isInvalid,
  isRequired,
  label,
  maxLength,
  name,
  onChange,
}) {
  const id = `address-field-input-${name}`;
  const [fieldValue, setFieldValue] = useState(value);

  const handleOnChange = (event) => {
    setFieldValue(event.target.value);
    if (onChange) onChange(event);
  };

  const errorMessage = error && (
    <ErrorMessage id={`${id}-error-message`} variant="error">
      <TmntText tmnt={error} />
    </ErrorMessage>
  );

  return (
    <InputField
      autoComplete="off"
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label?.value}
      maxLength={maxLength}
      name={name}
      onChange={handleOnChange}
      type={type}
      value={fieldValue}
    >
      { isInvalid && errorMessage }
    </InputField>
  );
}

AddressInput.propTypes = inputPropsTypes;
AddressInput.defaultProps = defaultInputProps;

const optionProp = PropTypes.shape({
  optionValue: PropTypes.string.isRequired,
  text: textProp.isRequired,
});

export const multiSelectPropTypes = {
  ...baseFieldProp,
  optionList: PropTypes.arrayOf(optionProp),
};

const multiSelectDefaultProps = { ...baseDefaultProps, optionList: [] };

export function AddressMultiSelect({
  value,
  error,
  isDisabled,
  isInvalid,
  isRequired,
  label,
  name,
  onChange,
  optionList,
}) {
  const id = `address-field-select-${name}`;
  const [fieldValue, setFieldValue] = useState(value);

  const handleOnChange = (event) => {
    setFieldValue(event.target.value);
    if (onChange) onChange(event);
  };

  const shouldResetFieldValue = useCallback((list) => {
    const optVal = list.find(({ optionValue }) => fieldValue === optionValue);
    if (!optVal) return true;
    return false;
  }, [fieldValue]);

  useEffect(() => {
    if (shouldResetFieldValue(optionList)) setFieldValue(undefined);
  }, [optionList, shouldResetFieldValue]);

  const errorMessage = error && (
    <ErrorMessage id={`${id}-error-message`}>
      <TmntText tmnt={error} />
    </ErrorMessage>
  );

  const processOptions = ({ optionValue, text }) => ({ label: text?.value, value: optionValue });

  return (
    <DropDown
      id={id}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label?.value}
      name={name}
      onChange={handleOnChange}
      options={optionList.map(processOptions)}
      value={fieldValue || ''}
    >
      {isInvalid && errorMessage}
    </DropDown>
  );
}

AddressMultiSelect.propTypes = multiSelectPropTypes;
AddressMultiSelect.defaultProps = multiSelectDefaultProps;

const propTypes = {
  ...inputPropsTypes,
  ...multiSelectPropTypes,
  type: PropTypes.oneOf(['multiselect', 'input']).isRequired,
};

function AddressField({ type, ...props }) {
  if (type !== 'multiselect') return <AddressInput {...props} />;
  return <AddressMultiSelect {...props} />;
}

AddressField.propTypes = propTypes;

export default AddressField;
