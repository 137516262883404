import { useQuery } from '@apollo/client';
import {
  DropDown,
  ErrorMessage,
  FieldGroup,
  InputField,
} from 'anf-core-react';
import React from 'react';
import { useController } from 'react-hook-form';
import { useBirthdayValidation } from '../../../hooks';
import FieldTitle from '../../FieldTitle/FieldTitle';
import * as FormStructure from '../../FormStructure';
import { ERROR_MESSAGE } from '../../Messages/Messages';
import { TmntText } from '../Text';
import { BIRTHDAY_FORM_QUERY } from './operations';
import { defaultProps, propTypes } from './props';

const NAMES = {
  birthMonth: 'birth-month',
  birthDay: 'birth-day',
  birthYear: 'birth-year',
};

export default function ControlledBirthdayFields({
  control,
  id,
  name,
  setError,
  clearErrors,
}) {
  const { loading, error, data } = useQuery(BIRTHDAY_FORM_QUERY);

  const configCache = data?.config;

  const { field: birthMonthField } = useController({ name: NAMES.birthMonth, control });
  const { field: birthDayField } = useController({ name: NAMES.birthDay, control });
  const { field: birthYearField } = useController({ name: NAMES.birthYear, control });

  const {
    isMonthInvalid,
    isDayInvalid,
    isYearInvalid,
    validateBirthDay,
  } = useBirthdayValidation(
    birthMonthField.value,
    birthDayField.value,
    birthYearField.value,
  );

  const validateForm = () => {
    const { isValid, showFormAgeError } = validateBirthDay(configCache?.legalSubscribeAge);
    clearErrors(['form', 'age']);
    if (showFormAgeError) setError('age');
    if (!isValid) setError('form');
  };

  useController({
    name,
    control,
    rules: {
      validate: validateForm,
    },
  });

  const processDropDownOptions = (dropDownList) => dropDownList.options.map((op) => (
    { label: op.text.value, value: op.optionValue }
  ));

  const renderErrorMessage = (fieldId, tmnt) => (tmnt ? (
    <ErrorMessage id={fieldId}>
      <TmntText tmnt={tmnt} />
    </ErrorMessage>
  ) : null);

  if (loading) return <FormStructure.FormCell isLoading />;
  if (error) return ERROR_MESSAGE;

  const {
    birthday,
    birthDayList,
    birthMonthList,
    birthYearText,
    optional,
  } = data.textFor;

  return (
    <FormStructure.FormCell>
      <FieldTitle
        title={birthday.value}
        titleTextKey={birthday.key}
        optionalText={optional.value}
        optionalTextKey={optional.key}
      />
      <FieldGroup
        describedBy={`${birthday.value} ${optional.value}`}
        legend={`${birthday.value} ${optional.value}`}
      >
        <DropDown
          autoComplete="bday-month"
          describedBy={birthMonthList.label.value}
          id={`${id}-birth-month`}
          isInvalid={isMonthInvalid}
          label={birthMonthList.label.value}
          name={birthMonthField.name}
          onChange={birthMonthField.onChange}
          options={processDropDownOptions(birthMonthList)}
          value={birthMonthField.value || ''}
          data-testid="birth-month"
        >
          {isMonthInvalid && renderErrorMessage(`${id}-birth-month-error-message`, birthMonthList.error)}
        </DropDown>
        <DropDown
          autoComplete="bday-day"
          describedBy={birthDayList.label.value}
          id={`${id}-birth-day`}
          isInvalid={isDayInvalid}
          label={birthDayList.label.value}
          name={birthDayField.name}
          onChange={birthDayField.onChange}
          options={processDropDownOptions(birthDayList)}
          value={birthDayField.value || ''}
        >
          {isDayInvalid && renderErrorMessage(`${id}-birth-day-error-message`, birthDayList.error)}
        </DropDown>
        <InputField
          autoComplete="bday-year"
          id={`${id}-birth-year`}
          isInvalid={isYearInvalid}
          label={birthYearText?.label?.value}
          maxLength={4}
          name={birthYearField.name}
          onChange={birthYearField.onChange}
          value={birthYearField.value}
        >
          {isYearInvalid && renderErrorMessage(`${id}-birth-year-error-message`, birthYearText.error)}
        </InputField>
      </FieldGroup>
    </FormStructure.FormCell>
  );
}

ControlledBirthdayFields.propTypes = propTypes;
ControlledBirthdayFields.defaultProps = defaultProps;
