import React, { useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  ErrorMessage, FieldGroup, Icon,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import PasswordField from '../Common/PasswordInputField/PasswordField';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import FormHeading from './FormHeading';
import useButtonState from '../Common/ButtonState/useButtonState';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { createPasswordPaneQuery, createPasswordRequest } from './operations';
import { PaneContext } from '../../context/PaneContext/PaneContextProvider';

export default function CreatePasswordPane() {
  const [renderButton, registerPromise] = useButtonState();
  const { goToPane } = useContext(PaneContext);
  const { loading, error: queryError, data: queryData } = useQuery(createPasswordPaneQuery);

  const {
    control,
    clearErrors,
    formState,
    setError,
    handleSubmit,
    getValues,
  } = useForm();

  const [createPasswordMutation] = useMutation(createPasswordRequest, {
    onError: (error) => {
      const { graphQLErrors } = error;
      const { extensions } = graphQLErrors && graphQLErrors.length && graphQLErrors[0];
      const { body } = extensions.response;
      setError('form', { message: body?.error[0]?.errorMessage });
    },
  });

  const validateNewConfirmPassword = (newPass, confirmPass) => newPass === confirmPass;

  const validateSubmission = (newPassword, confirmNewPassword) => {
    let isValid = true;

    if (!validateNewConfirmPassword(newPassword, confirmNewPassword)) {
      setError('confirm-password', { type: 'passwordMismatch' });
      setError('form', { message: queryData.textFor.invalidInputError?.value });
      isValid = false;
    }
    return isValid;
  };

  const handleOnSubmit = async (fieldValues) => {
    clearErrors();

    const {
      'enter-password': newPassword,
      'confirm-password': confirmNewPassword,
    } = fieldValues;

    const isSubmissionValid = validateSubmission(newPassword, confirmNewPassword);

    try {
      const call = new Promise((resolve, reject) => {
        if (!isSubmissionValid) {
          reject();
        } else {
          createPasswordMutation({
            variables: {
              password: confirmNewPassword,
            },
          }).then(({ data = {} }) => {
            const { createPassword } = data;
            if (!createPassword?.success) reject();
            else resolve();
          })
            .catch(() => { reject(); });
        }
      });
      registerPromise(call, {
        onSuccess: () => goToPane(3),
      });
    } catch { /* do nothing */ }
  };

  const handleFailure = (fieldErrors) => {
    if (Object.keys(fieldErrors).length === 1 && fieldErrors.form) {
      handleOnSubmit(getValues());
    } else {
      setError('form', { message: queryData.textFor.invalidInputError?.value });
    }
  };

  const renderFormError = (errors) => {
    const formError = errors.form;

    if (Object.keys(formState.errors).length <= 0) return null;

    return (
      <FormCell>
        <ErrorMessage id="create-password-pane-error-message">
          { formError?.message }
        </ErrorMessage>
      </FormCell>
    );
  };

  const handleSubmitWithoutPropogation = (event) => {
    event.stopPropagation();
    handleSubmit(handleOnSubmit, handleFailure)(event);
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    headerText,
    headerInstructionsText,
    enterPasswordLabel,
    confirmPasswordLabel,
    createPasswordRulesText,
    createPasswordButtonText,
    showPasswordText,
    hidePasswordText,
    processing,
    success,
    pleaseTryAgain,
    createPasswordPanePasswordRequirements,
    createPasswordPanePasswordMismatch,
  } = textFor;

  return (
    <form onSubmit={handleSubmitWithoutPropogation} name="create-password-pane" noValidate>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <FormHeading
              formHeadingIcon="lock-large-anf"
              formHeadingText={headerText}
              formDescriptionText={headerInstructionsText}
            />
          </FormCell>
          <FormCell>
            <FieldGroup legend="Create Password Pane Inputs">
              <PasswordField
                id="enter-password-field"
                isInvalid={Object.keys(formState.errors).includes('enter-passowrd')}
                label={enterPasswordLabel?.value}
                maxLength={25}
                name="enter-password"
                showLabel={showPasswordText?.value}
                hideLabel={hidePasswordText?.value}
                control={control}
              >
                <ErrorMessage id="enter-password-field-error-message">
                  {createPasswordPanePasswordRequirements.value}
                </ErrorMessage>
              </PasswordField>
              <PasswordField
                id="confirm-password-field"
                isInvalid={Object.keys(formState.errors).includes('confirm-passowrd')}
                label={confirmPasswordLabel?.value}
                maxLength={25}
                name="confirm-password"
                showLabel={showPasswordText?.value}
                hideLabel={hidePasswordText?.value}
                control={control}
              >
                <ErrorMessage id="confirm-password-field-error-message">
                  {formState.errors['confirm-password']?.type === 'passwordMismatch'
                    ? createPasswordPanePasswordMismatch.value
                    : createPasswordPanePasswordRequirements.value }
                </ErrorMessage>
              </PasswordField>
            </FieldGroup>
          </FormCell>
          <FormCell align="center">
            <TmntText tmnt={createPasswordRulesText} />
          </FormCell>
          { renderFormError(formState.errors) }
          <FormCell>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={createPasswordButtonText} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                },
                processing: {
                  children: (<TmntText tmnt={processing} />), isFullWidth: true, variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={pleaseTryAgain} />), isFullWidth: true, variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}
