import React from 'react';
import { gql, useQuery } from '@apollo/client';
import FooterEmailSubscribeForm from './FooterEmailSubscribeForm';
import FooterEmailSubscribeButton from './FooterEmailSubscribeButton';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const footerSubscribeConfig = gql`
  {
    config {
      hasFooterSubscribeForm: bool(name: "hasFooterSubscribeForm")
    }
  }
`;

export default function FooterEmailSubscribeBlock() {
  const { loading, error: queryError, data: queryData } = useQuery(footerSubscribeConfig);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    hasFooterSubscribeForm,
  } = queryData.config;

  if (hasFooterSubscribeForm) return <FooterEmailSubscribeForm />;

  return <FooterEmailSubscribeButton />;
}
