import PropTypes from 'prop-types';

export const clientIdProp = PropTypes.string.isRequired;
export const placementsProp = PropTypes.arrayOf(PropTypes.string);

export const RecommendationsWrapperPropTypes = {
  clientId: clientIdProp,
  children: PropTypes.node,
};

export const RecommendationsWrapperDefaultProps = { children: undefined };

export const RecommendationsContainerPropTypes = {
  clientId: clientIdProp,
  placements: placementsProp,
};

export const RecommendationsContainerDefaultProps = { placements: [] };

export const RecommendationSlidersContainerPropTypes = {
  clientId: clientIdProp,
  placements: placementsProp,
  useGrid: PropTypes.bool,
};

export const RecommendationSlidersContainerDefaultProps = { placements: [], useGrid: false };

export const RecommendationAEMSlidersContainerPropTypes = {
  clientId: clientIdProp,
  placements: placementsProp,
};

export const RecommendationAEMSlidersContainerDefaultProps = { placements: [] };
