import PropTypes from 'prop-types';

export const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  setError: PropTypes.func,
  clearErrors: PropTypes.func,
};

export const defaultProps = {
  control: null,
  setError: undefined,
  clearErrors: undefined,
};

export const birthdayFieldsContextPropTypes = {
  children: PropTypes.node.isRequired,
};
