import React from 'react';
import { useQuery } from '@apollo/client';
import { ProgressBar } from 'anf-core-react';
import { FormCell } from '../FormStructure';
import { TmntText } from '../Common/Text';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { PROGRESS_BAR_QUERY } from './operations';

export default function ProgressBarContainer() {
  const { loading, error: queryError, data: queryData } = useQuery(PROGRESS_BAR_QUERY, {
    ssr: false,
    context: { batch: true },
  });

  if (loading) return null;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = queryData;
  const { myPointsTitle, pointsLabel } = textFor;
  const { maxProgressBarValue, currentProgressBarValue } = config;

  return (
    <FormCell>
      <ProgressBar
        currentValue={currentProgressBarValue}
        maximumValue={maxProgressBarValue}
      />
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
        fontWeight: 700,
      }}
      >
        <TmntText tmnt={myPointsTitle} />
        <span>
          {maxProgressBarValue}
          {' '}
          {pointsLabel?.value}
        </span>
      </div>
    </FormCell>
  );
}
