import React, { useState, useCallback } from 'react';
import {
  InputField, Button, Icon, PasswordField,
} from 'anf-core-react';
import { propTypes, defaultProps } from './props';

export default function PasswordInputField({
  children,
  defaultType,
  hideLabel,
  id,
  isInvalid,
  label,
  maxLength,
  name,
  onChange,
  onFocus,
  onTypeChange,
  showLabel,
  inputValue,
  autoComplete,
  isRequired,
}) {
  const [type, setType] = useState(defaultType);
  const [passwordInputValue, setPasswordInputValue] = useState('');

  const toggleType = () => (type === 'password' ? 'text' : 'password');

  const handleOnTypeChange = () => {
    const newType = toggleType();

    setType(newType);
    if (!onTypeChange) return;
    onTypeChange(newType);
  };

  const handleInputChange = useCallback((event) => {
    setPasswordInputValue(event.target.value);
    if (!onChange) return;
    onChange(event);
  }, [onChange]);

  return (
    <PasswordField>
      <InputField
        autoComplete={autoComplete}
        label={label}
        id={id}
        isInvalid={isInvalid}
        maxLength={maxLength}
        type={type}
        name={name}
        isRequired={isRequired}
        onChange={handleInputChange}
        onFocus={onFocus}
        value={typeof inputValue !== 'undefined' ? inputValue : passwordInputValue}
      >
        <Button onClick={handleOnTypeChange} classList="password-field-button">
          <Icon
            icon={type === 'password' ? 'eye-filled' : 'eye'}
            labelText={type === 'password' ? showLabel : hideLabel}
          />
        </Button>
        { children }
      </InputField>
    </PasswordField>
  );
}

PasswordInputField.propTypes = propTypes;
PasswordInputField.defaultProps = defaultProps;
