import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { InputField, ErrorMessage } from 'anf-core-react';
import { textProp } from '../../../tools/customProps';
import { formatExpirationDate } from '../../../tools/CreditCardHelper/CreditCardHelper';

const defaultProps = {
  defaultExpDate: undefined,
  expDateError: undefined,
};

const propTypes = {
  defaultExpDate: PropTypes.string,
  expDateError: textProp,
  isDisabled: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default function ExpirationDateInput({
  defaultExpDate,
  expDateError,
  isDisabled,
  isInvalid,
  label,
  maxLength,
  name,
  type,
}) {
  const [dateInputValue, setDateInputValue] = useState(defaultExpDate);
  useEffect(() => {
    if (defaultExpDate) {
      setDateInputValue(defaultExpDate);
    } else {
      setDateInputValue(undefined);
    }
  }, [defaultExpDate]);

  return (
    <InputField
      autoComplete="off"
      isDisabled={isDisabled}
      id="expiration-date-input"
      isInvalid={isInvalid}
      label={label}
      maxLength={maxLength}
      name={name}
      onChange={(e) => setDateInputValue(formatExpirationDate(e.target.value))}
      isRequired
      type={type}
      value={dateInputValue}
    >
      {isInvalid && expDateError?.key && (
        <ErrorMessage id="exp-date-error-msg" variant="error">
          <span data-property={expDateError.key}>{expDateError.value}</span>
        </ErrorMessage>
      )}
    </InputField>
  );
}
ExpirationDateInput.defaultProps = defaultProps;
ExpirationDateInput.propTypes = propTypes;
