import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'anf-core-react';

export default function CTAButtons({
  children,
  detailsHref,
  detailsText,
}) {
  const mainButton = (
    <Button
      isFullWidth
      variant="tertiary-dark"
      onClick={() => window.open(detailsHref)}
    >
      { detailsText }
    </Button>
  );

  return !children ? mainButton : (
    <>
      <div style={({ marginBottom: '20px' })}>{ mainButton }</div>
      <ButtonGroup variant="vertical-collapsed">{ children }</ButtonGroup>
    </>
  );
}

CTAButtons.defaultProps = {
  children: undefined,
};

CTAButtons.propTypes = {
  detailsHref: PropTypes.string.isRequired,
  detailsText: PropTypes.node.isRequired,
  children: PropTypes.node,
};
