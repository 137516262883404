import { useState, useEffect } from 'react';

const UseLocalStorage = (key, initialValue) => {
  const isClientSide = typeof window !== 'undefined';
  const [storedValue, setStoredValue] = useState(initialValue);

  useEffect(() => {
    if (isClientSide) {
      const currentStoredValue = JSON.parse(
        localStorage.getItem(key) || JSON.stringify(initialValue),
      );
      setStoredValue(currentStoredValue);
    }
  }, [isClientSide, key, initialValue]);

  const setValue = (value) => {
    setStoredValue(value);
    if (isClientSide) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };

  return [storedValue, setValue];
};

export default UseLocalStorage;
