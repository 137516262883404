/**
 * @callback RuleFn
 * @param {String} key - the key of the input
 * @param {string} value - the value of the input
 * @returns {boolean} - Indicates whether or not to prune
 */

/**
 * Prunes a JavaScript object based on a set of rules.
 *
 * @param {Object} data - The object to be pruned.
 * @param {Array<RuleFn>} rules - List of functions to determine whether to prune each element.
 * @returns {Object} - The pruned object.
 */
export function pruneObjectByRules(data, rules) {
  /**
   * Helper function to recursively prune the object.
   *
   * @param {Object} obj - The object to be pruned.
   * @returns {Object} - The pruned object.
   */
  function pruneRecursive(obj) {
    if (Array.isArray(obj)) {
      // Handle arrays
      return obj.map((item) => pruneRecursive(item));
    }

    if (obj !== null && typeof obj === 'object') {
      // Handle objects
      return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!rules.some((rule) => rule(key, value))) acc[key] = pruneRecursive(value);
        return acc;
      }, {});
    }

    // Handle other types (including null)
    return obj;
  }

  return pruneRecursive(data);
}

/**
 * Handle the persistence mapping of a cached JSON object
 *
 * @param {String} data - The JSON string representing the cache
 * @param {Array<RuleFn>} rules - List of functions to determine whether to prune
 * @returns {String} - The JSON string of the pruned object
 */
export default function persistenceMapper(data, rules) {
  try {
    const parsedData = JSON.parse(data);
    const prunedData = pruneObjectByRules(parsedData, rules);
    return JSON.stringify(prunedData);
  } catch { return data; }
}
