import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import SubscribeForm from '../SubscribeForm/SubscribeForm';
import SubscribeSuccessModal from './SubscribeSuccessModal';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { Column, Row } from '../Common/Grid';
import TmntText from '../Common/Text/TmntText';

const query = gql`
  {
    textFor {
      subscribeBlockHeader: pair(pairKey: "subscribeBlockHeader") {
        key
        value
      }
    }
    config {
      redirectUrl: string(name: "homeRedirectUrl")
    }
  }
`;

export default function EmailSubscribeBlock() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { loading, error, data } = useQuery(query);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { subscribeBlockHeader } = data.textFor;

  const handleOnClose = () => {
    const { config = {} } = data;
    const { redirectUrl } = config;
    setIsSuccessModalOpen(false);
    window.location.assign(redirectUrl);
  };

  return (
    <PageContentWrapper mainClass="email-subscribe-background">
      <div className="email-subscribe-container">
        <AccountBlock
          heading={(
            <SectionHeading>
              <h1 className="h3">
                <TmntText tmnt={subscribeBlockHeader} />
              </h1>
            </SectionHeading>
          )}
        >
          <Row>
            <Column>
              <SubscribeForm
                onSuccessfulSubmit={() => setIsSuccessModalOpen(true)}
              />
            </Column>
          </Row>
          <SubscribeSuccessModal
            isOpen={isSuccessModalOpen}
            onClose={handleOnClose}
          />
        </AccountBlock>
      </div>
    </PageContentWrapper>
  );
}
