import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Modal } from 'anf-core-react';
import TmntText from '../../Common/Text/TmntText';
import { LOADING_MESSAGE } from '../../Messages/Messages';
import { EMAIL_US_MODAL_TEXT_QUERY } from './emailUsOperations';
import EmailUsContainer from './EmailUsContainer';

const modalId = 'email-us-modal';

export default function EmailUsModal({
  isOpen,
  onClose,
}) {
  const { data, loading } = useQuery(EMAIL_US_MODAL_TEXT_QUERY);

  if (loading) return LOADING_MESSAGE;

  const { modalHeading } = data.textFor;

  const renderHeader = () => modalHeading && (
    <span className="h2" data-testid="email-us-modal-header">
      <TmntText tmnt={modalHeading} />
    </span>
  );

  return (
    <Modal
      id={modalId}
      isOpen={isOpen}
      onClose={onClose}
      heading={renderHeader()}
    >
      <EmailUsContainer onSuccess={onClose} />
    </Modal>
  );
}

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

EmailUsModal.propTypes = propTypes;
