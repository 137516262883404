import { placementsVar } from './ApplicationContext';

// Note: we should start placing update var functions here - to be used in hydrate
export const updatePlacementsVar = (ctx, id) => {
  const { query = {} } = ctx;
  const { placements } = query;

  if (!id) return;
  if (!placements) return;

  const parsedPlacements = placements.split('|');
  placementsVar().set(id, parsedPlacements);
};
