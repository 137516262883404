import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { ERROR_MESSAGE } from '../Messages/Messages';

export const ESPOT_QUERY = gql`
  query espotContentQuery($espotId: ID!) {
    espot(id: $espotId) {
      id
      content
    }
  }
`;

export default function ESpot({
  espotId,
  classList,
}) {
  const { loading, error, data } = useQuery(ESPOT_QUERY, { variables: { espotId } });

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const content = (data?.espot?.content) || '';

  return (
    <div
      id={espotId}
      data-testid="espot-content"
      data-espotname={espotId}
      className={`genericESpot aem-content ${classList}`}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}

ESpot.defaultProps = {
  classList: '',
};

ESpot.propTypes = {
  espotId: PropTypes.string.isRequired,
  classList: PropTypes.string,
};
