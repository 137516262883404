import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import {
  Button,
} from 'anf-core-react';
import FormHeading from './FormHeading';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { finishPaneQuery } from './operations';
import { ModalContext } from '../../context/ModalContext/ModalContextProvider';

export default function FinishPane() {
  const { loading, error: queryError, data: queryData } = useQuery(finishPaneQuery);
  const { closeModal } = useContext(ModalContext);
  const reloadPage = () => {
    localStorage.removeItem('loginSuccessMFE');
    window.location.reload();
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    finishPaneHeader,
    finishPaneBodyText,
    finishPaneButton,
  } = textFor;

  return (
    <FormWrapper>
      <FormGroup>
        <FormCell>
          <FormHeading
            formHeadingText={finishPaneHeader}
            formDescriptionText={finishPaneBodyText}
            formHeadingIcon="checkmark-circle-large-anf"
          />
        </FormCell>
        <FormCell>
          <Button
            variant="secondary"
            isFullWidth
            onClick={() => {
              closeModal();
              reloadPage();
            }}
          >
            <TmntText tmnt={finishPaneButton} />
          </Button>
        </FormCell>
      </FormGroup>
    </FormWrapper>
  );
}
