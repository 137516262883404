import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import * as FormStructure from '../../FormStructure';
import { refProp, ofTypes } from '../../../tools/customProps';

const formGroupProp = (props, propName) => {
  const types = [FormGroup, FormStructure.FormGroup];
  const prop = props[propName];

  ofTypes(types, prop);
};

const propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.node.isRequired,
  error: PropTypes.node,
  formRef: refProp,
  children: PropTypes.node,
  groups: PropTypes.arrayOf(formGroupProp),
  isInvalid: PropTypes.bool,
  isValidated: PropTypes.bool,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  error: undefined,
  children: undefined,
  formRef: undefined,
  groups: [],
  isInvalid: false,
  isValidated: false,
  onSubmit: undefined,
};

export default function Form({
  error,
  children,
  formRef,
  groups,
  id,
  isInvalid,
  isValidated,
  label,
  onSubmit,
}) {
  return (
    <form
      id={id}
      data-testid={id}
      onSubmit={onSubmit}
      noValidate={!isValidated}
      ref={formRef}
    >
      <FormStructure.FormWrapper>
        { label }
        { groups }
        {
          error && (
            <FormStructure.FormGroup isHidden={!isInvalid}>
              <FormStructure.FormCell>
                { error }
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
          )
        }
        { children }
      </FormStructure.FormWrapper>
    </form>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;
