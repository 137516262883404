import React from 'react';
import { Recommendations } from '@xp-utilities/web';
import RecommendationsWrapper from './RecommendationsWrapper';
import {
  RecommendationsContainerPropTypes,
  RecommendationsContainerDefaultProps,
} from './props';

export default function RecommendationsContainer({ clientId, placements }) {
  return (
    <RecommendationsWrapper clientId={clientId}>
      <Recommendations placements={placements} />
    </RecommendationsWrapper>
  );
}

RecommendationsContainer.propTypes = RecommendationsContainerPropTypes;
RecommendationsContainer.defaultProps = RecommendationsContainerDefaultProps;
