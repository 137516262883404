import React from 'react';
import SelfServiceOrderHelpPages from '../lib/components/CustomerSelfService/SelfServiceOrderHelpPages';
import AuthenticationModal from '../lib/components/SignIn/AuthenticationModal';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';
import {
  AuthenticationModalContext,
  ModalContextProvider,
} from '../lib/context/ModalContext';
import { SessionContextProvider } from '../lib/context/SessionContext/SessionContext';
import hydrate from '../lib/hydrate';

const frontend = 'SelfServiceOrderHelpFrontend';

function SelfServiceOrderHelpFrontend() {
  return (
    <SessionContextProvider frontend={frontend}>
      <ModalContextProvider context={AuthenticationModalContext}>
        <AuthenticationFormContextProvider>
          <AuthenticationModal />
          <SelfServiceOrderHelpPages />
        </AuthenticationFormContextProvider>
      </ModalContextProvider>
    </SessionContextProvider>
  );
}
hydrate({
  frontend: 'SelfServiceOrderHelpPagesFrontend',
  component: SelfServiceOrderHelpFrontend,
});
