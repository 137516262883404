import { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  DATABRIDGE_SESSION_QUERY,
  DATABRIDGE_USER_INFO_QUERY,
} from './operations';

const setUserInDatabridge = ({ userInfo }) => {
  if (!window.digitalData) return;

  const {
    id, hashedEmail, jwtToken, registrationDate,
  } = userInfo;

  const user = {
    id,
    hashedEmail,
    jwtToken,
    registrationDate: registrationDate?.formattedRegistrationDate,
  };

  window.digitalData.merge('user', user);
};

export default function CustomerDatabridge() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const onSessionQueryCompleted = ({ userState }) => setIsLoggedIn(userState?.isLoggedIn);

  useQuery(DATABRIDGE_SESSION_QUERY, {
    ssr: false,
    context: { batch: true },
    onCompleted: onSessionQueryCompleted,
  });

  useQuery(DATABRIDGE_USER_INFO_QUERY, {
    skip: !isLoggedIn,
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
    onCompleted: setUserInDatabridge,
  });

  return null;
}
