import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import {
  LegalText,
  Link as DSLink,
  Modal,
  ErrorMessage,
} from 'anf-core-react';
import useButtonState from '../Common/ButtonState/useButtonState';
import { TmntHtml, TmntText } from '../Common/Text';
import { Form } from '../Common/Form';
import * as FormStructure from '../FormStructure';
import { SWITCH_ACCOUNT_MUTATION, SWITCH_ACCOUNT_FORM_QUERY } from './operations';
import Loading from '../Common/Loading/Loading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { useTealiumTrigger } from '../../hooks';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default function SwitchAccountModal({
  isOpen,
  onClose,
}) {
  const [isError, setIsError] = useState(false);
  const [renderButton, registerPromise] = useButtonState();
  const [switchAccount] = useMutation(SWITCH_ACCOUNT_MUTATION, {
    onCompleted: () => { onClose(); },
    onError: () => { setIsError(true); },
  });

  const triggerTealiumTrackAction = useTealiumTrigger('universal', 'SWITCH_MY_ACCOUNT');

  const { data, loading, error } = useQuery(SWITCH_ACCOUNT_FORM_QUERY);

  if (error) return ERROR_MESSAGE;

  const textFor = data?.textFor || {};

  const handleSwitchAccount = () => {
    try {
      const call = new Promise((resolve, reject) => {
        switchAccount({ variables: { switchAccepted: true } })
          .then(({ data: resData }) => {
            if (!resData?.switchAccount?.success) reject();
            resolve();
          });
      });

      registerPromise(call);
      triggerTealiumTrackAction();
    } catch { /* do nothing */ }
  };

  const handleDeclineSwitchAccount = () => {
    switchAccount({ variables: { switchAccepted: false } });
  };

  const renderHeader = () => (
    <h2 className="h2">
      <TmntText tmnt={textFor?.switchAccountText} />
    </h2>
  );

  const screenReaderText = () => (
    <span className="screen-reader-text">
      <TmntText tmnt={textFor?.switchAccountText} />
    </span>
  );

  const renderDefaultAction = () => renderButton({
    initial: {
      onClick: handleSwitchAccount,
      children: (<TmntText tmnt={textFor?.switchAccountAccept} />),
      isFullWidth: true,
      variant: 'secondary',
    },
    processing: {
      children: (<TmntText tmnt={textFor?.buttonProcessing} />),
      isFullWidth: true,
      variant: 'secondary',
    },
    error: {
      children: (<TmntText tmnt={textFor?.buttonPleaseTryAgain} />),
      isFullWidth: true,
      variant: 'secondary',
    },
    success: {
      children: (<TmntText tmnt={textFor?.buttonSuccess} />),
      isFullWidth: true,
      variant: 'secondary',
    },
  });

  const renderDeclineAction = () => (
    <div className="switch-account-form__decline">
      <DSLink onClick={handleDeclineSwitchAccount}>
        <TmntText tmnt={textFor?.switchAccountDecline} />
      </DSLink>
    </div>
  );

  const renderError = () => (
    <ErrorMessage id="switch-account-error-message">
      <TmntText tmnt={textFor?.switchAccountError} />
    </ErrorMessage>
  );

  return (
    <Modal
      id="switch-account-modal"
      isOpen={isOpen}
      onClose={onClose}
      heading={renderHeader()}
    >
      <div className="switch-account-form">
        <Loading isLoading={loading}>
          <Form
            id="switch-account-form"
            isInvalid={isError}
            label={screenReaderText()}
          >
            <FormStructure.FormGroup>
              <FormStructure.FormCell>
                <h3 className="h3">
                  <TmntHtml tmnt={textFor?.switchAccountMessage} />
                </h3>
              </FormStructure.FormCell>
              <FormStructure.FormCell>
                <LegalText>
                  <TmntHtml tmnt={textFor?.switchAccountLegal} />
                </LegalText>
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
            <FormStructure.FormGroup isHidden={!isError}>
              <FormStructure.FormCell>
                {renderError()}
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
            <FormStructure.FormGroup>
              <FormStructure.FormCell>
                <div className="switch-account-form__actions">
                  {renderDefaultAction()}
                  {renderDeclineAction()}
                </div>
              </FormStructure.FormCell>
            </FormStructure.FormGroup>
          </Form>
        </Loading>
      </div>
    </Modal>
  );
}

SwitchAccountModal.propTypes = propTypes;
