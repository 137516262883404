import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

export default function SectionHeading({ SectionHeadingText }) {
  return (
    <span className="h2">
      <TmntText tmnt={SectionHeadingText} />
    </span>
  );
}

SectionHeading.propTypes = {
  SectionHeadingText: textProp.isRequired,
};
