import React from 'react';
import PropTypes from 'prop-types';

const propTypes = { children: PropTypes.node.isRequired };

export default function FormWrapper({ children }) {
  return (
    <div className="account-form">
      <div className="account-form-inner-wrapper">
        { children }
      </div>
    </div>
  );
}

FormWrapper.propTypes = propTypes;
