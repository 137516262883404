import { useState } from 'react';
import isValidEmail from '../tools/isValidEmail';

/**
 * @function useEmailValidation
 * @description Returns an object containing email validation pieces
 *
 *
 * @returns {Object} - An object containing the following properties and methods:
 *   - {Boolean} isInvalid - Stateful isInvalid variable
 *   - {Function} setIsInvalid - Setter for isInvalid, manually updates state
 *   - {Function} validateEmail - validates the passed email
 *
 */

export default function useEmailValidation() {
  const [isInvalid, setIsInvalid] = useState(false);

  const validateEmail = (email) => {
    const isValid = isValidEmail(email);
    setIsInvalid(!isValid);
    return isValid;
  };

  return { isInvalid, setIsInvalid, validateEmail };
}
