import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';

export default function AdditionalInfo({ SectionTitle, SectionContent }) {
  return (
    <div className="additional-info-section">
      <h2>
        <TmntText tmnt={SectionTitle} />
      </h2>
      <div className="additional-info-content">
        <TmntHtml tmnt={SectionContent} />
      </div>
    </div>
  );
}

AdditionalInfo.propTypes = {
  SectionTitle: textProp.isRequired,
  SectionContent: textProp.isRequired,
};
