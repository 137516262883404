import React from 'react';
import { RecommendationSliders } from '@xp-utilities/web';
import RecommendationsWrapper from './RecommendationsWrapper';
import { handleOnRecommendedProductClick } from './helpers';
import {
  RecommendationSlidersContainerPropTypes,
  RecommendationSlidersContainerDefaultProps,
} from './props';

export default function RecommendationSlidersContainer({ clientId, placements, useGrid }) {
  return (
    <RecommendationsWrapper clientId={clientId}>
      <RecommendationSliders
        placements={placements}
        onProductClick={handleOnRecommendedProductClick}
        useGrid={useGrid}
      />
    </RecommendationsWrapper>
  );
}

RecommendationSlidersContainer.propTypes = RecommendationSlidersContainerPropTypes;
RecommendationSlidersContainer.defaultProps = RecommendationSlidersContainerDefaultProps;
