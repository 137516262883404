import React, { useState, useCallback } from 'react';
import { Modal } from 'anf-core-react';
import ChangePasswordForm from './ChangePasswordForm';
import { useEventListener } from '../../hooks';

export const id = 'change-password-modal';

export default function ChangePasswordModalContainer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    if (typeof window !== 'undefined') {
      const isLoggedIn = localStorage.getItem('tempPwdLoggedIn');
      if (isLoggedIn) {
        window.location.reload();
      }
    }
  }, []);

  useEventListener(`${id}:open`, handleOpenModal);
  useEventListener(`${id}:close`, handleCloseModal);

  return (
    <Modal
      id="change-password-modal"
      isOpen={isModalOpen}
      variant="headerless"
      onClose={handleCloseModal}
      closeButtonLabel="Close"
      aria-modal="true"
    >
      <ChangePasswordForm onSuccessfulSubmission={handleCloseModal} />
    </Modal>
  );
}
