import React, { useState } from 'react';
import { useController } from 'react-hook-form';
import { Checkbox, TooltipWidget } from 'anf-core-react';
import TmntText from '../Text/TmntText';
import { keepMeSignedInAutoSelectedPropTypes, keepMeSignedInCheckboxPropTypes } from './props';

export const KEEP_ME_SIGN_IN_INPUT_NAME = 'keep-me-signed-in';

export function KeepMeSignedInCheckboxAutoSelected({
  control,
  description,
  id,
  title,
}) {
  const { field } = useController({
    name: KEEP_ME_SIGN_IN_INPUT_NAME,
    control,
    defaultValue: true,
  });

  const renderCheckboxWithDescription = () => (
    <>
      <span className="h4">
        <TmntText tmnt={title} />
      </span>
      <TmntText tmnt={description} />
    </>
  );

  return (
    <div data-testid="keepMeSignedIn">
      <Checkbox
        description={renderCheckboxWithDescription()}
        id={id}
        name={KEEP_ME_SIGN_IN_INPUT_NAME}
        onChange={field.onChange}
        value={KEEP_ME_SIGN_IN_INPUT_NAME}
        isChecked={field.value}
      />
    </div>
  );
}

KeepMeSignedInCheckboxAutoSelected.propTypes = keepMeSignedInAutoSelectedPropTypes;

export function KeepMeSignedInCheckbox({
  control,
  id,
  title,
  tooltipLabel,
  tooltipText,
}) {
  const [isTooltipOepn, setIsToolTipOpen] = useState(false);

  const { field } = useController({
    name: KEEP_ME_SIGN_IN_INPUT_NAME,
    control,
    defaultValue: false,
  });

  const handleOnClick = () => setIsToolTipOpen(!isTooltipOepn);

  const handleOnKepUp = (event) => {
    event.stopPropagation();
    setIsToolTipOpen(event.key !== 'Escape');
  };

  return (
    <div
      data-testid="keepMeSignedIn"
      style={{ alignItems: 'center', display: 'flex' }}
      className="keep-me-signed-in-container"
    >
      <Checkbox
        description={<TmntText tmnt={title} />}
        id={id}
        name={KEEP_ME_SIGN_IN_INPUT_NAME}
        onChange={field.onChange}
        value={KEEP_ME_SIGN_IN_INPUT_NAME}
        isChecked={field.value}
      />
      <TooltipWidget
        buttonLabelText={tooltipLabel?.value}
        contentPosition="top-center"
        contentID="keep-me-signed-in-tooltip"
        isOpen={isTooltipOepn}
        onClick={handleOnClick}
        onKeyUp={handleOnKepUp}
      >
        <TmntText tmnt={tooltipText} />
      </TooltipWidget>
    </div>
  );
}

KeepMeSignedInCheckbox.propTypes = keepMeSignedInCheckboxPropTypes;
