import React from 'react';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import TmntText from '../../Common/Text/TmntText';
import { textProp } from '../../../tools/customProps';
import { useTealiumTrigger } from '../../../hooks';

export default function ChatModule({
  isChatEnabled,
  enabledText,
  disabledText,
}) {
  const launchChat = () => window?.digitalData.trigger('event:chat.openChatWindow');
  const dataAnalyticsTrackAction = useTealiumTrigger('universal', 'CHAT_WITH_US');

  const handleOnClick = () => {
    launchChat();
    dataAnalyticsTrackAction();
  };

  return isChatEnabled
    ? (
      <Button
        classList="customer-care-button chat-icon-button"
        theme="inverse"
        variant="tertiary-dark"
        onClick={handleOnClick}
      >
        <TmntText tmnt={enabledText} />
      </Button>
    )
    : (
      <Button
        classList="customer-care-button chat-icon-button"
        theme="inverse"
        variant="tertiary-dark"
        isDisabled
      >
        <TmntText tmnt={disabledText} />
      </Button>
    );
}

ChatModule.defaultProps = {
  enabledText: undefined,
  disabledText: undefined,
};

ChatModule.propTypes = {
  isChatEnabled: PropTypes.bool.isRequired,
  enabledText: textProp,
  disabledText: textProp,
};
