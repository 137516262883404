import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  ErrorMessage, Icon, LegalText, Link as DSLink,
} from 'anf-core-react';
import { useForm } from 'react-hook-form';
import FormHeading from './FormHeading';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { temporaryPasswordPaneQuery, loginMutation } from './operations';
import { PaneContext } from '../../context/PaneContext/PaneContextProvider';
import { useLocalStorage } from '../../hooks';
import useButtonState from '../Common/ButtonState/useButtonState';
import PasswordField from '../Common/PasswordInputField/PasswordField';

const propTypes = { email: PropTypes.string };
const defaultProps = { email: '' };

export default function TemporaryPasswordPane({ email }) {
  const [renderButton, registerPromise] = useButtonState();
  const { goToPane } = useContext(PaneContext);
  const [, setIsLoginSuccess] = useLocalStorage('loginSuccessMFE', 'false');
  const { loading, error: queryError, data: queryData } = useQuery(temporaryPasswordPaneQuery);

  const {
    control,
    clearErrors,
    formState,
    setError,
    handleSubmit,
    reset,
    watch,
  } = useForm({ defaultValues: { temporaryPassword: '' } });

  const watchTemporaryPassword = watch('temporaryPassword');

  const [validateLoginMutation] = useMutation(loginMutation, {
    onCompleted: ({ login: { success } }) => {
      if (!success) {
        setError('temporaryPassword', { type: 'mutation' });
      }
    },
    onError: () => { setError('temporaryPassword', { type: 'mutation' }); },
  });

  const handleOnSubmit = async (fieldValues) => {
    clearErrors();

    const { temporaryPassword } = fieldValues;

    try {
      const call = new Promise((resolve, reject) => {
        validateLoginMutation({
          variables: {
            email,
            password: temporaryPassword,
            keepMeSignedIn: true,
          },
        }).then(({ data = {} }) => {
          const { login } = data;
          const { success } = login;
          if (!success) reject();
          else {
            setIsLoginSuccess('true');
            resolve();
          }
        })
          .catch(() => { reject(); });
      });
      registerPromise(call, {
        onSuccess: () => goToPane(2),
      });
    } catch { /* do nothing */ }
  };

  const handleSubmitWithoutPropogation = (event) => {
    event.stopPropagation();
    handleSubmit(handleOnSubmit)(event);
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    processing,
    success,
    pleaseTryAgain,
    header,
    temporaryPasswordPaneInstructions,
    temporaryPasswordLabel,
    legal,
    continueText,
    invalidPasswordError,
    sendNewPassword,
    showPasswordText,
    hidePasswordText,
  } = textFor;

  const addEmailToInstructions = () => {
    const { value } = temporaryPasswordPaneInstructions;
    const formattedEmail = `<b data-testid="user-email">${email}</b>`;

    return {
      ...temporaryPasswordPaneInstructions,
      value: value.replace('{0}', formattedEmail),
    };
  };

  return (
    <form onSubmit={handleSubmitWithoutPropogation} name="temporary-password-pane" noValidate>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <FormHeading
              formHeadingText={header}
              formDescriptionText={addEmailToInstructions()}
            />
          </FormCell>
          <FormCell>
            <PasswordField
              id="forgotPasswordInStoreSignup_temporary_password"
              isInvalid={Object.keys(formState.errors).includes('temporaryPassword')}
              label={temporaryPasswordLabel?.value}
              maxLength={25}
              name="temporaryPassword"
              showLabel={showPasswordText?.value}
              hideLabel={hidePasswordText?.value}
              control={control}
            >
              <ErrorMessage id="temporary-password-pane-error-msg" variant="error">
                <TmntText tmnt={invalidPasswordError} />
              </ErrorMessage>
            </PasswordField>
          </FormCell>
          <FormCell align="center">
            <LegalText>
              <TmntHtml tmnt={legal} />
            </LegalText>
          </FormCell>
          <FormCell>
            {
              renderButton({
                initial: {
                  children: (<TmntText tmnt={continueText} />),
                  isFullWidth: true,
                  type: 'submit',
                  variant: 'secondary',
                  isDisabled: !watchTemporaryPassword,
                },
                processing: {
                  children: (<TmntText tmnt={processing} />), isFullWidth: true, variant: 'secondary',
                },
                error: {
                  children: (<TmntText tmnt={pleaseTryAgain} />), isFullWidth: true, variant: 'secondary',
                },
                success: {
                  children: (
                    <>
                      <Icon icon="check" />
                      <TmntText tmnt={success} />
                    </>
                  ),
                  isFullWidth: true,
                  variant: 'secondary',
                },
              })
            }
          </FormCell>
          <FormCell align="center">
            <DSLink
              onClick={() => {
                goToPane(0);
                reset();
              }}
            >
              <TmntText tmnt={sendNewPassword} />
            </DSLink>
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

TemporaryPasswordPane.propTypes = propTypes;
TemporaryPasswordPane.defaultProps = defaultProps;
