import React from 'react';
import { useQuery } from '@apollo/client';
import { KeepMeSignedInCheckboxAutoSelected, KeepMeSignedInCheckbox } from './KeepMeSignedInCheckbox';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';
import { KEEP_ME_SIGNED_IN_QUERY } from './operations';
import { propTypes } from './props';

export default function KeepMeSignedIn({ id, control }) {
  const { loading, error: queryError, data: queryData = {} } = useQuery(KEEP_ME_SIGNED_IN_QUERY);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    tooltipText,
    tooltipLabel,
    checkboxTitle,
    checkboxDescription,
  } = queryData.textFor;

  const { config = {} } = queryData;
  const { hasAutoSelectKMSIEnabled = false } = config;

  if (hasAutoSelectKMSIEnabled) {
    return (
      <KeepMeSignedInCheckboxAutoSelected
        control={control}
        description={checkboxDescription}
        id={id}
        title={checkboxTitle}
      />
    );
  }

  return (
    <KeepMeSignedInCheckbox
      control={control}
      id={id}
      title={checkboxTitle}
      tooltipLabel={tooltipLabel}
      tooltipText={tooltipText}
    />
  );
}

KeepMeSignedIn.propTypes = propTypes;
