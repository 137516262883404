import React, {
  createContext, useState, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({});

const propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

const defaultProps = { context: ModalContext };

export default function ModalContextProvider({ context, children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => { setIsModalOpen(true); }, []);
  const closeModal = useCallback(() => { setIsModalOpen(false); }, []);

  const modalContextValue = useMemo(() => ({
    isModalOpen, openModal, closeModal,
  }), [isModalOpen, openModal, closeModal]);

  return (
    <context.Provider value={modalContextValue}>
      {children}
    </context.Provider>
  );
}

ModalContextProvider.propTypes = propTypes;
ModalContextProvider.defaultProps = defaultProps;
