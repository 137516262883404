import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import TmntText from '../../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';
import { EMAIL_US_MODULE_QUERY } from './emailUsOperations';
import EmailUsModal from './EmailUsModal';
import { useTealiumTrigger } from '../../../hooks';

export default function EmailUsModule() {
  const [modalOpen, setModalOpen] = useState(false);
  const dataAnalyticsTrackAction = useTealiumTrigger('universal', 'EMAIL_US');
  const { loading, error, data: queryData } = useQuery(EMAIL_US_MODULE_QUERY);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { emailUsLabelText } = queryData?.textFor || {};

  const handleModalOpen = () => {
    setModalOpen(true);
    dataAnalyticsTrackAction();
  };
  const handleModalClose = () => { setModalOpen(false); };

  return (
    <>
      <Button
        classList="customer-care-button email-us-button"
        theme="inverse"
        variant="tertiary-dark"
        onClick={handleModalOpen}
      >
        <TmntText tmnt={emailUsLabelText} />
      </Button>
      <EmailUsModal isOpen={modalOpen} onClose={handleModalClose} />
    </>
  );
}
