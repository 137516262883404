import React from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import {
  Button, IconBlock, Icon, Modal,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { textProp } from '../../tools/customProps';
import FormWrapper from '../FormStructure/FormWrapper';
import FormGroup from '../FormStructure/FormGroup';
import FormCell from '../FormStructure/FormCell';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const subscribeModalQuery = gql`
  {
    textFor {
      headingText: pair (pairKey: "subscribeSuccessModalHeading") {...values}
      thanksText: pair (pairKey: "thanks") {...values}
      messageText: pair (pairKey: "subscribeSuccessModalMessage") {...values}
      closeButtonText: pair (pairKey: "subscribeSuccessModalCloseButtonText") {...values}
      continueButtonText: pair(pairKey: "subscribeSuccessModalButtonText") {...values}
    }
  }

  fragment values on TextPair {
    key
    value
  }
`;

export default function SubscribeSuccessModal({
  isOpen, onClose, variant, modalHeadingText,
}) {
  const { loading, error: queryError, data: queryData } = useQuery(subscribeModalQuery);
  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    headingText,
    thanksText,
    messageText,
    closeButtonText,
    continueButtonText,
  } = queryData.textFor;

  return (
    <Modal
      isOpen={isOpen}
      heading={(<h2><TmntText tmnt={modalHeadingText ?? headingText} /></h2>)}
      id="email-subscribe-success-modal"
      closeButtonLabel={closeButtonText?.value}
      closeButtonLabelKey={closeButtonText?.key}
      variant={variant}
      onClose={onClose}
    >
      <div className="scope-1892">
        <FormWrapper>
          <FormGroup>
            <FormCell>
              <IconBlock orientation="vertical" icon={<Icon icon="checkmark-circle" size="l" />}>
                <TmntText tmnt={thanksText} />
              </IconBlock>
            </FormCell>
            <FormCell>
              <div style={{ textAlign: 'center', fontSize: '15px' }}>
                <TmntText tmnt={messageText} />
              </div>
            </FormCell>
            <FormCell>
              <Button
                variant="secondary"
                type="button"
                isFullWidth
                onClick={onClose}
              >
                <TmntText tmnt={continueButtonText} />
              </Button>
            </FormCell>
          </FormGroup>
        </FormWrapper>
      </div>
    </Modal>
  );
}

SubscribeSuccessModal.defaultProps = {
  isOpen: false,
  onClose: undefined,
  variant: 'headerless',
  modalHeadingText: undefined,
};

SubscribeSuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  variant: PropTypes.string,
  modalHeadingText: textProp,
};
