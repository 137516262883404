import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconBlock } from 'anf-core-react';

export default function ProfileWelcomeMessage({
  children,
  memberSince,
  tierImageAltText,
  tierImageSrc,
  nextRewardText,
}) {
  return (
    <div className="logged-in-pwm-block" data-testid="profile-welcome-message">
      {
        tierImageSrc === 'nonLoyalty' ? (
          children
        ) : (
          <IconBlock
            icon={(
              <Icon
                icon={tierImageSrc}
                isImage
                labelText={tierImageAltText}
                size="l"
              />
            )}
          >
            {children}
          </IconBlock>
        )
      }
      { memberSince && (<div>{memberSince}</div>) }
      { nextRewardText && <hr className="signin-separator" />}
    </div>
  );
}

ProfileWelcomeMessage.defaultProps = {
  memberSince: undefined,
  tierImageAltText: '',
  nextRewardText: '',
};

ProfileWelcomeMessage.propTypes = {
  children: PropTypes.node.isRequired,
  tierImageSrc: PropTypes.string.isRequired,
  memberSince: PropTypes.node,
  tierImageAltText: PropTypes.string,
  nextRewardText: PropTypes.string,
};
