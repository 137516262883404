import { gql } from '@apollo/client';
import { textPairFragment, fieldOptionListValueFragment, apiResponseFragment } from '../../query/fragments';
import {
  formGroupFragmentStr,
  formFragmentStr,
  inputFragmentStr,
  multiSelectFragmentStr,
  optionValuesFragmentStr,
} from '../../query/fragmentStrings';

export const MY_INFO_FORM_QUERY = gql`
  query MY_INFO_FORM_QUERY {
    textFor {
        buttonUpdateText: pair(pairKey: "update") {...textPair}
        buttonUpdatedText: pair(pairKey: "updated") {...textPair}
        buttonProcessingText: pair (pairKey: "processing") {...textPair}
        buttonErrorText: pair (pairKey: "pleaseTryAgain") {...textPair}
        buttonInvalidBirthdayErrorText: pair (pairKey: "formAgeError") {...textPair}
        myInfoHeader: pair(pairKey: "myInfoHeader") {...textPair}
        myInfoForm: form(fieldKey: "myInfoConfig") { ...form }
        editButton: pair(pairKey: "editButton") {...textPair}
        myInfoSuccessMessage: pair(pairKey: "myInfoSuccessMessage") {...textPair}
        passwordCharacterLengthText: pair(pairKey: "passwordCharacterLength") {...textPair}
        shoppingPreferenceText: listField(fieldKey: "shoppingPreference") {...fieldOptionListValues}
        myInfoLegalText: legal(fieldKey: "myInfoFormLegalConsent") {
          text {...textPair}
        }
    }
    userInfo {
        email
        firstName
        lastName
        phone
        preferences {
            gender
            birthDay
            birthMonth
            birthYear
            preferredStore
        }
        response {
            success
        }
    }
    userState { isLoggedIn }
  }

  ${textPairFragment}
  ${inputFragmentStr}
  ${multiSelectFragmentStr}
  ${formGroupFragmentStr}
  ${formFragmentStr}
  ${optionValuesFragmentStr}
  ${fieldOptionListValueFragment}
`;

export const UPDATE_MY_INFO = gql`
    mutation UPDATE_USER_INFO(
        $email: String!,
        $firstName: String!,
        $lastName: String!,
        $primaryPhone: String,
        $preference: UserPreferencesInput
    ) {
        updateUserInfo(
            email: $email,
            firstName: $firstName,
            lastName: $lastName,
            primaryPhone: $primaryPhone,
            preference: $preference,
        ) {
            ...response
        }
    }

    ${apiResponseFragment}
`;
