import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Loading from '../Common/Loading/Loading';
import * as FormStructure from '../FormStructure';
import { ERROR_MESSAGE } from '../Messages/Messages';
import BazaarVoiceReviewModal from './BazaarVoiceReviewModal';
import NumberOfItemsHeading from './NumberOfItemsHeading';
import { REVIEW_ITEMS_LIST_QUERY } from './operations';
import ProductCardItem from './ProductCardItem';

const loadBazaarVoiceScript = (url) => {
  const bvScript = document.createElement('script');
  bvScript.async = true;
  bvScript.type = 'text/javascript';
  bvScript.src = url;
  bvScript.setAttribute('data-testid', 'bazaarvoice-review-sdk');
  document.body.appendChild(bvScript);

  return () => {
    document.body.removeChild(bvScript);
  };
};

export default function ReviewItemsList() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [activeSku, setActiveSku] = useState('');

  const handleReviewClick = (itemSku) => {
    setIsModalVisible(true);
    setActiveSku(itemSku);
  };

  const {
    loading: queryLoading,
    error: queryError,
    data: queryData,
  } = useQuery(REVIEW_ITEMS_LIST_QUERY, {
    ssr: false,
    context: { batch: true },
  });

  useEffect(() => {
    const bazaarVoiceReviewSdkUrl = queryData?.config?.bazaarVoiceReviewSdkUrl;
    if (!bazaarVoiceReviewSdkUrl) {
      return () => {};
    }

    const isScriptAlreadyLoaded = document.querySelector(`script[src="${bazaarVoiceReviewSdkUrl}"]`) !== null;
    if (isScriptAlreadyLoaded) {
      return () => {};
    }

    const cleanup = loadBazaarVoiceScript(bazaarVoiceReviewSdkUrl);
    setIsScriptLoaded(true);

    return cleanup;
  }, [queryData]);

  if (queryError) return ERROR_MESSAGE;

  const {
    headingText,
    subHeadingText,
    reviewSubmittedNote,
    reviewItems,
  } = queryData?.getAllReviewItems || {};

  const {
    writeAReviewText,
  } = queryData?.textFor || {};

  return (
    <FormStructure.FormWrapper>
      <Loading isLoading={queryLoading}>
        <FormStructure.FormGroup>
          <FormStructure.FormCell isLoading={queryLoading}>
            {!queryLoading && (
            <NumberOfItemsHeading
              numberOfItems={reviewItems?.length}
              headingText={headingText}
              subHeadingText={subHeadingText}
            />
            )}
          </FormStructure.FormCell>
        </FormStructure.FormGroup>
        {reviewItems?.length > 0 && (
          <FormStructure.FormGroup>
            <div
              data-testid="review-items-list"
              className="review-items-list"
            >
              {/*
                Adding this div with `data-bv-show="inpage_submission"` to prevent console errors
                from the BazaarVoice SDK script. This div will be hidden using `display: none`
                to avoid affecting the UI, but it's necessary to keep the SDK from throwing
                errors when it tries to render the in-page submission modal.
              */}
              <div data-bv-show="inpage_submission" style={{ display: 'none' }} />
              {reviewItems?.map((item) => (
                <FormStructure.FormCell key={item.shortSku} isLoading={queryLoading}>
                  <ProductCardItem
                    item={item}
                    reviewSubmittedNote={reviewSubmittedNote}
                    writeAReviewText={writeAReviewText}
                    onReviewClick={handleReviewClick}
                  />
                </FormStructure.FormCell>
              ))}
              {isScriptLoaded && isModalVisible && (
              <BazaarVoiceReviewModal
                reviewSku={activeSku}
                onClose={() => setIsModalVisible(false)}
              />
              )}
            </div>
          </FormStructure.FormGroup>
        )}
      </Loading>
    </FormStructure.FormWrapper>
  );
}
