import {
  Button,
  Icon,
  IconBlock,
  ProductCard,
  ProductDetail,
  ProductImage,
  ProductName,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  reviewSubmittedNote: textProp.isRequired,
  onReviewClick: PropTypes.func.isRequired,
  writeAReviewText: textProp.isRequired,
};

export default function ProductCardItem({
  item,
  reviewSubmittedNote,
  writeAReviewText,
  onReviewClick,
}) {
  const { shortSku } = item;
  const reviewedSkus = window.sessionStorage.getItem('reviewedSkus');
  const isReviewed = (reviewedSkus || '').includes(shortSku);

  return (
    <div className="review-product-card">
      <ProductCard
        productImage={(
          <ProductImage
            alt={item.name}
            size="xl"
            src={item.imageSrc}
          />
      )}
        productName={(
          <>
            <ProductName>
              <span>{item.name}</span>
            </ProductName>
            <span>{item.gender}</span>
          </>
      )}
        header={isReviewed ? (
          <ProductDetail>
            <div className="review-item-submitted-note">
              <IconBlock
                orientation="horizontal"
                icon={<Icon icon="exclamation" size="s" />}
              >
                <TmntText tmnt={reviewSubmittedNote} />
              </IconBlock>
            </div>
          </ProductDetail>
        ) : <span />}
        productPrice={(<span />)}
        variant="boxed"
        style={{ margin: '10px' }}
      >
        <ProductDetail>
          <span>
            {item.size}
            ,
            {' '}
            {item.color.toLowerCase()}
          </span>
        </ProductDetail>
        <br />
        <Button
          variant="tertiary-light"
          onClick={() => {
            onReviewClick(shortSku);
          }}
        >
          <TmntText tmnt={writeAReviewText} />
        </Button>
      </ProductCard>
    </div>
  );
}

ProductCardItem.propTypes = propTypes;
