// eslint-disable-next-line import/prefer-default-export
export function getRandomNumber(minimum = 1, maximum = 5) {
  const min = Math.ceil(minimum);
  const max = Math.floor(maximum);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomizeArray(array) {
  const values = [...array];
  const results = [];
  while (values.length > 0) {
    const index = Math.floor(Math.random() * values.length);
    const value = values[index];
    values.splice(index, 1);
    results.push(value);
  }
  return results;
}

export const getRandomFloat = (max, min) => (Math.random() * (max - min) + min);

export const getRandomDate = (start = new Date(2000, 0, 1), end = new Date()) => new Date(
  start.getTime() + Math.random() * (end.getTime() - start.getTime()),
);

export const generateRandomString = (minLength, maxLength) => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const length = Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return result;
};
