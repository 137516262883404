import React from 'react';
import PropTypes from 'prop-types';

export default function FieldTitle({
  title,
  titleTextKey,
  optionalText,
  optionalTextKey,
}) {
  return (
    <p style={{ marginBottom: '15px' }} aria-hidden>
      <span className="h4" data-text-key={titleTextKey}>{title}</span>
      {optionalText && (
        <>
          {' ('}
          <span data-text-key={optionalTextKey}>{optionalText}</span>
          )
        </>
      )}
    </p>
  );
}

FieldTitle.defaultProps = {
  titleTextKey: undefined,
  optionalText: undefined,
  optionalTextKey: undefined,
};

FieldTitle.propTypes = {
  title: PropTypes.node.isRequired,
  titleTextKey: PropTypes.string,
  optionalText: PropTypes.string,
  optionalTextKey: PropTypes.string,
};
