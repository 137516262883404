import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { Modal } from 'anf-core-react';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import TmntText from '../Common/Text/TmntText';
import SubscribeForm from '../SubscribeForm/SubscribeForm'; // TODO: Rename
import SubscribeSuccessModal from '../EmailSubscribeBlock/SubscribeSuccessModal';

const id = 'footer-email-subscribe-modal';

const FOOTER_SUBSCRIBE_MODAL_QUERY = gql`
  query FOOTER_SUBSCRIBE_MODAL_QUERY {
    textFor {
      subscribeBlockHeader: pair (pairKey: "subscribeBlockHeader") {...textPair}
      closeButtonText: pair (pairKey: "footerSubscribeModalCloseButtonText") {...textPair}
    }
    config {
      redirectUrl: string(name: "homeRedirectUrl")
    }
  }

  ${textPairFragment}
`;

export default function FooterEmailSubscribeModal({ isOpen, onClose, email }) {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { loading, error: queryError, data: queryData } = useQuery(FOOTER_SUBSCRIBE_MODAL_QUERY);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    onClose();
  }, [onClose]);

  const handleOnSuccessModalClose = () => {
    const { config = {} } = queryData;
    const { redirectUrl } = config;
    setShowSuccessModal(false);
    window.location.assign(redirectUrl);
  };

  const handleSuccessForm = useCallback(() => {
    setIsModalOpen(false);
    setShowSuccessModal(true);
  }, []);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { subscribeBlockHeader, closeButtonText } = queryData.textFor;

  return (
    <>
      <Modal
        id={id}
        heading={(<h2><TmntText tmnt={subscribeBlockHeader} /></h2>)}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        closeButtonLabel={closeButtonText?.value}
        closeButtonLabelKey={closeButtonText?.key}
      >
        <div className="scope-1892">
          <SubscribeForm email={email} onSuccessfulSubmit={handleSuccessForm} />
        </div>
      </Modal>
      <SubscribeSuccessModal
        variant=""
        modalHeadingText={subscribeBlockHeader}
        isOpen={showSuccessModal}
        onClose={handleOnSuccessModalClose}
      />
    </>
  );
}

FooterEmailSubscribeModal.defaultProps = {
  email: '',
};

FooterEmailSubscribeModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string,
};
