export const isDisabledField = (field) => {
  const { __typename, optionList = [] } = field;
  if (__typename?.toLowerCase() !== 'multiselect') return false;
  if (!optionList.length) return true;
  return false;
};

export const isRequiredField = ({ name }, requireList = []) => requireList.includes(name);

export const isInvalidField = ({ name }, invalidList = []) => invalidList.includes(name);

export const getFieldMaxLengthFromMap = ({ name }, widthMap = new Map()) => widthMap.get(name);

export const getFormData = (form) => Object.fromEntries([...new FormData(form).entries()]);

export const findBadParams = (graphQLErrors, requiredFields) => (
  graphQLErrors.reduce((acc, error) => {
    error.extensions.response.body.error.forEach((errorItem) => {
      if (errorItem.errorCode === 'BAD_PARAMETER') {
        errorItem.params.forEach((param) => {
          if (requiredFields.includes(param)) acc.push(param);
        });
      }

      if (errorItem.errorKey?.includes('_ERR_STATE_ZIP_INVALID')) {
        acc.push('postalCode');
      }
    });

    return acc;
  }, [])
);

export const getValidationType = (country, name) => {
  // if active country doesn't belong to countries needing pre-validation, return undefined
  const countriestoValidate = ['CN', 'HK', 'JP', 'SG'];
  if (!countriestoValidate.find((countrytoValidate) => (
    countrytoValidate === country
  ))) return undefined;

  switch (name) {
    case 'name':
    case 'firstName':
    case 'lastName':
    case 'address1':
      return 'chineseRegex';
    case 'city':
      // only Japan has pre-validaiton for city
      return country === 'JP' ? 'chineseRegex' : undefined;
    case 'postalCode':
      // Hong Kong does not have pre-validation for postal code
      return country !== 'HK' ? `postal${country}` : undefined;
    default:
      return undefined;
  }
};

export const isPrevalidated = (country, name, value) => {
  // find validation type associated with field
  const validationType = getValidationType(country, name);

  // if there is no pre-validation associated with field, return true
  if (!validationType) return true;

  const addressFieldRegex = {
    chineseRegex: /^((?![_×÷])[\u0027-\u007B\u007D-\u007E\u00BF-\u00DF\u00E0-\u00FF\u0100-\u024F\u4e00-\u62ff\u6300-\u77ff\u7800-\u8cff\u8d00-\u9fcc\u3000-\u303F\u3040-\u309F\u30A0-\u30FF ])+$/,
    postalCN: /^\d{6}$/,
    postalSG: /^[0-9]{3}-?[0-9]{3}$/,
    postalJP: /^[0-9]{3}-?[0-9]{4}$/,
  };

  // test field value against regex code associated with field
  const validValue = new RegExp(addressFieldRegex[validationType]);
  if (validValue.test(value)) return true;

  // if field value does not pass regex test, return false
  return false;
};

export const getInvalidFieldsFromFormData = (formData, requiredList) => {
  const { country } = formData;

  return Object.entries(formData)
    .filter(([key]) => key !== 'country')
    .reduce((acc, [key, value]) => {
      const isRequired = isRequiredField({ name: key }, requiredList);
      if (!isRequired) return acc;
      if (!value) acc.push(key);

      const prevalidated = isPrevalidated(country, key, value);
      if (!prevalidated) acc.push(key);

      return acc;
    }, []);
};

export const getValueFromData = ({ name }, data) => {
  if (!data) return undefined;

  const value = data[name];
  if (!value) return undefined;

  return value;
};

export const getOnChange = (field, callbackQueries) => {
  if (field.__typename !== 'MultiSelect') return undefined; // eslint-disable-line no-underscore-dangle
  if (field.optionList.length === 0) return undefined;

  return callbackQueries[field.name];
};

export const formatOptionList = (optionList) => (
  optionList.map(({ id, label }) => ({ optionValue: id, text: label }))
);
