import React, { useCallback, useState } from 'react';
import { Button } from 'anf-core-react';
import { BUTTON_STATES, DEFAULT_BUTTON_STATE_TIMEOUT } from './ButtonState';

const DEFAULT_PROPS = {
  [BUTTON_STATES.initial]: {},
  [BUTTON_STATES.processing]: { isDisabled: true, isProcessing: true },
  [BUTTON_STATES.error]: { isDisabled: true },
  [BUTTON_STATES.success]: { isDisabled: true },
};

// eslint-disable-next-line react/jsx-props-no-spreading
const renderButton = (props) => (props && <Button {...props} />);

/**
 * useButtonState
 * @param {Object} props - DS Button properties for each of the states
 * [initial, processing, error, success]
 * @param {Object} settings - Settings object container timeouts
 * @returns {Array} - tuple array containing a `render` and `register` method
 */
export default function useButtonState(props = {}, settings = {}) {
  const {
    successTimeout = DEFAULT_BUTTON_STATE_TIMEOUT,
    errorTimeout = DEFAULT_BUTTON_STATE_TIMEOUT,
  } = settings;

  const [state, setState] = useState(BUTTON_STATES.initial);

  const reset = useCallback((timeout) => {
    setTimeout(() => { setState(BUTTON_STATES.initial); }, timeout);
  }, []);

  const register = useCallback(async (promise, { onSuccess, onError } = {}) => {
    setState(BUTTON_STATES.processing);

    try {
      await promise;
      setState(BUTTON_STATES.success);
      if (onSuccess) onSuccess();
      reset(successTimeout);
    } catch {
      setState(BUTTON_STATES.error);
      if (onError) onError();
      reset(errorTimeout);
    }
  }, [
    errorTimeout,
    successTimeout,
    reset,
  ]);

  const getProps = useCallback((currentState) => {
    switch (currentState) {
      case BUTTON_STATES.processing:
        return props.processing;
      case BUTTON_STATES.success:
        return props.success;
      case BUTTON_STATES.error:
        return props.error;
      default:
        return props.initial;
    }
  }, [props]);

  const render = (overrideProps = {}) => {
    const currentProps = {
      ...DEFAULT_PROPS[state],
      ...getProps(state),
      ...(overrideProps[state] ?? {}),
    };

    return renderButton(currentProps);
  };

  return [render, register];
}
