import { useEffect } from 'react';

const instances = new Map();

export default function useInstance(id, initialValue = null) {
  useEffect(() => {
    const setInitialValue = () => instances.set(id, initialValue);
    setInitialValue();
    return () => { setInitialValue(); };
  }, [id, initialValue]);

  const getInstance = () => instances.get(id);
  const setInstance = (value) => instances.set(id, value);

  return [getInstance, setInstance];
}
