import React, { useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Modal } from 'anf-core-react';
import PropTypes from 'prop-types';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import CancelOrderModalForm from './CancelOrderModalForm';
import TmntText from '../Common/Text/TmntText';
import { apiResponseFragment, fieldOptionListValueFragment, textPairFragment } from '../../query/fragments';

const cancelOrderModalTextQuery = gql`
  query CancelOrderModalText {
    textFor {
      headingText: pair(pairKey: "cancelOrderModalHeading") { ...textPair }
      cancelOrderModalErrorMessageText: pair(pairKey: "cancelOrderModalErrorMessageText") { ...textPair }
      legalText: pair(pairKey: "cancelOrderModalLegalText") { ...textPair }
      submit: pair(pairKey: "cancelOrderModalButtonText") { ...textPair }
      linkText: pair(pairKey: "cancelOrderModalLinkButtonText") { ...textPair }
      pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
      processing: pair(pairKey: "processing") { ...textPair }
      success: pair(pairKey: "success") { ...textPair }
      cancelOrderOptions: listField(fieldKey: "cancelOrderOptions") { ...fieldOptionListValues }
    }
  }

  ${textPairFragment}
  ${fieldOptionListValueFragment}
`;

const cancelOrderModalMutation = gql`
  mutation CancelOrder($orderNumber: String!, $email: String!, $reason: String) {
    cancelOrder(orderNumber: $orderNumber, email: $email, reason: $reason) { ...response }
  }

  ${apiResponseFragment}
`;

let textCache;

export default function CancelOrderModal({
  email, isOpen, onClose, orderNumber,
}) {
  const [checkedValue, setCheckedValue] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);

  const { loading, error, data: textData } = useQuery(cancelOrderModalTextQuery);

  const [runCancelOrder] = useMutation(cancelOrderModalMutation, {
    onCompleted: ({ cancelOrder }) => {
      const { success, errors } = cancelOrder;
      const { message } = errors && errors.length && errors[0];

      if (!success) {
        setAlertMessage({ variant: 'error', message });
      } else {
        setAlertMessage({ variant: 'success', message });
      }
    },
    onError: () => {
      setAlertMessage({ variant: 'error', message: textCache.cancelOrderModalErrorMessageText });
    },
  });

  textCache = textData?.textFor || null;

  const reset = () => {
    setCheckedValue(null);
    setAlertMessage(null);
  };

  const handleRadioChange = (event) => setCheckedValue(event.target.value);

  const handleOnCancel = async (e) => {
    e.preventDefault();
    setAlertMessage(null);
    return new Promise((resolve, reject) => {
      runCancelOrder({ variables: { email, orderNumber, reason: checkedValue } })
        .then(({ data = {} }) => {
          const { cancelOrder } = data;
          if (!cancelOrder?.success) reject();
          else resolve();
        });
    });
  };

  const handleOnSuccessfulCancel = () => {
    reset();
    onClose(); // close
    document.dispatchEvent(new CustomEvent('orderHistory:update')); // update
  };

  const handleOnKeepOrder = () => {
    reset();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      heading={
        textCache?.headingText
          ? (
            <span className="h2">
              <TmntText tmnt={textCache.headingText} />
            </span>
          )
          : null
      }
      id="cancelOrderModal"
      onClose={onClose}
    >
      {
        (() => {
          if (loading) return LOADING_MESSAGE;
          if (error) return ERROR_MESSAGE;

          return (
            <CancelOrderModalForm
              alertMessage={alertMessage}
              cancelOptionsGroup={textCache.cancelOrderOptions}
              checkedValue={checkedValue}
              handleRadioChange={handleRadioChange}
              onCancelOrder={handleOnCancel}
              onSuccessfulCancel={handleOnSuccessfulCancel}
              onKeepOrder={handleOnKeepOrder}
              text={{
                legalText: textCache.legalText,
                linkText: textCache.linkText,
                submit: textCache.submit,
                pleaseTryAgain: textCache.pleaseTryAgain,
                processing: textCache.processing,
                success: textCache.success,
              }}
            />
          );
        })()
      }
    </Modal>
  );
}

CancelOrderModal.defaultProps = {
  email: undefined,
  onClose: undefined,
  orderNumber: undefined,
};

CancelOrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  email: PropTypes.string,
  onClose: PropTypes.func,
  orderNumber: PropTypes.string,
};
