import React from 'react';
import { gql, useQuery } from '@apollo/client';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import MarketingPreferencesFormContainer from '../MarketingPreferencesForm/MarketingPreferencesFormContainer';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import SectionHeading from '../Common/SectionHeading/SectionHeading';

const textQuery = gql`
  {
    textFor {
      heading: pair(pairKey: "marketingPrefsFormBlockHeading") {
        key
        value
      }
    }
    userInfo {
      email,
      response {
        success,
        errors {
          status
        }
      }
    }
  }
`;

export default function MarketingPreferencesFormBlock() {
  const { loading, error, data } = useQuery(textQuery, {
    context: { batch: true },
  });

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const {
    heading,
  } = data.textFor;
  const { email } = data.userInfo;

  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <h2 className="h3" data-property={heading?.key}>{heading?.value}</h2>
        </SectionHeading>
      )}
    >
      <MarketingPreferencesFormContainer email={email} />
    </AccountBlock>
  );
}
