import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import { textProp } from '../../tools/customProps';
import { TmntText } from '../Common/Text';
import SwitchAccountModal from './SwitchAccountModal';

const propTypes = {
  crossBorderText: textProp.isRequired,
  isSwitchAccount: PropTypes.bool.isRequired,
  switchAccountInfoText: textProp.isRequired,
  switchAccountButtonText: textProp.isRequired,
};

export default function AccountInfo({
  crossBorderText,
  isSwitchAccount,
  switchAccountInfoText,
  switchAccountButtonText,
}) {
  const [switchAccountOpen, setSwitchAccountOpen] = useState(false);

  const handleOpenSwitchAccount = () => { setSwitchAccountOpen(true); };
  const handleCloseSwitchAccount = () => { setSwitchAccountOpen(false); };

  return (
    <main className="cross-border-rep" data-testid="account-info">
      <div className="cross-border-rep__info">
        {isSwitchAccount ? (
          <>
            <div className="cross-border-rep__message">
              <p>
                <TmntText tmnt={switchAccountInfoText} />
              </p>
            </div>
            <div className="cross-border-rep__actions">
              <Button variant="tertiary-dark" onClick={handleOpenSwitchAccount}>
                <TmntText tmnt={switchAccountButtonText} />
              </Button>
            </div>
          </>
        ) : (
          <div className="cross-border-rep__message">
            <h3 className="h3">
              <TmntText tmnt={crossBorderText} />
            </h3>
          </div>
        )}
      </div>
      <SwitchAccountModal isOpen={switchAccountOpen} onClose={handleCloseSwitchAccount} />
    </main>
  );
}

AccountInfo.propTypes = propTypes;
