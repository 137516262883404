import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useWindowWidth } from '@react-hook/window-size';
import { useDatabridgeSubscribe } from '@xp-utilities/web';
import { Icon } from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import ChatModule from './ChatModule/ChatModule';
import MessagingBlock from './MessageModule/MessagingBlock';
import ViewPhoneNumber from './ViewPhoneNumberModule/ViewPhoneNumber';
import EmailUsModule from './EmailUsModule';

export const CONTACT_US_BLOCK_QUERY = gql`
  query ContactUsBlockQuery {
    textFor {
      chatEnabledLabelText: pair(pairKey: "chatEnabledLabel") {...textPair}
      chatDisabledLabelText: pair(pairKey: "chatDisabledLabel") {...textPair}
      phoneNumbersDesktopLabelText: pair(pairKey: "phoneNumbersDesktopLabel") {...textPair}
      callUsMobileLabelText: pair(pairKey: "callUsMobileLabel") {...textPair}
      textUsLabel: pair(pairKey: "textUsLabel") {...textPair}
      customerMessageNumber: pair(pairKey: "customerMessageNumber") {...textPair}
      phoneNumberModalLabel: pair(pairKey: "phoneNumberLabel") {...textPair}
    }
    config {
      customerCareIcons: string(name: "customerCareIcons")
      imageServiceUrl: string(name: "imageServiceUrl")
    }
    emailUsModuleEnabled: featureFlag(
      key: "custcare-email-module-enabled",
      defaultValue: false
    )
    viewPhoneNumberModuleEnabled: featureFlag(
      key: "custcare-call-module-enabled",
      defaultValue: false
    )
    whatsAppModuleEnabled: featureFlag(
      key: "custcare-whatsapp-module-enabled",
      defaultValue: false
    )
  }
  ${textPairFragment}
`;

export default function ContactUsBlock() {
  const { loading, error, data: queryData } = useQuery(CONTACT_US_BLOCK_QUERY);

  // Window Width
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(retrievedWindowWidth);
  }, [retrievedWindowWidth]);

  // Use the custom hook for chatSettings.isOnline
  const isChatEnabled = useDatabridgeSubscribe('chatSettings.isOnline', true);

  const isMobileView = windowWidth <= 699;
  const groupOrientation = isMobileView ? 'vertical' : 'horizontal';

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const {
    emailUsModuleEnabled,
    viewPhoneNumberModuleEnabled,
    whatsAppModuleEnabled,
  } = queryData;

  const icon = queryData.config?.customerCareIcons || 'anf';
  const whatsAppIcon = `${queryData?.config?.imageServiceUrl}social_whatsapp-white.svg`;

  const {
    chatEnabledLabelText,
    chatDisabledLabelText,
    phoneNumbersDesktopLabelText,
    callUsMobileLabelText,
    textUsLabel,
    phoneNumberModalLabel,
    customerMessageNumber,
  } = queryData.textFor;

  const chatIcon = `chat-${icon}`;
  const mailIcon = `mail-${icon}`;
  const phoneIcon = `phone-${icon}`;
  const smsIcon = `sms-${icon}`;

  // View Phone Number Module
  const buttonLabel = isMobileView ? (
    <TmntText tmnt={callUsMobileLabelText} />
  ) : <TmntText tmnt={phoneNumbersDesktopLabelText} />;

  const viewPhoneNumberModalHeader = <TmntText tmnt={phoneNumberModalLabel} />;

  return (
    <div className="contactUs-container" data-orientation={groupOrientation} data-testid="cust-care-container">
      <div className="cust-care-block">
        <div className="cust-care-icon"><Icon icon={chatIcon} size="m" /></div>
        <ChatModule
          data-testid="chat-module"
          className="help-chat-button-container"
          isChatEnabled={isChatEnabled}
          enabledText={chatEnabledLabelText}
          disabledText={chatDisabledLabelText}
        />
      </div>
      {emailUsModuleEnabled ? (
        <div className="cust-care-block">
          <div className="cust-care-icon"><Icon icon={mailIcon} size="m" /></div>
          <EmailUsModule />
        </div>
      ) : null}
      {viewPhoneNumberModuleEnabled ? (
        <div className="cust-care-block">
          <div className="cust-care-icon"><Icon icon={phoneIcon} size="m" /></div>
          <ViewPhoneNumber
            buttonLabel={buttonLabel}
            modalHeaderText={viewPhoneNumberModalHeader}
          />
        </div>
      ) : null}
      {whatsAppModuleEnabled ? (
        <MessagingBlock
          isMobileView={isMobileView}
          customerMessageNumber={customerMessageNumber?.value}
          smsIcon={smsIcon}
          whatsAppIcon={whatsAppIcon}
          textUsLabel={textUsLabel}
        />
      ) : null}
    </div>
  );
}
