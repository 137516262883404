import PropTypes from 'prop-types';

export const propTypes = {
  autoComplete: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired, // Matches DS Component
  maxLength: PropTypes.number, // Matches DS Component
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  customValidation: PropTypes.func,
  control: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  defaultValue: PropTypes.string,
  errorMessage: PropTypes.node,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
};

export const defaultProps = {
  children: undefined,
  customValidation: undefined,
  control: null,
  defaultValue: '',
  errorMessage: undefined,
  isDisabled: false,
  isRequired: true, // defaulting to true
  maxLength: null,
  type: 'text',
};
