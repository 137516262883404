export default function validateErrors(errors) {
  const unauthorizedStatuses = [401, 403];

  const findErrorStatusFromList = ({ extensions }, list) => (
    list.includes(extensions?.response?.status)
  );

  if (!errors?.find((err) => findErrorStatusFromList(err, unauthorizedStatuses))) {
    return true;
  }

  return false;
}
