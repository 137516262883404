import React from 'react';
import PropTypes from 'prop-types';
import { DiscountCard } from 'anf-core-react';
import GraphicBadge from 'anf-core-react/components/GraphicBadge';
import { decodeHtmlEntities } from '../../../tools/html/decode';

const propTypes = {
  discountTitle: PropTypes.string,
  discountSecondaryTitle: PropTypes.string,
  graphicBadge: PropTypes.string,
  discountDescription: PropTypes.string,
  id: PropTypes.string,
  discountMessage: PropTypes.string,
  discountDetails: PropTypes.node,
  orientation: PropTypes.string,
};
const defaultProps = {
  discountTitle: undefined,
  discountSecondaryTitle: undefined,
  graphicBadge: undefined,
  discountDescription: undefined,
  id: undefined,
  discountMessage: undefined,
  discountDetails: false,
  orientation: undefined,
};
export default function DiscountCardContainer({
  discountTitle,
  discountSecondaryTitle,
  discountDescription,
  graphicBadge,
  discountDetails,
  discountMessage,
  id,
  orientation,
}) {
  const renderBadge = (badge) => (
    <GraphicBadge>
      { decodeHtmlEntities(badge) }
    </GraphicBadge>
  );

  return (
    <DiscountCard
      id={id}
      variant="badged"
      orientation={orientation}
      discountCardContent={{
        discountTitle: decodeHtmlEntities(discountTitle),
        discountSecondaryTitle: decodeHtmlEntities(discountSecondaryTitle),
        discountDescription: decodeHtmlEntities(discountDescription),
        discountDetails,
        graphicBadge: renderBadge(graphicBadge),
      }}
      discountCardActions={{ discountMessage }}
    />
  );
}
DiscountCardContainer.propTypes = propTypes;
DiscountCardContainer.defaultProps = defaultProps;
