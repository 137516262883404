import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'anf-core-react';

export default function SectionHeading({
  alignedRight,
  headingLevel,
  href,
  children,
  icon,
}) {
  const alignedClass = alignedRight ? 'align-right' : '';

  return (
    <div
      className={`section-heading ${alignedClass}`}
      role={alignedRight ? undefined : 'heading'}
      aria-level={alignedRight ? undefined : headingLevel}
    >
      {icon && <Icon icon={icon} />}
      {href ? (
        <a
          href={href}
          className="link"
        >
          {children}
        </a>
      ) : (
        children
      )}
    </div>
  );
}

SectionHeading.defaultProps = {
  icon: undefined,
  headingLevel: '2',
  href: undefined,
  alignedRight: false,
};

SectionHeading.propTypes = {
  children: PropTypes.node.isRequired,
  alignedRight: PropTypes.bool,
  icon: PropTypes.string,
  href: PropTypes.node,
  headingLevel: PropTypes.node,
};
