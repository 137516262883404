import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useDatabridgeSubscribe } from '@xp-utilities/web';
import UpperFunnelToaster from './UpperFunnelToaster';
import { ERROR_MESSAGE } from '../Messages/Messages';
import TmntHtml from '../Common/Text/TmntHtml';
import { UPPER_FUNNEL_CONTENT_QUERY, UPPER_FUNNEL_USER_STATE_QUERY, UPPER_FUNNEL_CONFIG_QUERY } from './operations';
import { PAGE_KINDS } from '../../tools/constants';

function UpperFunnelLoginContent() {
  const { loading, error, data } = useQuery(UPPER_FUNNEL_CONTENT_QUERY);

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor } = data;

  return (
    <UpperFunnelToaster>
      <div className="upperfunnel-content">
        <TmntHtml tmnt={textFor?.upperFunnelToasterContent} />
      </div>
    </UpperFunnelToaster>
  );
}

function UpperFunnelLoginContainer() {
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const [isGenericUser, setIsGenericUser] = useState(false);

  // Use the custom hook for page.pageKind
  const pageKind = useDatabridgeSubscribe('page.pageKind');

  useEffect(() => {
    setShouldRenderComponent(pageKind !== PAGE_KINDS.checkout && pageKind !== PAGE_KINDS.cart);
  }, [pageKind]);

  const onUserStateQueryCompleted = ({ userState } = {}) => {
    const { isGeneric } = userState;
    setIsGenericUser(isGeneric);
  };

  useQuery(UPPER_FUNNEL_USER_STATE_QUERY, {
    ssr: false,
    context: { batch: true },
    onCompleted: onUserStateQueryCompleted,
    skip: !shouldRenderComponent,
  });

  if (!isGenericUser) return null;
  if (!shouldRenderComponent) return null;

  return <UpperFunnelLoginContent />;
}

export default function UpperFunnelLogin() {
  const { loading, error: queryError, data: queryData } = useQuery(UPPER_FUNNEL_CONFIG_QUERY);

  if (loading) return null;
  if (queryError) return ERROR_MESSAGE;

  const { hasUpperFunnelLoginEnabled } = queryData.config;

  if (!hasUpperFunnelLoginEnabled) return null;

  return <UpperFunnelLoginContainer />;
}
