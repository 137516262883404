import React from 'react';
import PropTypes from 'prop-types';
import hydrate from '../lib/hydrate';
import RecommendationsAEMSlidersContainer from '../lib/components/Recommendations/RecommendationAEMSlidersContainer';
import { placementsVar } from '../lib/context/ApplicationContext/ApplicationContext';

const frontend = 'RecommendationAEMSlidersFrontend';
const instances = ['sales-1', 'homepage-1'];

const propTypes = { clientId: PropTypes.string.isRequired };

function RecommendationAEMSlidersFrontend({ clientId }) {
  return (
    <RecommendationsAEMSlidersContainer
      clientId={clientId}
      placements={placementsVar().get(clientId)}
    />
  );
}

instances.forEach((id) => {
  hydrate({
    frontend,
    component: RecommendationAEMSlidersFrontend,
    clientId: id,
  });
});

RecommendationAEMSlidersFrontend.propTypes = propTypes;
