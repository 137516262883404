import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import UnsubscribeForm from '../UnsubscribeForm/UnsubscribeForm';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import UnsubscribeSuccessModal from './UnsubscribeSuccessModal';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { Column, Row } from '../Common/Grid';

const query = gql`
  {
    textFor {
      unsubscribeBlockHeader: pair(pairKey: "unsubscribeBlockHeader") {
        key
        value
      }
    }
    config {
      redirectUrl: string(name: "homeRedirectUrl")
    }
  }
`;

export default function EmailUnsubscribeBlock() {
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);

  const { loading, error, data } = useQuery(query);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { unsubscribeBlockHeader } = data.textFor;

  const handleSuccessfulSubmit = (brands) => {
    setSelectedBrands(brands);
    setIsSuccessModalOpen(true);
  };
  const handleOnClose = () => {
    const { config } = data;
    const { redirectUrl } = config;
    setSelectedBrands([]);
    setIsSuccessModalOpen(false);
    window.location.assign(redirectUrl);
  };

  return (
    <PageContentWrapper mainClass="email-unsubscribe-background">
      <div className="email-unsubscribe-container">
        <AccountBlock
          heading={(
            <SectionHeading>
              <h1 className="h3" data-property={unsubscribeBlockHeader.key}>
                {unsubscribeBlockHeader.value}
              </h1>
            </SectionHeading>
          )}
        >
          <Row>
            <Column>
              <UnsubscribeForm onSuccessfulSubmit={handleSuccessfulSubmit} />
            </Column>
          </Row>
          <UnsubscribeSuccessModal
            isOpen={isSuccessModalOpen}
            selectedBrands={selectedBrands}
            onClose={handleOnClose}
          />
        </AccountBlock>
      </div>
    </PageContentWrapper>
  );
}
