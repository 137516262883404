import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntHtml from '../Common/Text/TmntHtml';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  title: textProp.isRequired,
  description: textProp.isRequired,
};

export default function MissingPoints({
  title,
  description,
}) {
  return (

    <div className="missing-points">
      <div className="title">
        <TmntText tmnt={title} />
      </div>
      <div className="description">
        <TmntHtml tmnt={description} />
      </div>
    </div>
  );
}

MissingPoints.propTypes = propTypes;
