import React from 'react';
import { Button, ButtonGroup } from 'anf-core-react';
import { gql, useQuery } from '@apollo/client';
import Heading from './PickedForYouHeading';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import TmntText from '../Common/Text/TmntText';
import { textPairFragment } from '../../query/fragments';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

const pickedForYouNonPersoRegionQuery = gql`
  query PickedForYouText {
    textFor {
        pickedForYouNonPersoRegionHeaderText: pair(pairKey: "pickedForYouNonPersoRegionHeaderText") {...textPair}
        pickedForYouNonPersoRegionSubText: pair(pairKey: "pickedForYouNonPersoRegionSubText") {...textPair}
        pickedForYouNonPersoRegionShopMensBoysButton: pair(pairKey: "pickedForYouNonPersoRegionShopMensBoysButton") {...textPair}
        pickedForYouNonPersoRegionShopWomensGirlsButton: pair(pairKey: "pickedForYouNonPersoRegionShopWomensGirlsButton") {...textPair}
    }
    config {
      mensBoysUrl: string(name: "shopMensBoysUrl")
      womensGirlsUrl: string(name: "shopWomensGirlsUrl")
    }
  }

  ${textPairFragment}
`;

export default function PickedForYouNonPersoRegionExperience() {
  const { loading, error, data } = useQuery(pickedForYouNonPersoRegionQuery);
  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = data;

  const {
    pickedForYouNonPersoRegionHeaderText,
    pickedForYouNonPersoRegionSubText,
    pickedForYouNonPersoRegionShopMensBoysButton,
    pickedForYouNonPersoRegionShopWomensGirlsButton,
  } = textFor;

  const {
    mensBoysUrl,
    womensGirlsUrl,
  } = config;

  return (
    <PageContentWrapper mainClass="picked-for-you">
      <div className="picked-for-you-non-perso-region">
        <div className="non-perso-region-header">
          <Heading
            headingText={pickedForYouNonPersoRegionHeaderText}
            subText={pickedForYouNonPersoRegionSubText}
          />
          <div className="shop-buttons">
            <ButtonGroup
              marginTop="l"
              variant="grid"
            >
              <Button
                href={mensBoysUrl}
                variant="tertiary-dark"
              >
                <TmntText tmnt={pickedForYouNonPersoRegionShopMensBoysButton} />
              </Button>
              <Button
                href={womensGirlsUrl}
                variant="tertiary-dark"
              >
                <TmntText tmnt={pickedForYouNonPersoRegionShopWomensGirlsButton} />
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </div>
    </PageContentWrapper>
  );
}
