import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.node.isRequired,
  isHidden: PropTypes.bool,
  hasTransition: PropTypes.bool,
};

const defaultProps = {
  isHidden: false,
  hasTransition: false,
};

export default function FormGroup({
  children,
  isHidden,
  hasTransition,
}) {
  return (
    <div
      className="account-form-group"
      data-display={!isHidden}
      data-transition={hasTransition}
    >
      { children }
    </div>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;
