import { useEffect, useState } from 'react';

export default function useUpperFunnelToaster(storeTimerValue, key = 'upper-funnel-switch', initialValue = true) {
  let currentStoredValue = null;
  if (typeof window !== 'undefined') {
    currentStoredValue = JSON.parse(localStorage.getItem(key) || JSON.stringify(initialValue));
  }
  const [storedValue, setStoredValue] = useState(currentStoredValue);
  useEffect(() => {
    const removeLocalStorageValue = () => {
      localStorage.removeItem(key);
      setStoredValue(initialValue);
    };
    const interval = setInterval(removeLocalStorageValue, storeTimerValue);
    return () => {
      clearInterval(interval);
    };
  }, [initialValue, key, storeTimerValue]);

  const setValue = (value) => {
    setStoredValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [storedValue, setValue];
}
