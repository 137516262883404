import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import Icon from '../Common/Icon/Icon';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LoadingButton } from '../Messages/Messages';
import { SIGN_IN_BUTTON_QUERY } from './operations';

const propTypes = { onClick: PropTypes.func };
const defaultProps = { onClick: () => {} };

export default function SignInButton({ onClick }) {
  const { loading, error, data } = useQuery(SIGN_IN_BUTTON_QUERY);

  if (loading) return <LoadingButton />;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { signInOrJoinText } = textFor;

  const handleClick = () => {
    document.dispatchEvent(new CustomEvent('upper-funnel:close'));
    onClick();
  };

  return (
    <Button
      variant="borderless"
      onClick={handleClick}
    >
      <Icon icon="account-circle" />
      <TmntText tmnt={signInOrJoinText} />
    </Button>
  );
}

SignInButton.propTypes = propTypes;
SignInButton.defaultProps = defaultProps;
