import { createContext } from 'react';
import ModalContextProvider, { ModalContext } from './ModalContextProvider';

const AuthenticationModalContext = createContext({});
const SignInToasterContext = createContext({});

export {
  AuthenticationModalContext,
  ModalContext,
  ModalContextProvider,
  SignInToasterContext,
};
