import React from 'react';
import PropTypes from 'prop-types';

/*
  Note: anf-core/anf-core-react v7.107.1 removed IconText, so this will act as a
  intermediary component to handle rendering icons. Have spoken to Luke about introducing
  an Icon component.
*/
export default function Icon({ icon }) {
  return (
    icon !== 'spinner'
      ? (
        <span data-icon={icon} data-testid="icon" />
      )
      : (
        <span
          className="spin-container"
          data-hidden={false}
          data-state="processing"
          aria-hidden="true"
          data-testid="icon"
        >
          <span className="spinner" data-testid="spinner" />
        </span>
      )
  );
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
};
