import { gql } from '@apollo/client';
import { textPairFragmentStr } from '../../../query/fragmentStrings';

// eslint-disable-next-line import/prefer-default-export
export const KEEP_ME_SIGNED_IN_QUERY = gql`
  query KEEP_ME_SIGNED_IN_TEXT_QUERY {
    textFor {
      checkboxTitle: pair(pairKey: "keepMeSignedIn") {...textPair}
      checkboxDescription: pair(pairKey: "keepMeSignedInCheckboxUncheckMsg") {...textPair}
      tooltipText: pair(pairKey: "keepMeSignedInCheckboxTooltip") {...textPair}
      tooltipLabel: pair(pairKey: "keepMeSignedInTooltipLabel") {...textPair}
    }
    config {
      hasAutoSelectKMSIEnabled: bool(name: "hasAutoSelectKMSIEnabled")
    }
  }

  ${textPairFragmentStr}
`;
