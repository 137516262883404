import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  CardInput, PaymentIcon, InputField, ErrorMessage,
} from 'anf-core-react';
import { textProp } from '../../../tools/customProps';
import {
  formatCardNumber,
  getCardBrand,
  getCardBrandIcon,
  getCardMaxLength,
  maskCardNumber,
} from '../../../tools/CreditCardHelper/CreditCardHelper';
import { PaymentsContext } from '../../Payments/PaymentsContextProvider';
import { cardNumberObscureChar } from '../../Payments/paymentConstants';

const defaultProps = {
  cardNumError: undefined,
  defaultCardBrand: undefined,
  defaultCardNumber: undefined,
};

const propTypes = {
  cardNumError: textProp,
  defaultCardBrand: PropTypes.string,
  defaultCardNumber: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default function CreditCardInput({
  cardNumError,
  defaultCardBrand,
  defaultCardNumber,
  isDisabled,
  isInvalid,
  label,
  name,
  type,
}) {
  const [cardIcon, setCardIcon] = useState('default-card');
  const [maxLength, setMaxLength] = useState(19);
  const [cardInputValue, setCardInputValue] = useState(defaultCardNumber);
  const paymentsContext = useContext(PaymentsContext);

  useEffect(() => {
    if (defaultCardBrand && defaultCardNumber) {
      setCardIcon(getCardBrandIcon(defaultCardBrand));
      setCardInputValue(maskCardNumber(defaultCardBrand, defaultCardNumber, cardNumberObscureChar));
    } else {
      setCardInputValue(undefined);
      setCardIcon('default-card');
    }
  }, [defaultCardNumber, defaultCardBrand]);

  function handleInputChange(inputValue) {
    // need to remove spaces from previous number formatting first or weirdness ensues
    const tempInputValue = inputValue.replace(/\s+/g, '');

    // If user hasnt even entered 4 digits, we dont want to run logic
    // We need 4 digits for cardType matching
    if (tempInputValue.length >= 4) {
      let formattedValue;
      const cardBrand = getCardBrand(tempInputValue, paymentsContext.ccRegexArray);
      const tempCardIcon = getCardBrandIcon(cardBrand);

      // check cardType retrieval was successful before running other logic
      if (cardBrand) {
        // maxLength wasnt being applied correctly so I am making sure
        // we dont run formatting logic if inputValue.length is above maximum for cardType
        if (cardBrand === '003' && tempInputValue.length < 16) {
          formattedValue = formatCardNumber(tempInputValue, cardBrand);
        }
        if (cardBrand !== '003' && tempInputValue.length < 17) {
          formattedValue = formatCardNumber(tempInputValue, cardBrand);
        }

        setCardIcon(tempCardIcon);
        setMaxLength(getCardMaxLength(cardBrand));
        setCardInputValue(formattedValue);
      }
    } else {
      setCardIcon('default-card');
      setMaxLength(19);
      setCardInputValue(tempInputValue);
    }
  }

  return (
    <CardInput
      inputField={(
        <InputField
          autoComplete="off"
          key="input"
          isDisabled={isDisabled}
          id="card-number-input"
          isInvalid={isInvalid}
          label={label}
          maxLength={maxLength}
          name={name}
          onChange={(e) => handleInputChange(e.target.value)}
          isRequired
          value={cardInputValue}
          type={type}
        >
          {isInvalid && cardNumError?.key && (
            <ErrorMessage id="card-number-error-msg" variant="error">
              <span data-property={cardNumError.key}>{cardNumError.value}</span>
            </ErrorMessage>
          )}
        </InputField>
      )}
      paymentIcon={(
        <PaymentIcon
          iconName={cardIcon}
          labelText={cardIcon === 'amex' ? 'american express' : cardIcon}
        />
      )}
    />
  );
}
CreditCardInput.defaultProps = defaultProps;
CreditCardInput.propTypes = propTypes;
