import { gql } from '@apollo/client';

export const DATABRIDGE_SESSION_QUERY = gql`
  query DATABRIDGE_SESSION_QUERY {
    userState { isLoggedIn }
  }
`;

export const DATABRIDGE_USER_INFO_QUERY = gql`
  query DATABRIDGE_USER_INFO_QUERY {
    userInfo {
      id
      hashedEmail
      jwtToken
      registrationDate {
        formattedRegistrationDate 
      }
    }
  }
`;
