import React, { useState } from 'react';
import { Button, AccountBlock, ErrorMessage } from 'anf-core-react';
import { useQuery, useMutation } from '@apollo/client';
import PaymentCard from './PaymentCard';
import PaymentModal from './PaymentModal';
import PageContentWrapper from '../PageContentWrapper/PageContentWrapper';
import { PaymentsContextProvider } from './PaymentsContextProvider';
import { Column, Row } from '../Common/Grid';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import TmntText from '../Common/Text/TmntText';
import {
  paymentModuleQuery,
  getAllPaymentsQuery,
  setDefaultPaymentRequest,
} from './paymentOperations';

const ccRegexArray = [
  { brand: '001', icon: 'visa', regexValue: /^4\d{3}/ },
  { brand: '002', icon: 'mastercard', regexValue: /^5[1-5]\d{2}|^2\d{1}/ },
  { brand: '003', icon: 'amex', regexValue: /^3[47]\d{2}/ },
  { brand: '004', icon: 'discover', regexValue: /^6(011|2\d{2}|4[4-9]\d{1}|5\d{2})|^8\d{3}|^30[0-5]\d{1}|^3095|^3[689]\d{2}/ },
  { brand: '007', icon: 'jcb', regexValue: /^30[89]\d{1}|^3[01][09][^345]|^3[135]\d{2}/ },
];

export default function PaymentPage() {
  const [allPayments, setAllPayments] = useState(undefined);
  const [activePayment, setActivePayment] = useState(undefined);
  const [isEditState, setIsEditState] = useState(false);
  const [isErrorState, setIsErrorState] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [queryContext, setQueryContext] = useState({
    text: undefined,
    config: undefined,
    paymentCount: 0,
    ccRegexArray,
  });

  const {
    loading: textLoading,
    error: textError,
    data: textData,
  } = useQuery(paymentModuleQuery, {
    onCompleted: ({ textFor, config }) => {
      setQueryContext((prevState) => ({
        ...prevState,
        text: textFor,
        config,
        ccRegexArray,
      }));
    },
    onError: () => setIsErrorState(true),
  });

  const { refetch } = useQuery(getAllPaymentsQuery, {
    onCompleted: ({ getAllPayments }) => {
      setAllPayments(getAllPayments);
      setQueryContext((prevState) => ({
        ...prevState,
        paymentCount: getAllPayments?.payments ? getAllPayments.payments.length : 0,
      }));
      setIsErrorState(false);
    },
    onError: () => setIsErrorState(true),
  });

  const [defaultPaymentMutation] = useMutation(setDefaultPaymentRequest, {
    refetchQueries: ['GetAllPayments'],
    onError: () => setIsErrorState(true),
  });

  const setPaymentAsDefault = async (paymentId) => (
    defaultPaymentMutation({ variables: { id: paymentId } })
  );

  const setToEditState = (id) => {
    setIsEditState(true);
    setIsModalOpen(true);
    setActivePayment(allPayments.payments.find((payment) => payment.id === id));
  };

  const setToAddState = () => {
    setIsEditState(false);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsEditState(false);
    setIsErrorState(false);
    setIsModalOpen(false);
  };

  const sortPayments = (payments, defaultId) => {
    if (payments && payments.length && defaultId) {
      const defaultIndex = payments.findIndex((payment) => payment.id === defaultId);

      if (defaultIndex !== -1) {
        const updatedPayments = [...payments];
        updatedPayments.unshift(...updatedPayments.splice(defaultIndex, 1));

        return updatedPayments;
      }
    }

    return payments;
  };

  const createPaymentCards = (allPaymentsObj) => {
    const defaultId = allPaymentsObj.default;
    const sortedPayments = sortPayments(allPaymentsObj.payments, defaultId);

    return sortedPayments.map((payment, i) => {
      const key = `payment-${i}`;
      return (
        <Column xs="8" lg="4" key={key}>
          <PaymentCard
            isDefault={defaultId === payment.id}
            onSetPaymentAsDefault={setPaymentAsDefault}
            onSetToEditState={setToEditState}
            payment={payment}
          />
        </Column>
      );
    });
  };

  function renderPageContent() {
    if (isErrorState) {
      return (
        <ErrorMessage>
          <TmntText tmnt={textData?.textFor.paymentApiError} />
        </ErrorMessage>
      );
    }
    if (!isErrorState && allPayments?.payments.length > 0) {
      return createPaymentCards(allPayments);
    }
    return (
      <div data-testid="no-payments">
        <TmntText tmnt={textData?.textFor.noPayments} />
      </div>
    );
  }

  if (textLoading) return LOADING_MESSAGE;
  if (textError) return ERROR_MESSAGE;

  return (
    <PaymentsContextProvider context={queryContext}>
      <PageContentWrapper mainClass="payment-page">
        <AccountBlock
          heading={(
            <h3 className="h3" data-property="GLB_SAVED_PAYMENTS">
              <TmntText tmnt={textData?.textFor.pageTitle} />
            </h3>
          )}
        >
          <Row>
            {renderPageContent()}
            <Column xs="8">
              <hr className="hr" />
            </Column>
            <Column xs="8" md="3">
              <Button
                classList="payment-page-button"
                type="submit"
                variant="secondary"
                data-property="GLB_ADD_PAYMENT"
                isFullWidth
                onClick={setToAddState}
              >
                <TmntText tmnt={textData?.textFor.buttonAddNew} />
              </Button>
            </Column>
          </Row>
        </AccountBlock>
        <PaymentModal
          isEditState={isEditState}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onComplete={refetch}
          payment={activePayment}
        />
      </PageContentWrapper>
    </PaymentsContextProvider>
  );
}
