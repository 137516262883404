import { gql } from '@apollo/client';
import { errorFragmentStr } from './fragmentStrings';

// NOTE: deprecate these in favor of fragmentStrings - do not need gql portion

export const userStateFragment = gql`
  fragment userState on UserState {
    sessionId
    isGeneric
    isGuest
    isLoggedIn
    isRemembered
  }
`;

export const textPairFragment = gql`
  fragment textPair on TextPair {
    key
    value
  }
`;

export const apiResponseFragment = gql`
  ${errorFragmentStr}

  fragment response on APIResponse {
    success
    errors {...error}
  }
`;

// Forms
export const formErrorFragment = gql`
  ${textPairFragment}

  fragment formError on FormError {
    field
    text {...textPair}
  }
`;

export const formResponseFragment = gql`
  ${formErrorFragment}

  fragment formResponse on FormResponse {
    success
    errors {...formError}
  }
`;

export const labelValuePairFragment = gql`
  ${textPairFragment}

  fragment labelValuePair on LabelValuePair {
    label {...textPair}
    value
  }
`;

// listFieldGroups - FieldOptionList and SectionedFieldOptionList
export const optionValuesFragment = gql`
  ${textPairFragment}

  fragment optionValues on Option {
    optionValue
    text {...textPair}
    description {...textPair}
  }
`;

export const fieldOptionListValueFragment = gql`
  ${textPairFragment}
  ${optionValuesFragment}

  fragment fieldOptionListValues on FieldOptionList {
    label {...textPair}
    error {...textPair}
    options {...optionValues}
  }
`;

export const sectionedFieldOptionListValuesFragment = gql`
  ${textPairFragment}
  ${optionValuesFragment}

  fragment sectionedFieldOptionListValues on SectionedFieldOptionList {
    label {...textPair}
    error {...textPair}
    optionSections {...optionValues}
  }
`;

// Order history
export const transactionSummaryFragment = gql`
  ${textPairFragment}

  fragment row on TransactionRow {
    isNegative
    label {...textPair}
    price
  }

  fragment transaction on TransactionSummary {
    isNegative
    itemLabel {...textPair}
    priceLabel {...textPair}
    footer {...row}
    rows {...row}
  }
`;

export const orderHistoryItemFragment = gql`
  ${textPairFragment}
  ${transactionSummaryFragment}
  ${labelValuePairFragment}

  fragment order on OrderHistoryItem {
    isEmpty
    heading {...textPair}
    number
    state
    status { ...labelValuePair }
    summary { ...transaction }
    detailsLink { ...labelValuePair }
    ctaGroup { ...labelValuePair }
  }
`;

export const paymentFragment = gql`
  fragment payment on Payment {
    id
    cardBrand
    cardExpireMonth
    cardExpireYear
    cardNumber
    displayInfo
    requiresCVV
  }
`;

export const addressFragment = gql`
  fragment address on Address {
    id
    address1
    address2
    city
    country
    district
    firstName
    isAPOFPO
    isDefaultBilling
    isDefaultShipping
    lastName
    name
    phoneticName
    poBox
    postalCode
    prefecture
    province
    state
    title
    zone
    isBillingAllowed
    isShippingAllowed
  }
`;
