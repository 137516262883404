import React from 'react';
import { useController } from 'react-hook-form';
import { InputField } from 'anf-core-react';
import { propTypes, defaultProps } from './props';
import isValidPhoneNumber from '../../../tools/isValidPhoneNumber';

export default function ControlledPhoneNumberField(props) {
  const {
    id, control, name, isRequired, defaultValue, label, children,
  } = props;

  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: {
      validate: (value) => {
        if (value) {
          return isValidPhoneNumber(value);
        }
        return !isRequired;
      },
    },

  });

  return (
    <InputField
      autoComplete="tel"
      id={id}
      name={name}
      isInvalid={fieldState.invalid}
      isRequired={isRequired}
      label={label}
      maxLength={25}
      type="tel"
      value={field.value}
      onChange={field.onChange}
    >
      { children }
    </InputField>
  );
}

ControlledPhoneNumberField.propTypes = propTypes;
ControlledPhoneNumberField.defaultProps = defaultProps;
