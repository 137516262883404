import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RecommendationSliders } from '@xp-utilities/web';
import RecommendationsWrapper from './RecommendationsWrapper';
import {
  RecommendationAEMSlidersContainerPropTypes,
  RecommendationAEMSlidersContainerDefaultProps,
} from './props';

export default function RecommendationAEMSlidersContainer({ clientId, placements }) {
  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const [targetElement, setTargetElement] = useState(null);

  useEffect(() => {
    const handleContentLoaded = () => {
      setIsContentLoaded(true);
    };

    document.addEventListener('aem-content-homepage:recs', handleContentLoaded);

    return () => {
      document.removeEventListener('aem-content-homepage:recs', handleContentLoaded);
    };
  }, []);

  useEffect(() => {
    if (!isContentLoaded) return;

    const sliders = document.querySelectorAll('.js-recommendation-slider');
    if (sliders) setTargetElement(sliders);
  }, [isContentLoaded]);

  if (!isContentLoaded) return null;
  if (!targetElement?.length) return null;

  const handleOnProductClick = (event, product = {}) => {
    const {
      brand,
      collection,
      faceout,
      id,
      imageId,
      name,
    } = product;

    const eventDetails = {
      productId: id,
      collectionId: collection,
      imageId,
      productName: name,
      productBrand: brand,
      returnFocus: event.target,
      faceout,
    };

    window.digitalData.subscribe('algonomy.onClick', (data) => {
      const { strategyName } = data;
      const { testId } = data.mvtTreatments[0];
      const analyticsParam = `recommendations-${strategyName}-${testId}`;

      eventDetails.origin = analyticsParam;
    });
    const quickviewEvent = new CustomEvent('quickview:openModal', { detail: eventDetails });

    window.dispatchEvent(quickviewEvent);
  };

  // Find and get placement Id from the target element(s) and render recs for each placement
  return Array.from(targetElement).map((el) => {
    const placement = el.getAttribute('data-placement-id') || '';

    // if the placement is not found in target element, do not render the slider
    if (!placement) return null;

    return ReactDOM.createPortal(
      <RecommendationsWrapper clientId={clientId}>
        <RecommendationSliders
          placements={placements.filter((p) => placement.includes(p))}
          onProductClick={handleOnProductClick}
        />
      </RecommendationsWrapper>,
      el,
    );
  });
}

RecommendationAEMSlidersContainer.propTypes = RecommendationAEMSlidersContainerPropTypes;
RecommendationAEMSlidersContainer.defaultProps = RecommendationAEMSlidersContainerDefaultProps;
