import React, { useState, useRef } from 'react';
import EnterEmailPane from './EnterEmailPane';
import TemporaryPasswordPane from './TemporaryPasswordPane';
import CreatePasswordPane from './CreatePasswordPane';
import FinishPane from './FinishPane';
import PaneContextProvider from '../../context/PaneContext/PaneContextProvider';

function CreateAPasswordContainer() {
  const [email, setEmail] = useState('');
  const handleEmailInput = (emailId) => {
    setEmail(emailId);
  };

  const sliderRef = useRef(null);
  const carouselSettings = {
    adaptiveHeight: false,
    accessibility: false,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: false,
    touchMove: false,
  };

  return (
    <PaneContextProvider carouselSettings={carouselSettings} ref={sliderRef}>
      <EnterEmailPane
        index={0}
        onSuccessfulSubmission={handleEmailInput}
      />
      <TemporaryPasswordPane
        index={1}
        email={email}
      />
      <CreatePasswordPane index={2} />
      <FinishPane index={3} />
    </PaneContextProvider>
  );
}

export default CreateAPasswordContainer;
