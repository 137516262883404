import React from 'react';
import PropTypes from 'prop-types';

/*
  Note:
  - (1/23/23) as of anf-core-react v7.129.0 this is available from the DS.
  However, there is some things missing from that version when compared to ours.
  Luke is aware of this and will be taking a look.
  - SectionHeading does not exist yet in the DS
*/

export default function AccountBlock({
  children,
  heading,
  rightHeading,
  classList,
}) {
  return (
    <div className={`account-block ${classList}`}>
      <div className="heading">
        <div className="section-header-container">
          {heading}
          {rightHeading}
        </div>
        <div className="section-divider" />
      </div>
      <div className="content">{children}</div>
    </div>
  );
}

AccountBlock.defaultProps = {
  children: undefined,
  rightHeading: undefined,
  classList: '',
};

AccountBlock.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.node.isRequired,
  rightHeading: PropTypes.node,
  classList: PropTypes.string,
};
