import { softAuththenticationErrorVar } from '../context/ApplicationContext/ApplicationContext';

// eslint-disable-next-line import/prefer-default-export
export function handleGraphQLError(err) {
  const { extensions = {} } = err;

  switch (extensions.code) {
    case 'SOFT_AUTHENTICATION':
      softAuththenticationErrorVar(err);
      break;
    default:
      break;
  }
}
