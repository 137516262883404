import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Link as DSLink, DataCard, FlagBlock, IconBlock, Icon,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { addressProps } from '../Address/addressPropTypes';
import { textProp } from '../../tools/customProps';

const propTypes = {
  address: addressProps,
  cardTitle: textProp.isRequired,
  cardDefaultText: textProp.isRequired,
  changeText: textProp.isRequired,
  noDefaultAddress: textProp.isRequired,
  pageLink: PropTypes.string.isRequired,
  selectAddressText: textProp.isRequired,
};

const defaultProps = {
  address: null,
};

export default function DefaultAddress({
  address,
  cardTitle,
  cardDefaultText,
  changeText,
  noDefaultAddress,
  pageLink,
  selectAddressText,
}) {
  const renderHeader = () => (
    <DSLink href={pageLink}>
      <TmntText tmnt={changeText} />
    </DSLink>
  );

  const renderName = () => {
    switch (address.country) {
      case 'JP':
      case 'HK':
        return <p>{`${address.lastName} ${address.firstName}`}</p>;
      default:
        return <p>{`${address.firstName} ${address.lastName}`}</p>;
    }
  };

  const renderStreetAddress = () => (
    <>
      <p>{address.address1}</p>
      {(address.address2 && address.address2.length > 0) && (
        <p>{address.address2}</p>
      )}
    </>
  );

  const renderCityStateZip = () => {
    switch (address.country) {
      case 'HK':
      case 'SG':
        return (
          <p>
            {address.state && `${address.state}, `}
            {address.city && `${address.city}, `}
            {address.district && `${address.district} `}
            {address.postalCode}
          </p>
        );
      case 'JP':
      default:
        return (
          <p>
            {address.city && `${address.city}, `}
            {address.state && `${address.state} `}
            {address.postalCode}
          </p>
        );
    }
  };

  const renderNoDefaultAddress = () => (
    <p className="address-data-card__no-default">
      <TmntText tmnt={noDefaultAddress} />
    </p>
  );

  const renderFlagBlock = () => (
    <p>
      <FlagBlock countryCode={address.country} countryName={address.country} />
    </p>
  );

  const renderDefaultStatus = () => (
    <>
      <hr className="address-data-card__hr" />
      <div className="address-data-card__default-status">
        <IconBlock
          orientation="horizontal"
          icon={<Icon icon="check" size="m" />}
        >
          <span className="h3">
            <TmntText tmnt={cardDefaultText} />
          </span>
        </IconBlock>
      </div>
    </>
  );

  const renderSelectAddress = () => (
    <Button
      variant="tertiary-dark"
      isFullWidth
      href={pageLink}
    >
      <TmntText tmnt={selectAddressText} />
    </Button>
  );

  return (
    <div className="address-data-card" data-testid="default-address">
      <DataCard
        headerContent={(address && renderHeader())}
        headerTitle={cardTitle.value}
        variant="boxed"
      >
        <div className="address-data-card__info">
          {address ? (
            <>
              {renderName()}
              {renderStreetAddress()}
              {renderCityStateZip()}
              {renderFlagBlock()}
            </>
          ) : (
            renderNoDefaultAddress()
          )}
        </div>
        <div className="address-data-card__ctas">
          {address ? renderDefaultStatus() : renderSelectAddress()}
        </div>
      </DataCard>
    </div>
  );
}

DefaultAddress.propTypes = propTypes;
DefaultAddress.defaultProps = defaultProps;
