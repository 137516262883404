import React from 'react';
import hydrate from '../lib/hydrate';
import AuthenticationForm from '../lib/components/SignIn/AuthenticationForm';
import { AuthenticationFormContextProvider } from '../lib/context/AuthenticationFormContext/AuthenticationFormContext';

function AuthenticationFormFrontend() {
  return (
    <AuthenticationFormContextProvider>
      <AuthenticationForm />
    </AuthenticationFormContextProvider>
  );
}

hydrate({
  frontend: 'AuthenticationFormFrontend',
  component: AuthenticationFormFrontend,
});
