import React, { useState, useEffect } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import PropTypes from 'prop-types';
import { Icon } from 'anf-core-react';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { TmntText } from '../Common/Text';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import { textProp } from '../../tools/customProps';

const propTypes = {
  welcomeMessageText: textProp.isRequired,
  loyaltyTierImageSrc: PropTypes.string.isRequired,
  memberSince: textProp.isRequired,
  loyaltyTierImageAltText: PropTypes.string.isRequired,
  brand: PropTypes.string,
  isVip: PropTypes.bool,
};
const defaultProps = {
  brand: '',
  isVip: false,
};

export default function LoyaltyWelcomeMessage({
  welcomeMessageText,
  loyaltyTierImageSrc,
  loyaltyTierImageAltText,
  memberSince,
  isVip,
}) {
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => { setWindowWidth(retrievedWindowWidth); }, [retrievedWindowWidth]);

  const vipLogo = isVip ? 'xl' : null;
  const isMobileView = windowWidth <= 699;

  const mobileWelcomeMessage = () => (
    <div className="loyalty-welcome-message-container" data-testid="loyalty-header-mobile">
      <div className="header">
        <div className="loyalty-img">
          <Icon
            icon={loyaltyTierImageSrc}
            size={vipLogo}
            isImage
            labelText={loyaltyTierImageAltText}
          />
        </div>
        <div className="welcome-message">
          <TmntText tmnt={welcomeMessageText} />
        </div>
      </div>
      <div>
        <TmntText tmnt={memberSince} />
      </div>
    </div>
  );

  const desktopWelcomeMessage = () => (
    <AccountBlock
      heading={(
        <SectionHeading>
          <div>
            <h1 className="h2" data-testid="welcome-message-heading">
              <TmntText tmnt={welcomeMessageText} />
            </h1>
            <span>
              <TmntText tmnt={memberSince} />
            </span>
          </div>
        </SectionHeading>
      )}
      rightHeading={(
        <SectionHeading alignedRight>
          <Icon
            icon={loyaltyTierImageSrc}
            size={vipLogo}
            isImage
            labelText={loyaltyTierImageAltText}
          />
        </SectionHeading>
      )}
    />
  );

  return (
    isMobileView ? mobileWelcomeMessage() : desktopWelcomeMessage()
  );
}

LoyaltyWelcomeMessage.defaultProps = defaultProps;
LoyaltyWelcomeMessage.propTypes = propTypes;
