import { decodeHTML } from 'entities';

const entityPattern = /&([a-z0-9#]+);/i;

// eslint-disable-next-line import/prefer-default-export
export function decodeHtmlEntities(text) {
  if (!text) return '';

  const isHtmlEntity = text?.match(entityPattern);
  if (!isHtmlEntity) return text;

  return decodeHTML(text);
}
