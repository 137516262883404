import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

export default function BazaarVoiceReviewModal({ reviewSku, onClose }) {
  useEffect(() => {
    const submissionCloseHandler = () => onClose();

    const submissionSubmittedHandler = () => {
      let reviewedSkus = window.sessionStorage.getItem('reviewedSkus') || '';
      if (reviewedSkus.includes(reviewSku)) return;
      reviewedSkus = reviewedSkus.concat(`${reviewSku};`);
      window.sessionStorage.setItem('reviewedSkus', reviewedSkus);
    };

    const checkIfBvLoaded = () => {
      if (window.BV) {
        window.BV.configure('global', {
          events: {
            submissionClose: submissionCloseHandler,
            submissionSubmitted: submissionSubmittedHandler,
          },
        });
      } else {
        setTimeout(checkIfBvLoaded, 500);
      }
    };

    checkIfBvLoaded();

    return () => {
      if (window.BV) {
        window.BV.configure('global', {
          events: {
            submissionClose: null,
            submissionSubmitted: null,
          },
        });
      }
    };
  }, [reviewSku, onClose]);

  return (
    <div
      data-testid="write-a-review-bv-modal"
      data-bv-show="inpage_submission"
      data-bv-product-id={reviewSku}
      data-bv-campaign-id="accountToaster"
    />
  );
}

BazaarVoiceReviewModal.propTypes = {
  reviewSku: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
