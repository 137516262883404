/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import { textProp } from '../../tools/customProps';
import { inputPropsTypes, multiSelectPropTypes } from './AddressModal/AddressField';

export const addressFieldProp = PropTypes.oneOfType([
  PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    ...inputPropsTypes,
  }),
  PropTypes.shape({
    __typename: PropTypes.string.isRequired,
    ...multiSelectPropTypes,
  }),
]);

export const addressGroupProp = PropTypes.shape({
  label: textProp.isRequired,
  formGroupList: PropTypes.arrayOf(addressFieldProp),
});

export const addressProps = PropTypes.shape({
  address1: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  district: PropTypes.string,
  firstName: PropTypes.string,
  isAPOFPO: PropTypes.bool,
  isBillingAllowed: PropTypes.bool,
  isDefaultShipping: PropTypes.bool,
  isShippingAllowed: PropTypes.bool,
  lastName: PropTypes.string,
  name: PropTypes.string,
  phoneticName: PropTypes.string,
  poBox: PropTypes.bool,
  postalCode: PropTypes.string,
  prefecture: PropTypes.string,
  province: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.string,
  zone: PropTypes.string,
});
