import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  align: PropTypes.oneOf(['center', '']),
  children: PropTypes.node,
  hasTransition: PropTypes.bool,
  isHeader: PropTypes.bool,
  isHidden: PropTypes.bool,
  isLoading: PropTypes.bool,
  ariaDescribedBy: PropTypes.string,
};

const defaultProps = {
  align: undefined,
  children: undefined,
  hasTransition: false,
  isHeader: false,
  isHidden: false,
  isLoading: false,
  ariaDescribedBy: '',
};

export default function FormCell({
  align,
  children,
  hasTransition,
  isHeader,
  isHidden,
  isLoading,
  ariaDescribedBy,
}) {
  return (
    <div
      className="account-form-content"
      data-align={align}
      data-display={!isHidden}
      data-header={isHeader}
      data-loading={isLoading}
      data-transition={hasTransition}
      aria-describedby={ariaDescribedBy || null}
    >
      { children }
    </div>
  );
}

FormCell.propTypes = propTypes;
FormCell.defaultProps = defaultProps;
