import React, { useEffect, useState } from 'react';
import {
  Button, Modal,
} from 'anf-core-react';
import { useQuery } from '@apollo/client';
import { useWindowWidth } from '@react-hook/window-size';
import { useDatabridgeSubscribe } from '@xp-utilities/web';
import { useRewardsModalDisplay } from '../../hooks';
import DiscountCardContainer from '../Common/DiscountCard/DiscountCardContainer';
import { REWARDS_TEXT_QUERY } from './operations';
import { PAGE_KINDS } from '../../tools/constants';
import { TmntText } from '../Common/Text';

export default function SignInRewardsCommunicationModal() {
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false);
  const [openModal, setOpenModal] = useRewardsModalDisplay();

  const pageKind = useDatabridgeSubscribe('page.pageKind');

  // This modal will be displayed only on the homepage.
  useEffect(() => {
    setShouldRenderComponent(pageKind === PAGE_KINDS.homepage);
  }, [pageKind]);

  // Window Width
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(retrievedWindowWidth);
  }, [retrievedWindowWidth]);

  const { loading, error, data: queryData } = useQuery(REWARDS_TEXT_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  if (loading || error) return null;

  const {
    textFor = {}, config = {}, coupons = [], enableSignInRewardsModal,
  } = queryData;

  const {
    signInRewardsModalTitle,
    signInRewardsModalDescription,
    signInRewardsModalButton,
    redeemInBagText,
    exclusionsApplyLabelText,
  } = textFor;
  const { seeAllRewardsLinkUrl } = config || {};

  const handleClose = (redirect = false) => {
    setOpenModal(false);
    if (redirect) {
      setTimeout(() => {
        // Navigate to rewards page only when communication button is clicked
        window.location.href = seeAllRewardsLinkUrl;
      }, 200);
    }
  };

  const renderDiscountDetails = (text, href) => (
    <a
      target="_self"
      rel="noreferrer"
      href={href}
      style={{ display: 'block' }}
      className="open-modal-link"
    >
      {text}
    </a>
  );

  const isMobileView = windowWidth <= 699;

  const renderDiscountCard = ({
    title, description, badge, expiration, exclusionLink, id,
  }) => (
    <DiscountCardContainer
      key={`discountcard_${id}`}
      discountTitle={title.value}
      discountSecondaryTitle={description?.key ? description?.value : ''}
      graphicBadge={badge}
      discountDescription={expiration.value}
      id={id}
      discountMessage={redeemInBagText.value}
      discountDetails={renderDiscountDetails(exclusionsApplyLabelText?.value, exclusionLink)}
      orientation={isMobileView ? 'stacked' : 'horizontal'}
    />
  );

  const renderCoupons = (nCoupons) => nCoupons.map((coupon) => (
    renderDiscountCard(coupon)
  ));

  const isCouponsAvailable = coupons?.length;

  if (!shouldRenderComponent) return null;

  return (
    enableSignInRewardsModal && isCouponsAvailable ? (
      <Modal
        id="rewards-modal-coupons"
        data-testid="rewards-notification-modal"
        isOpen={openModal}
        onClose={() => handleClose(false)} // Close modal without redirecting
        variant="headerless"
      >
        <div className="scope-1892">
          <div className="rewards-communication-container">
            <div className="rewards-communication__header">
              <TmntText tmnt={signInRewardsModalTitle} classNames="h1" />
              <TmntText tmnt={signInRewardsModalDescription} />
            </div>
            <div className="rewards-communication__coupons">
              {renderCoupons(coupons)}
            </div>
            <Button className="rewards-communication__button" isFullWidth variant="tertiary-dark" onClick={() => handleClose(true)}>
              <TmntText tmnt={signInRewardsModalButton} />
            </Button>
          </div>
        </div>
      </Modal>
    ) : null
  );
}
