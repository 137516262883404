import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { Toaster } from 'anf-core-react';
import { useQuery } from '@apollo/client';
import { SignInToasterContext } from '../../context/ModalContext';
import { useEventListener } from '../../hooks';
import { SIGN_IN_TOASTER_QUERY } from './operations';

const propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

const defaultProps = {
  onClose: () => {}, // noop
};

let textCache = { closeButtonLabel: null };
const id = 'signin-toaster';

export default function SignInToaster({ children, onClose }) {
  const {
    isModalOpen: isToasterOpen, openModal: openToaster, closeModal: closeToaster,
  } = useContext(SignInToasterContext);

  const handleOpenToaster = () => openToaster();

  const handleCloseToaster = useCallback(() => {
    closeToaster();
    onClose();
  }, [closeToaster, onClose]);

  const { loading } = useQuery(SIGN_IN_TOASTER_QUERY, {
    onCompleted: ({ textFor }) => { textCache = { ...textFor }; },
  });

  useEventListener(`${id}:open`, handleOpenToaster);
  useEventListener(`${id}:close`, handleCloseToaster);

  if (loading) return null;

  const renderChildren = () => {
    if (!isToasterOpen) return null;
    return children;
  };

  return (
    <Toaster
      closeButtonLabel={textCache?.closeButtonLabel?.value}
      direction="from-right"
      heading={textCache?.toasterTitle?.value}
      id={id}
      isOpen={isToasterOpen}
      onClose={handleCloseToaster}
      variant="headerless"
    >
      <div className="scope-1892">
        { renderChildren() }
      </div>
    </Toaster>
  );
}

SignInToaster.propTypes = propTypes;
SignInToaster.defaultProps = defaultProps;
