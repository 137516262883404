import { useState, useEffect } from 'react';
import { getEventConfig, collectDatabridgeDatapoints } from '../tools/tealium';

/**
 * @name useTealiumTrigger
 * @description Exposes function specific to Tealium action events
 *
 * @param {String} interaction - user interaction, used as key for eventConfig
 * @param {String} featureType - feature that is triggering the Tealium event
 *
 * @return {Function} - Function to trigger event specific to the particular action
 */

export default function useTealiumTrigger(featureType, interaction) {
  const [digitalData, setDigitalData] = useState();

  const triggerEvent = () => {
    if (!digitalData) return;

    const eventConfig = getEventConfig(featureType);

    digitalData.trigger(eventConfig[interaction].eventName, eventConfig[interaction].eventData);
  };

  useEffect(() => {
    if (!window?.digitalData) return;

    setDigitalData(window.digitalData);
  }, []);

  return triggerEvent;
}

export const useCustomTealiumTrigger = (tealiumData) => {
  const [digitalData, setDigitalData] = useState();

  const triggerEvent = () => {
    if (!digitalData) return;

    const dataBridgeDataPoints = tealiumData.eventData.event_Id !== 'marketing_brand' ? collectDatabridgeDatapoints(digitalData) : '';
    const globalDatapoints = {
      ...dataBridgeDataPoints,
      ...tealiumData.eventData,
    };

    digitalData.trigger(tealiumData.eventName, globalDatapoints);
  };

  useEffect(() => {
    if (!window?.digitalData) return;

    setDigitalData(window.digitalData);
  }, []);

  return triggerEvent;
};
