import React from 'react';
import hydrate from '../lib/hydrate';
import AccountDisplay from '../lib/components/AccountDisplay/AccountDisplay';

function AccountDisplayFrontend() {
  return (
    <AccountDisplay />
  );
}

hydrate({
  frontend: 'AccountDisplayFrontend',
  component: AccountDisplayFrontend,
});
