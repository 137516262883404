import React, {
  createContext, useMemo, useCallback, forwardRef,
} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

export const PaneContext = createContext();

const propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  carouselSettings: PropTypes.object.isRequired,
};

const PaneContextProvider = forwardRef(({ children, carouselSettings }, sliderRef) => {
  // Using react-slick's API to navigate between pane
  const goToPane = useCallback((paneIndex) => {
    sliderRef.current.slickGoTo(paneIndex);
  }, [sliderRef]);

  const paneContextValue = useMemo(() => ({ goToPane }), [goToPane]);

  return (
    <PaneContext.Provider value={paneContextValue}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Slider ref={sliderRef} {...carouselSettings}>
        {children}
      </Slider>
    </PaneContext.Provider>
  );
});

PaneContextProvider.propTypes = propTypes;
export default PaneContextProvider;
