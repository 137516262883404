import React from 'react';
import { useQuery, gql } from '@apollo/client';
import { TmntText } from '../Common/Text';
import { textPairFragment } from '../../query/fragments';
import { LOADING_MESSAGE, ERROR_MESSAGE } from '../Messages/Messages';
import MyInfoBlock from '../MyInfo/MyInfoBlock';
import CheckoutInfoBlock from '../CheckoutInfoBlock/CheckoutInfoBlock';
import MarketingPreferencesFormBlock from '../MarketingPreferencesFormBlock/MarketingPreferencesFormBlock';

const ACCOUNT_DISPLAY_TEXT_QUERY = gql`
  query AccountDisplayTextQuery {
    textFor {
      pageTitle: pair(pairKey: "accountDisplayTitle") { ...textPair }
    }
  }

  ${textPairFragment}
`;

export default function AccountDisplay() {
  const { data, loading, error } = useQuery(ACCOUNT_DISPLAY_TEXT_QUERY);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  const { textFor } = data;

  return (
    <div className="account-display">
      <span className="screen-reader-text">
        <TmntText tmnt={textFor?.pageTitle} />
      </span>

      <MyInfoBlock />
      <CheckoutInfoBlock />
      <MarketingPreferencesFormBlock />
    </div>
  );
}
