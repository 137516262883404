import { gql } from '@apollo/client';
import { errorFragmentStr, textPairFragmentStr } from '../../query/fragmentStrings';
import { textPairFragment } from '../../query/fragments';

export const CUSTOMER_SELF_SERVICE_QUERY = gql`
  query CUSTOMER_SELF_SERVICE_QUERY {
   textFor {
    selfServiceNavLinks: listField(fieldKey: "selfServiceNavLinks") {...linkListValues}
    selfServiceTitle: pair(pairKey: "selfServiceTitle") {...textPair}
    }
  }

  fragment linkListValues on FieldOptionList {
    label { ...textPair }
    options {
      url: optionValue
      text {...textPair}
    }
  }

  ${textPairFragment}
`;

export const ORDERHELP_FORM_QUERY = gql`
  query ORDERHELP_FORM_QUERY {
      textFor {
        orderNumberErrorValidation: pair(pairKey: "orderNumberErrorValidation") {...textPair}
        emailAddressErrorValidation: pair(pairKey: "emailAddressErrorValidation") {...textPair}
        fromOrderNumberText: pair(pairKey: "fromOrderNumber") {...textPair}
        formError: pair(pairKey: "formError") {...textPair}
      }
      config {
        orderDetailDisplayUrl: string(name: "orderDetailDisplayUrl")
      }
      userType @client
  }

  ${textPairFragment}
`;

export const ORDERHELP_SELFSERVICE_PAGE_QUERY = gql`
  query ORDERHELP_SELFSERVICE_PAGE_QUERY {
      userState { isLoggedIn }
      textFor {
        trackMyOrder: pair(pairKey: "trackMyOrderTitle") {...textPair}
        startMyReturn: pair(pairKey: "startMyReturnTitle") {...textPair}
        trackMyReturn: pair(pairKey: "trackMyReturnTitle") {...textPair}
        lookUpMyOrder: pair(pairKey: "lookUpMyOrderTitle") {...textPair}
        checkGiftCardBalance: pair(pairKey: "checkGiftCardBalanceTitle") {...textPair}
        viewOrderDetailsText: pair(pairKey: "viewOrderDetailsText") {...textPair}
        orderNumberText: pair(pairKey: "orderNumberText") {...textPair}
        emailAddressText: pair(pairKey: "emailAddressText") {...textPair}
        returnOrderNumberText: pair(pairKey: "returnOrderNumberText") {...textPair}
        returnOrderNumberTooltipText: pair(pairKey: "returnOrderNumberTooltipText") {...textPair}
        viewOrderDetailsText: pair(pairKey: "viewOrderDetailsText") {...textPair}
        orderNumberTooltipText: pair(pairKey: "orderNumberTooltipText") {...textPair}
        trackMyOrderAdditionalInfoText: pair(pairKey: "trackMyOrderAdditionalInfoText") {...textPair}
        additionalInfoText: pair(pairKey: "additionalInfoText") {...textPair}
        returnsAdditionalInfoText:  pair(pairKey: "returnsAdditionalInfoText") {...textPair}
        giftCardAdditionalInfo: pair(pairKey: "giftCardAdditionalInfo") {...textPair}
        fromOrderHistoryText: pair(pairKey: "fromOrderHistoryText") {...textPair}
        signInToViewOrderHistoryText: pair(pairKey: "signInToViewOrderHistoryText") {...textPair}
        viewOrderHistory: pair(pairKey: "viewOrderHistory") {...textPair}
      }
      config {
        enableMFEAuthenticationModal: bool(name: "enableMFEAuthenticationModal")
        userOrderHistoryDisplayUrl: string(name: "userOrderHistoryDisplayUrl")
      }
  }

  ${textPairFragment}
`;

export const giftCardBalanceFormQuery = gql`
query GiftCardBalanceFormQuery {
  textFor {
    checkYourBalanceHeadingText: pair(pairKey: "checkYourCardBalanceText") {...textPair}
    checkBalanceCountryDropdownText: pair(pairKey: "checkBalanceCountryDropdownText") {...textPair}
    checkBalanceCountryDropDownErrorText: pair(pairKey: "checkBalanceCountryDropDownErrorText") {...textPair}
    giftCardAccountNumberText: pair(pairKey: "giftCardAccountNumberText") {...textPair}
    giftCardAccountNumberErrorText: pair(pairKey: "giftCardAccountNumberErrorText") {...textPair}
    checkBalanceFormTextPin: pair(pairKey: "checkBalanceFormTextPin") {...textPair}
    checkBalanceFormPinErrorText: pair(pairKey: "checkBalanceFormPinErrorText") {...textPair}
    giftCardBalanceFormInvalidText: pair(pairKey: "formError") {...textPair}
    giftCardReCaptchaErrorText: pair(pairKey: "giftCardReCaptchaErrorText") {...textPair}
    giftCardCardNumberTooltipLabel: pair(pairKey: "giftCardCardNumberTooltipLabel") {...textPair}
    giftCardCardNumberTooltipDescTitle: pair(pairKey: "giftCardCardNumberTooltipDescTitle") {...textPair}
    giftCardEGiftCardNumberHelpText: pair(pairKey: "giftCardEGiftCardNumberHelpText") {...textPair}
    giftCardCheckPhysicalCardNumberText: pair(pairKey: "giftCardCheckPhysicalCardNumberText") {...textPair}
    checkBalanceButtonText: pair(pairKey: "checkBalanceButtonText") {...textPair}
    processing: pair(pairKey: "processing") { ...textPair }
    success: pair(pairKey: "success") { ...textPair }
    pleaseTryAgain: pair(pairKey: "pleaseTryAgain") { ...textPair }
  }
  config {
    physicalGiftCardImageUrl: string(name: "physicalGiftCardImageUrl")
    digitalGiftCardImageUrl: string(name: "digitalGiftCardImageUrl")
  }
  giftCardCountryList {
    label { ...textPair }
    value
  }
}

${textPairFragmentStr}
`;

export const giftCardBalanceRequest = gql`
  mutation CheckGiftCardBalance($cardNumber: String!, $country: String!, $recaptchaResponse: String!) {
    checkGiftCardBalance(cardNumber: $cardNumber, country: $country, recaptchaResponse: $recaptchaResponse) {
      success
      balance
      currency
      id
      errors { ...error }
    }
  }

  ${errorFragmentStr}
`;

export const GIFTCARD_BALANCE_DISPLAY_QUERY = gql`
  query GiftCardBalanceDisplayQuery {
    textFor {
      cardBalance: pair(pairKey: "cardBalance") { ...textPair }
      checkBalanceOnAnotherCard: pair(pairKey: "checkBalanceOnAnotherCard") { ...textPair }
      checkYourBalanceHeadingText: pair(pairKey: "checkYourCardBalanceText") { ...textPair }
    }
  }

  ${textPairFragment}
`;
