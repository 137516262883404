import React from 'react';
import { propTypes, defaultProps } from './props';
import ControlledEmailInputField from './ControlledEmailInputField';

export default function EmailInputField(props) {
  const { control } = props;

  if (!control) {
    // eslint-disable-next-line no-console
    console.warn(
      `${EmailInputField.name} warning - no control passed please use "useEmailValidation"`,
    );

    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ControlledEmailInputField {...props} control={control} />;
}

EmailInputField.propTypes = propTypes;
EmailInputField.defaultProps = defaultProps;
