import { gql, useQuery } from '@apollo/client';
import { Link, TabPanel, Tabs } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { textPairFragment } from '../../../query/fragments';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';

const phoneNumberModalFormQuery = gql`
  query PhoneNumbersInfoQuery {
    textFor {
      countryTollFreeTabLabel: pair(pairKey: "countryTollFreeTabLabel") {...textPair}
      globalTollNumbersTabLabel: pair(pairKey: "globalTollNumbersTabLabel") { ...textPair }
      globalUnavailable: pair(pairKey: "globalUnavailable") { ...textPair }
      phoneNumbers: phoneNumberList(listKey: "phoneNumberList") { ...list }
      countryNumbers: countryNumberList(listKey: "countryNumberList") { ...list }   
    }
  }

  fragment list on ListItem {
    label
    number
  }

  ${textPairFragment}
`;

let textCache;

const propTypes = {
  orientation: PropTypes.string,
};
const defaultProps = {
  orientation: 'horizontal',
};

const phoneNumberTabs = { countryTollFree: 'country', globalTollFree: 'global' };
export default function PhoneNumberModal({ orientation }) {
  const { loading, error, data: textData } = useQuery(phoneNumberModalFormQuery);
  const [selectedTabId, setSelectedTabId] = useState(phoneNumberTabs.countryTollFree);
  const tabRefs = [
    useRef(null),
    useRef(null),
  ];

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  textCache = textData.textFor;

  const renderCenteredBlock = (numberlist) => (
    <div>
      <ul>
        {
          numberlist.map(({ label, number }) => (
            label === 'Other Countries' ? <li className="countries-phone-numbers-info" key={label}><span>{textCache?.globalUnavailable?.value}</span></li>
              : (
                <li className="phoneList_row" key={label}>
                  <span>{label}</span>
                  <Link classList="link-phoneNumber" href={`tel:${number}`}>{number}</Link>
                </li>
              )
          ))
        }
      </ul>
    </div>
  );

  const handleTabClick = (anEvent) => {
    setSelectedTabId(anEvent.currentTarget.getAttribute('id'));
  };

  const onKeyDownTab = (anEvent) => {
    const visibleTabId = anEvent.nativeEvent.target.id;
    const directionalArrowCodes = orientation === 'vertical'
      ? ['ArrowDown', 'ArrowUp']
      : ['ArrowLeft', 'ArrowRight'];

    if (directionalArrowCodes.includes(anEvent.code)) {
      anEvent.preventDefault();
      const keys = Object.values(phoneNumberTabs);
      const currentIndex = keys.indexOf(visibleTabId);
      const deltasByCode = {
        ArrowDown: 1,
        ArrowLeft: -1,
        ArrowRight: 1,
        ArrowUp: -1,
      };

      const indexOfTabToShow = (
        keys.length
        + currentIndex
        + deltasByCode[anEvent.code]
      ) % keys.length;

      setSelectedTabId(keys[indexOfTabToShow]);
      tabRefs[indexOfTabToShow].current.focus();
    }
  };

  return (
    <div className="phone-numbers-info">
      <Tabs
        controls={{
          tabs: [
            {
              ariaControls: 'country-tabpanel',
              id: phoneNumberTabs.countryTollFree,
              isSelected: selectedTabId === phoneNumberTabs.countryTollFree,
              label: textCache?.countryTollFreeTabLabel?.value,
              ref: tabRefs[0],
              onClick: handleTabClick,
            },
            {
              ariaControls: 'global-tabpanel',
              id: phoneNumberTabs.globalTollFree,
              isSelected: selectedTabId === phoneNumberTabs.globalTollFree,
              label: textCache?.globalTollNumbersTabLabel?.value,
              ref: tabRefs[1],
              onClick: handleTabClick,
            },
          ],
          onKeyDown: onKeyDownTab,
        }}
        labelText="phoneNumberModalTabs"
        orientation={orientation}
      >
        <TabPanel
          ariaLabelledBy={phoneNumberTabs.countryTollFree}
          isVisible={selectedTabId === phoneNumberTabs.countryTollFree}
          id={phoneNumberTabs.countryTollFree}
        >
          {selectedTabId === phoneNumberTabs.countryTollFree
            ? renderCenteredBlock(textCache?.countryNumbers) : <span />}
        </TabPanel>
        <TabPanel
          ariaLabelledBy={phoneNumberTabs.globalTollFree}
          id={phoneNumberTabs.globalTollFree}
          isVisible={selectedTabId === phoneNumberTabs.globalTollFree}
        >
          {selectedTabId === phoneNumberTabs.globalTollFree
            ? renderCenteredBlock(textCache?.phoneNumbers) : <span />}
        </TabPanel>
      </Tabs>
    </div>
  );
}

PhoneNumberModal.defaultProps = defaultProps;
PhoneNumberModal.propTypes = propTypes;
