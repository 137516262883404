import React from 'react';
import PropTypes from 'prop-types';

export default function Row({ children }) {
  return (
    <div className="a-row" data-testid="row">
      {children}
    </div>
  );
}

Row.defaultProps = {};

Row.propTypes = {
  children: PropTypes.node.isRequired,
};
