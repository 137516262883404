import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AuthenticationModalContext } from '../../context/ModalContext';
import { textProp } from '../../tools/customProps';

const propTypes = {
  fromOrderHistoryText: textProp.isRequired,
  signInToViewOrderHistoryText: textProp.isRequired,
  viewOrderHistory: textProp.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  enableMFEAuthenticationModal: PropTypes.bool.isRequired,
  userOrderHistoryDisplayUrl: PropTypes.string.isRequired,
};

export default function ViewOrderHistoryButton({
  fromOrderHistoryText,
  signInToViewOrderHistoryText,
  viewOrderHistory,
  isLoggedIn,
  enableMFEAuthenticationModal,
  userOrderHistoryDisplayUrl,
}) {
  const { openModal } = useContext(AuthenticationModalContext);
  const handleViewOrder = () => {
    window.open(userOrderHistoryDisplayUrl);
  };

  const handleSignIn = () => {
    const detail = { isSignIn: true, isHBNav: true };
    if (!enableMFEAuthenticationModal) document.dispatchEvent(new CustomEvent('authentication-modal:open', { detail }));
    else {
      openModal();
    }
  };
  return (
    <>
      <div className="view-order-history-header">
        <h2>{fromOrderHistoryText?.value}</h2>
      </div>
      {isLoggedIn ? (
        <Button isFullWidth variant="secondary" onClick={handleViewOrder}>
          {viewOrderHistory?.value}
        </Button>
      )
        : (
          <Button isFullWidth variant="secondary" onClick={handleSignIn}>
            {signInToViewOrderHistoryText?.value}
          </Button>
        )}
    </>
  );
}

ViewOrderHistoryButton.propTypes = propTypes;
