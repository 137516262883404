import React from 'react';
import PropTypes from 'prop-types';

// This will be further updated in the ticket https://anfcorp.atlassian.net/browse/PRO-561

const propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
  isLoading: false,
};

export default function Loading({ isLoading, children }) {
  return (
    <div className="loading-animation" data-loading={isLoading}>
      {children}
    </div>
  );
}

Loading.defaultProps = defaultProps;
Loading.propTypes = propTypes;
