import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'anf-core-react';
import { useMutation, useQuery } from '@apollo/client';
import { LOGOUT_MUTATION, SIGNOUT_BUTTON_QUERY } from './operations';
import ButtonState from '../Common/ButtonState/ButtonState';
import TmntText from '../Common/Text/TmntText';

const propTypes = { children: PropTypes.node };

const defaultProps = { children: null };

let textCache = {};

export default function SignOutButton({ children }) {
  const { data = {} } = useQuery(SIGNOUT_BUTTON_QUERY);

  const [logout] = useMutation(LOGOUT_MUTATION);

  const handleOnSignOut = async () => logout();

  const handleRedirect = () => {
    const { config = {} } = data;
    const { homeRedirectUrl } = config;
    window.location.assign(homeRedirectUrl);
  };

  textCache = { ...data?.textFor };

  return (
    <ButtonState
      asyncOnClick={handleOnSignOut}
      onSuccess={handleRedirect}
      initial={(
        <Button variant="tertiary-light" isFullWidth>
          { children }
        </Button>
      )}
      processing={(
        <Button
          isDisabled
          isProcessing
          variant="tertiary-light"
          isFullWidth
        >
          { children }
        </Button>
      )}
      success={(
        textCache?.success && (
          <Button
            variant="tertiary-light"
            isDisabled
            isFullWidth
          >
            <Icon icon="check" />
            <TmntText tmnt={textCache.success} />
          </Button>
        )
      )}
      error={(
        textCache?.pleaseTryAgain && (
          <Button
            variant="tertiary-light"
            isDisabled
            isFullWidth
          >
            <TmntText tmnt={textCache.pleaseTryAgain} />
          </Button>
        )
      )}
    />
  );
}

SignOutButton.propTypes = propTypes;
SignOutButton.defaultProps = defaultProps;
