import React from 'react';
import { ErrorMessage, Link as DSLink } from 'anf-core-react';
import LoyaltyConversion from './LoyaltyConversion';
import * as FormStructure from '../FormStructure';
import { TmntText, TmntHtml } from '../Common/Text';
import {
  formErrorMessageCellPropTypes,
  loyaltyConversionTogglePropTypes,
  loyaltyConversionToggleDefaultProps,
  loyaltyAboutLinkFormCellPropTypes,
  loyaltyAboutLinkFormCellDefaultProps,
} from './props';

export function FormErrorCell({ formState }) {
  const { errors } = formState;
  const isHidden = !(Object.keys(errors).filter((error) => error === 'form').length > 0);
  const { form } = errors;

  return (
    <FormStructure.FormCell isHidden={isHidden}>
      <ErrorMessage>
        <TmntHtml tmnt={form?.message} />
      </ErrorMessage>
    </FormStructure.FormCell>
  );
}

FormErrorCell.propTypes = formErrorMessageCellPropTypes;

export function LoyaltyConversionToggle({
  children,
  control,
  isLoading,
  isLoyaltyConversionShown,
  submitCall,
}) {
  if (!isLoyaltyConversionShown) return children;

  return (
    <FormStructure.FormCell isLoading={isLoading}>
      <LoyaltyConversion onSubmitCall={submitCall} control={control} />
    </FormStructure.FormCell>
  );
}

LoyaltyConversionToggle.propTypes = loyaltyConversionTogglePropTypes;
LoyaltyConversionToggle.defaultProps = loyaltyConversionToggleDefaultProps;

export function LoyaltyAboutLinkFormCell({
  isLoading,
  isLoyaltyEnabled,
  label,
  link,
}) {
  if (!isLoyaltyEnabled) return null;

  return (
    <FormStructure.FormCell align="center" isLoading={isLoading}>
      <DSLink href={link}>
        <TmntText tmnt={label} />
      </DSLink>
    </FormStructure.FormCell>
  );
}

LoyaltyAboutLinkFormCell.propTypes = loyaltyAboutLinkFormCellPropTypes;
LoyaltyAboutLinkFormCell.defaultProps = loyaltyAboutLinkFormCellDefaultProps;
