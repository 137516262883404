import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import {
  Button, ErrorMessage, Icon, InputField, LegalText, Link as DSLink,
} from 'anf-core-react';
import FormHeading from './FormHeading';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import isValidEmail from '../../tools/isValidEmail';
import ButtonState from '../Common/ButtonState/ButtonState';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import { useLocalStorage } from '../../hooks';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { enterEmailPaneQuery, enterEmailPaneRequest } from './operations';
import { PaneContext } from '../../context/PaneContext/PaneContextProvider';

const propTypes = { onSuccessfulSubmission: PropTypes.func };
const defaultProps = { onSuccessfulSubmission: undefined };

export default function EnterEmailPane({ onSuccessfulSubmission }) {
  const { goToPane } = useContext(PaneContext);
  const { loading, error: queryError, data: queryData } = useQuery(enterEmailPaneQuery);

  const [emailInput, setEmailInput] = useState('');
  const [, setIsTempLoginSuccess] = useLocalStorage('tempPwdLoggedIn', 'false');
  const [emailInputInvalidErrMsg, setEmailInputInvalidErrMsg] = useState('');

  const handleCreateAccountClick = () => {
    document.dispatchEvent(new CustomEvent('authentication-modal:open', {
      detail: { isSignIn: false },
    }));
  };

  const [validateEmailMutation] = useMutation(enterEmailPaneRequest, {
    onError: (error) => {
      const { graphQLErrors } = error;
      const { extensions } = graphQLErrors && graphQLErrors.length && graphQLErrors[0];
      const { body } = extensions.response;

      if (body?.error[0]?.errorKey === '_ERR_BAD_PARMS.2010') {
        setEmailInputInvalidErrMsg(
          <>
            <TmntHtml
              tmnt={queryData.textFor.enterEmailPaneInvalidEmail}
            />
            <DSLink classList="link-error-message" onClick={handleCreateAccountClick}>
              <TmntText
                tmnt={queryData.textFor.createAnAccount}
              />
            </DSLink>
          </>,
        );
      } else {
        setEmailInputInvalidErrMsg(body?.error[0]?.errorMessage);
      }
    },
  });

  const validateEmail = () => {
    const isValid = isValidEmail(emailInput);
    if (!isValid) setEmailInputInvalidErrMsg(queryData.textFor.invalidEmailError?.value);
    return isValid;
  };

  const handleOnSubmit = async () => {
    setEmailInputInvalidErrMsg('');
    const isSubmissionValid = validateEmail();
    if (!isSubmissionValid) return Promise.reject();
    return new Promise((resolve, reject) => {
      validateEmailMutation({
        variables: {
          email: emailInput,
        },
      }).then(({ data = {} }) => {
        const { sendTempPassword } = data;
        if (!sendTempPassword?.success) reject();
        else {
          setIsTempLoginSuccess(true);
          resolve();
        }
        // Triggers Temporary Password Pane
        // Sent emailInput as a prop to Temporary Password Pane component.
      })
        .catch(() => { reject(); });
    });
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;
  const {
    enterEmailPaneHeader,
    enterEmailPaneInstructions,
    sendTempPasswordBtnText,
    email,
    processing,
    success,
    pleaseTryAgain,
    legal,
  } = textFor;

  return (
    <FormWrapper>
      <FormGroup>
        <FormCell>
          <FormHeading
            formHeadingText={enterEmailPaneHeader}
            formDescriptionText={enterEmailPaneInstructions}
            formHeadingIcon="mail-large-anf"
          />
        </FormCell>
        <FormCell>
          <InputField
            autoComplete="email"
            id="forgotPasswordInStoreSignup_email"
            isInvalid={!!emailInputInvalidErrMsg}
            label={email?.value}
            onChange={(e) => setEmailInput(e.target.value)}
            isRequired
            type="email"
            value={emailInput}
          >
            {
              emailInputInvalidErrMsg && (
              <ErrorMessage id="enter-email-pane-error-msg" variant="error">
                <span>{emailInputInvalidErrMsg}</span>
              </ErrorMessage>
              )
            }
          </InputField>
        </FormCell>
        <FormCell align="center">
          <LegalText>
            <TmntHtml tmnt={legal.text} />
          </LegalText>
        </FormCell>
        <FormCell>
          <ButtonState
            asyncOnClick={handleOnSubmit}
            onSuccess={() => {
              onSuccessfulSubmission(emailInput);
              goToPane(1);
            }}
            initial={(
              <Button
                type="submit"
                variant="secondary"
                isFullWidth
                isDisabled={!emailInput}
              >
                <TmntText tmnt={sendTempPasswordBtnText} />
              </Button>
            )}
            processing={(
              <Button variant="secondary" isProcessing isDisabled isFullWidth>
                <TmntText tmnt={processing} />
              </Button>
            )}
            error={(
              <Button variant="secondary" isDisabled isFullWidth>
                <TmntText tmnt={pleaseTryAgain} />
              </Button>
            )}
            success={(
              <Button variant="secondary" isDisabled isFullWidth>
                <Icon icon="check" />
                <TmntText tmnt={success} />
              </Button>
            )}
          />
        </FormCell>
      </FormGroup>
    </FormWrapper>
  );
}

EnterEmailPane.propTypes = propTypes;
EnterEmailPane.defaultProps = defaultProps;
