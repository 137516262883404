import { useEffect } from 'react';

export default function useEventListener(event, callback) {
  useEffect(() => {
    document.addEventListener(event, callback);
    return () => {
      document.removeEventListener(event, callback);
    };
  }, [event, callback]);
}
