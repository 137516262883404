import React from 'react';
import hydrate from '../lib/hydrate';
import AddressModalCRS from '../lib/components/Address/AddressModal/AddressModalCRS';
import { AddressContextProvider } from '../lib/components/Address/AddressContextProvider';

function AddressModalFrontend() {
  return (
    <AddressContextProvider>
      <AddressModalCRS />
    </AddressContextProvider>
  );
}

hydrate({
  frontend: 'SavedAddressModalFrontend',
  component: AddressModalFrontend,
});
