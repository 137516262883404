import React from 'react';
import PropTypes from 'prop-types';

export default function PageContentWrapper({
  children, mainClass,
}) {
  return (
    <main className={`page-content-wrapper ${mainClass}`}>
      {children}
    </main>
  );
}

PageContentWrapper.defaultProps = {
  mainClass: '',
};

PageContentWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  mainClass: PropTypes.string,
};
