import React from 'react';
import PropTypes from 'prop-types';
import { textProp } from '../../../tools/customProps';

const propTypes = { tmnt: textProp, tag: PropTypes.oneOf(['p', 'span']) };
const defaultProps = { tmnt: undefined, tag: 'span' };

export default function TmntHtml({ tag, tmnt }) {
  if (!tmnt) return null;
  const Tag = tag;

  const { key, value } = tmnt;

  return <Tag data-text-key={key} dangerouslySetInnerHTML={{ __html: value }} />;
}

TmntHtml.propTypes = propTypes;
TmntHtml.defaultProps = defaultProps;
