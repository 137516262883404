import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  Icon,
  RadioButton,
  ErrorMessage,
  LegalText,
} from 'anf-core-react';
import RadioButtonGroup from 'anf-core-react/components/RadioButtonGroup';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import { textProp, optionProp } from '../../tools/customProps';
import ButtonState from '../Common/ButtonState/ButtonState';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';

const CancelOrderOptionsProps = PropTypes.shape({
  label: textProp.isRequired,
  options: PropTypes.arrayOf(optionProp).isRequired,
});

const propTypes = {
  cancelOptionsGroup: CancelOrderOptionsProps.isRequired,
  alertMessage: PropTypes.shape({
    variant: PropTypes.string,
    message: textProp,
  }),
  checkedValue: PropTypes.string,
  handleRadioChange: PropTypes.func,
  onCancelOrder: PropTypes.func,
  onSuccessfulCancel: PropTypes.func,
  onKeepOrder: PropTypes.func,
  text: PropTypes.shape({
    linkText: textProp,
    legalText: textProp,
    submit: textProp,
    pleaseTryAgain: textProp,
    processing: textProp,
    success: textProp,
  }),
};

const defaultProps = {
  alertMessage: null,
  checkedValue: null,
  handleRadioChange: undefined,
  onCancelOrder: undefined,
  onSuccessfulCancel: undefined,
  onKeepOrder: undefined,
  text: {
    linkText: null,
    legalText: null,
    submit: null,
    pleaseTryAgain: null,
    processing: null,
    success: null,
  },
};

export default function CancelOrderModalForm({
  alertMessage,
  cancelOptionsGroup,
  checkedValue,
  handleRadioChange,
  onCancelOrder,
  onSuccessfulCancel,
  onKeepOrder,
  text,
}) {
  /* Member variables */
  const {
    legalText,
    linkText,
    submit,
    pleaseTryAgain,
    processing,
    success,
  } = text;

  /* Render */
  const renderRadioButtonGroup = (group) => {
    const { label, options } = group;

    const radioButtons = options.map((item) => {
      const id = `cancelOrderModalRadioButtonOptions_${item.optionValue}`;
      return (
        <RadioButton
          key={id}
          id={id}
          label={item.text?.value}
          name="cancelOrderModalRadioButtonOptions[]"
          value={item.optionValue}
          onChange={handleRadioChange}
          isChecked={checkedValue === item.optionValue}
        />
      );
    });

    return (
      <RadioButtonGroup legend={label?.value}>
        { radioButtons }
      </RadioButtonGroup>
    );
  };

  return (
    <FormWrapper>
      <FormGroup>
        {
          cancelOptionsGroup && (
            <FormCell>
              { renderRadioButtonGroup(cancelOptionsGroup) }
            </FormCell>
          )
        }
        {
          alertMessage && alertMessage.message && (
            <FormCell>
              <ErrorMessage id="cancelOrderModalForm_errorMessage" variant={alertMessage.variant}>
                <TmntText tmnt={alertMessage.message} />
              </ErrorMessage>
            </FormCell>
          )
        }
        <FormCell>
          <LegalText>
            <TmntHtml tmnt={legalText} />
          </LegalText>
        </FormCell>
        <FormCell>
          <ButtonGroup variant="vertical-spaced">
            <ButtonState
              asyncOnClick={onCancelOrder}
              onSuccess={onSuccessfulCancel}
              initial={(
                <Button variant="secondary" isDisabled={!checkedValue ? true : undefined}>
                  <TmntText tmnt={submit} />
                </Button>
              )}
              processing={(
                <Button variant="secondary" isProcessing isDisabled>
                  <TmntText tmnt={processing} />
                </Button>
              )}
              error={(
                <Button variant="secondary" isDisabled>
                  <TmntText tmnt={pleaseTryAgain} />
                </Button>
              )}
              success={(
                <Button variant="secondary" isDisabled>
                  <Icon icon="check" />
                  <TmntText tmnt={success} />
                </Button>
              )}
            />
            <Button
              variant="borderless"
              type="submit"
              onClick={onKeepOrder}
              classList="button-with-link-hyperlink"
            >
              <TmntText tmnt={linkText} />
            </Button>
          </ButtonGroup>
        </FormCell>
      </FormGroup>
    </FormWrapper>
  );
}

CancelOrderModalForm.defaultProps = defaultProps;
CancelOrderModalForm.propTypes = propTypes;
