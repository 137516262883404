import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  ApolloClient, ApolloProvider, HttpLink, from,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';
import jsCookie from 'js-cookie';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { persistCache, SessionStorageWrapper } from 'apollo3-cache-persist';
import { cookies as cookieUtilities, signifyd as signifydUtilities } from '@xp-utilities/utilities';
import App from './App';
import {
  applicationContextVar,
  xSegVar,
  userTypeVar,
  countryVar,
  signifydIdVar,
  updatePlacementsVar,
} from './context/ApplicationContext';
import { buildBFFUri } from '../tools/uri';
import { getCacheId, getInMemoryCacheSettings } from '../tools/cache';
import persistenceMapper from '../tools/persist/persistenceMapper';
import persistenceRules from '../tools/persist/rules';
import { handleGraphQLError } from './tools/errorHandling';
import getFrontendId from '../tools/getFrontendId';

const updateXSegVar = (ctx) => {
  const { cookies = {} } = ctx;
  xSegVar(cookies.xSeg || null);
};

const updateUserTypeVar = (ctx) => {
  userTypeVar(cookieUtilities.getUserType(ctx));
};

const updateCountryVar = (ctx) => {
  const { query = {} } = ctx;
  countryVar(query.country);
};

const updateSignifydIdVar = () => {
  if (!document) return;

  const signifydSessionID = signifydUtilities.getSignifydSessionID(document);
  signifydIdVar(signifydSessionID);
};

const updateApplicationContextVar = (ctx) => {
  const { query } = ctx;

  if (!query) {
    applicationContextVar(null);
    return;
  }

  const includeList = ['brand', 'catalogId', 'country', 'langId', 'store', 'storeId'];

  const filteredQueryObj = Object.keys(query)
    .filter((key) => includeList.includes(key))
    .reduce((obj, key) => {
      const object = obj;
      object[key] = query[key];
      return object;
    }, {});

  applicationContextVar(JSON.stringify(filteredQueryObj));
};

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      const { message, locations, path } = err;

      handleGraphQLError(err);

      // eslint-disable-next-line no-console
      console.log(
        '[GraphQL error]: Message: ',
        message,
        'Location: ',
        locations,
        'Path',
        path,
      );
    });
  }
  // eslint-disable-next-line no-console
  if (networkError) console.log('[Network error]: ', networkError);
});

export default async function hydrate({
  frontend,
  component,
  clientId,
  persist = false,
}) {
  const Component = component;
  const cacheId = getCacheId(frontend, clientId);
  const frontendId = getFrontendId(frontend, clientId);
  const el = document.getElementById(frontendId);

  // To support a single file js type delivery, need to handle component endpoints
  // existing in js, but not in dom
  if (!el) return null;

  const config = window[`APOLLO_STATE__${cacheId}`] || {};
  const { query } = config;

  config.cookies = jsCookie.get();

  // Update the applicationContextVar and setup the cache
  updateApplicationContextVar(config);

  // Update the xSegVar
  updateXSegVar(config);

  updateUserTypeVar(config);
  updateCountryVar(config);
  updateSignifydIdVar();

  updatePlacementsVar(config, clientId);

  const inMemoryCacheSettings = getInMemoryCacheSettings();
  const cache = new InMemoryCache(inMemoryCacheSettings).restore(config.CACHE);

  if (persist) {
    try {
      await persistCache({
        cache,
        storage: new SessionStorageWrapper(window.sessionStorage),
        key: `${frontendId}-persist-cache`,
        persistenceMapper: (data) => persistenceMapper(data, persistenceRules),
      });
    // eslint-disable-next-line no-console
    } catch (err) { console.log('[Persist Cache Error]: ', err); }
  }

  // Goal uri example: 'http://localhost:51902?storeId=10051&catalogId=10901&langId=-1&brand=anf&store=a-us',
  const uri = buildBFFUri(query);
  const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true });

  const batchHttpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
    batchMax: 50,
    batchInterval: 10,
  });

  const httpLink = new HttpLink({ uri, credentials: 'same-origin' });

  const splitLink = (operation) => {
    const operationType = operation?.query?.definitions?.[0]?.operation;

    if (operationType === 'mutation') return true;

    const { batch = false } = operation.getContext();
    return batch;
  };

  /*
    Batched queries and mutations are made over POST calls and shouldn't be cached.
    persistExtractionLink should be called after the persistedQueriesLink
  */
  const client = new ApolloClient({
    link: from([
      errorLink,
    ]).split(
      splitLink,
      batchHttpLink,
      persistedQueriesLink.concat(httpLink),
    ),
    cache,
  });

  return hydrateRoot(
    el,
    <ApolloProvider client={client}>
      <App>
        <Component clientId={clientId} />
      </App>
    </ApolloProvider>,
  );
}
