import React from 'react';
import PropTypes from 'prop-types';

export default function Column({
  children,
  xs,
  sm,
  smd,
  md,
  xmd,
  lg,
  xl,
  xxl,
}) {
  const generateClassName = () => {
    const results = [];
    if (xs) results.push(`a-xs-${xs}`);
    if (sm) results.push(`a-sm-${sm}`);
    if (smd) results.push(`a-smd-${smd}`);
    if (md) results.push(`a-md-${md}`);
    if (xmd) results.push(`a-xmd-${xmd}`);
    if (lg) results.push(`a-lg-${lg}`);
    if (xl) results.push(`a-xl-${xl}`);
    if (xxl) results.push(`a-xxl-${xxl}`);
    return results.reduce((acc, classStr) => `${acc} ${classStr}`, '');
  };
  return (
    <div className={generateClassName()} data-testid="column">
      {children}
    </div>
  );
}

Column.defaultProps = {
  xs: '8',
  sm: undefined,
  smd: undefined,
  md: undefined,
  xmd: undefined,
  lg: undefined,
  xl: undefined,
  xxl: undefined,
};

export const ColumnNumberProp = PropTypes.oneOf(['1', '2', '3', '4', '5', '6', '7', '8']);
Column.propTypes = {
  children: PropTypes.node.isRequired,
  xs: ColumnNumberProp,
  sm: ColumnNumberProp,
  smd: ColumnNumberProp,
  md: ColumnNumberProp,
  xmd: ColumnNumberProp,
  lg: ColumnNumberProp,
  xl: ColumnNumberProp,
  xxl: ColumnNumberProp,
};
