import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useController } from 'react-hook-form';
import { LegalText, Link as DSLink } from 'anf-core-react';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import useButtonState from '../Common/ButtonState/useButtonState';
import TmntHtml from '../Common/Text/TmntHtml';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { LOYALTY_COVERSION_PROMPT_QUERY } from './operations';

// eslint-disable-next-line react/forbid-prop-types
const propTypes = { onSubmitCall: PropTypes.object, control: PropTypes.object };
const defaultProps = { onSubmitCall: undefined, control: undefined };

export const LOYALTY_CONVERSION_INPUT_NAME = 'loyalty-conversion-input';

export default function LoyaltyConversion({ control, onSubmitCall }) {
  const [renderButton, registerPromise] = useButtonState();

  const {
    loading, error:
    queryError, data:
    queryData = {},
  } = useQuery(LOYALTY_COVERSION_PROMPT_QUERY);

  const { field: hiddenInput } = useController({
    name: LOYALTY_CONVERSION_INPUT_NAME,
    control,
    defaultValue: false,
  });

  const onAccept = () => hiddenInput.onChange(true);

  const onDeny = () => {
    hiddenInput.onChange(false);
    document.dispatchEvent(new Event('authentication-modal:close'));
  };

  // Note: Examine a full context object as an alternative for this
  useEffect(() => {
    if (!onSubmitCall) return;

    registerPromise(onSubmitCall);
  }, [registerPromise, onSubmitCall]);

  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = queryData;

  const {
    loyaltyConversionLegalText,
    loyaltyConversionSubmitText,
    loyaltyConversionDenialText,
    processing,
    success,
    pleaseTryAgain,
  } = textFor;

  return (
    <FormWrapper>
      <FormGroup>
        <FormCell align="center" isLoading={loading}>
          <LegalText>
            <TmntHtml tmnt={loyaltyConversionLegalText} />
          </LegalText>
        </FormCell>
        <FormCell align="center" isLoading={loading}>
          {
            renderButton({
              initial: {
                children: (<TmntText tmnt={loyaltyConversionSubmitText} />),
                variant: 'secondary',
                isFullWidth: true,
                onClick: onAccept,
                type: 'submit',
              },
              processing: {
                children: (<TmntText tmnt={processing} />),
                variant: 'secondary',
                isFullWidth: true,
              },
              success: {
                children: (<TmntText tmnt={success} />),
                variant: 'secondary',
                isFullWidth: true,
              },
              error: {
                children: (<TmntText tmnt={pleaseTryAgain} />),
                variant: 'secondary',
                isFullWidth: true,
              },
            })
          }
        </FormCell>
        <FormCell align="center" isHidden isLoading={loading}>
          <input
            data-testid="loyalty-conversion-input"
            onChange={() => {}}
            name={LOYALTY_CONVERSION_INPUT_NAME}
            type="checkbox"
            checked={hiddenInput.value}
            hidden
          />
        </FormCell>
        <FormCell align="center" isLoading={loading}>
          <DSLink onClick={onDeny}>
            <TmntText tmnt={loyaltyConversionDenialText} />
          </DSLink>
        </FormCell>
      </FormGroup>
    </FormWrapper>
  );
}

LoyaltyConversion.propTypes = propTypes;
LoyaltyConversion.defaultProps = defaultProps;
