import React from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { useQuery } from '@apollo/client';
import { RECAPTCHA_KEY_CONFIG } from './operations';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../../Messages/Messages';

export default function ReCaptcha({ onChange }) {
  const { loading, error: queryError, data: queryData } = useQuery(RECAPTCHA_KEY_CONFIG);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { reCaptchaSiteKey } = queryData.config;

  return (
    <ReCAPTCHA
      sitekey={reCaptchaSiteKey}
      onChange={onChange}
      onExpired={() => onChange('')}
      data-testid="recaptcha"
    />
  );
}

ReCaptcha.propTypes = {
  onChange: PropTypes.func.isRequired,
};
