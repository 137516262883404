import PropTypes from 'prop-types';
import { textProp } from '../../../tools/customProps';

export const propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  control: PropTypes.object.isRequired,
};

export const keepMeSignedInAutoSelectedPropTypes = {
  ...propTypes,
  description: textProp,
  onChange: PropTypes.func,
  title: textProp,
};

export const keepMeSignedInCheckboxPropTypes = {
  ...propTypes,
  onChange: PropTypes.func,
  title: textProp,
  tooltipLabel: textProp,
  tooltipText: textProp,
};
