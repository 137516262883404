import React from 'react';
import { InputField } from 'anf-core-react';
import { useController } from 'react-hook-form';
import isValidEmail from '../../../tools/isValidEmail';
import { propTypes, defaultProps } from './props';

export default function ControlledEmailInputField({
  children,
  control,
  defaultValue,
  id,
  isDisabled,
  isRequired, // defaulted to true
  label,
  name,
  onFocus,
}) {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
    rules: { required: isRequired, validate: (value) => isValidEmail(value) },
  });

  return (
    <InputField
      autoComplete="email"
      id={id}
      isDisabled={isDisabled}
      isInvalid={fieldState.invalid}
      isRequired={isRequired}
      label={label}
      name={field.name}
      onChange={field.onChange}
      onBlur={field.onBlur}
      onFocus={onFocus}
      type="email"
      value={field.value}
    >
      { children }
    </InputField>
  );
}

ControlledEmailInputField.propTypes = propTypes;
ControlledEmailInputField.defaultProps = defaultProps;
