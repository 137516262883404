import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useWindowWidth } from '@react-hook/window-size';
import {
  Button, ButtonGroup, Icon,
} from 'anf-core-react';
import AccountBlock from '../Common/AccountBlock/AccountBlock';
import SectionHeading from '../Common/SectionHeading/SectionHeading';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { CUSTOMER_SELF_SERVICE_QUERY } from './operations';
import { textProp } from '../../tools/customProps';
import TmntHtml from '../Common/Text/TmntHtml';

function SelfServiceButton({ text, url, onClick }) {
  return (
    <Button
      variant="tertiary-light"
      classList="self-service-button"
      onClick={() => onClick(url)}
    >
      {text?.value}
      <Icon icon="right-small-anf" size="s" />
    </Button>
  );
}

SelfServiceButton.propTypes = {
  text: textProp.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function CustomerSelfServiceBlock() {
  const { loading, error, data } = useQuery(CUSTOMER_SELF_SERVICE_QUERY);
  const retrievedWindowWidth = useWindowWidth();
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    setWindowWidth(retrievedWindowWidth);
  }, [retrievedWindowWidth]);

  const isMobileView = windowWidth <= 699;
  const groupOrientation = isMobileView ? 'vertical-collapsed' : 'horizontal-spaced';

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor = {} } = data;
  const { selfServiceTitle, selfServiceNavLinks } = textFor;

  const handleButtonClick = (url) => {
    window.location.href = url;
  };

  return (
    <AccountBlock
      heading={(
        <SectionHeading>
          <TmntHtml tmnt={selfServiceTitle} />
        </SectionHeading>
        )}
      classList="self-service-account-block"
    >
      <ButtonGroup variant={groupOrientation}>
        {selfServiceNavLinks && selfServiceNavLinks.options.map(({ text, url }) => (
          <SelfServiceButton
            key={text?.key}
            text={text}
            url={url}
            onClick={handleButtonClick}
          />
        ))}
      </ButtonGroup>
    </AccountBlock>
  );
}
