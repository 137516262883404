import React from 'react';
import PropTypes from 'prop-types';
import { textProp } from '../../../tools/customProps';
import { decodeHtmlEntities } from '../../../tools/html/decode';

const propTypes = { tmnt: textProp, classNames: PropTypes.string };
const defaultProps = { tmnt: undefined, classNames: '' };

export default function TmntText({ tmnt, classNames }) {
  if (!tmnt) return null; // Note: safe guard even though tmnt is required

  const { key, value } = tmnt;

  return (
    <span className={classNames} data-text-key={key}>
      {/* Note: passes tmnt values through HTML entity decode */}
      { decodeHtmlEntities(value) }
    </span>
  );
}

TmntText.propTypes = propTypes;
TmntText.defaultProps = defaultProps;
