import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ADDRESS_TEXT_QUERY } from './addressOperations';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';

export const AddressContext = createContext();

const propTypes = { children: PropTypes.node.isRequired };

export function AddressContextProvider({ children }) {
  const { data, loading, error } = useQuery(ADDRESS_TEXT_QUERY);

  const contextData = useMemo(() => {
    if (loading || error) return null;
    return data;
  }, [loading, error, data]);

  if (loading) return LOADING_MESSAGE;
  if (error) return ERROR_MESSAGE;

  return (
    <AddressContext.Provider value={contextData}>
      {children}
    </AddressContext.Provider>
  );
}

AddressContextProvider.propTypes = propTypes;
