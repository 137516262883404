export const PAGE_KINDS = {
  cart: 'shopping cart',
  category: 'category',
  checkout: 'checkout',
  homepage: 'homepage',
  myList: 'my saves',
  pickedForYou: 'pickedForYou',
  product: 'product',
};

export const MOBILE_SIGN_IN_MENU_FRONTEND = 'MobileSignInMenuFrontend';
