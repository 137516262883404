import React from 'react';
import { useController } from 'react-hook-form';
import { propTypes, defaultProps } from './props';
import PasswordInputField from './PasswordInputField';
import isValidPassword from '../../../tools/isValidPassword';

export default function ControlledPasswordInputField(props) {
  const { control, name } = props;

  const { field, fieldState } = useController({
    control,
    name,
    rules: { required: true, validate: (value) => isValidPassword(value) },
  });

  const additionalProps = {
    ...props,
    isInvalid: fieldState.invalid,
    onChange: field.onChange,
    inputValue: field.value,
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PasswordInputField {...additionalProps} />
  );
}

ControlledPasswordInputField.propTypes = propTypes;
ControlledPasswordInputField.defaultProps = defaultProps;
