import React from 'react';
import { Icon, Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import TmntHtml from '../../Common/Text/TmntHtml';
import { textProp } from '../../../tools/customProps';
import { useTealiumTrigger } from '../../../hooks';

export default function MessagingBlock({
  isMobileView,
  customerMessageNumber,
  smsIcon,
  whatsAppIcon,
  textUsLabel,
}) {
  const whatsAppURL = `https://wa.me/${customerMessageNumber.replace(/[-\s()]+/g, '')}`;
  const dataAnalyticsTrackAction = useTealiumTrigger('universal', 'WHATSAPP');

  const handleOnClick = () => {
    dataAnalyticsTrackAction();
    window.location.href = whatsAppURL;
  };

  const smsWhatsAppBtn = isMobileView ? (
    <Button
      classList="customer-care-button whatsapp-button"
      data-testid="sms-whatsapp-label"
      theme="inverse"
      variant="tertiary-dark"
      onClick={handleOnClick}
    >
      <TmntHtml tmnt={textUsLabel} />
    </Button>
  ) : (
    <div className="whatsapp-label" data-testid="sms-whatsapp-label">
      <TmntHtml tmnt={textUsLabel} />
    </div>
  );

  return (
    <div className="cust-care-block" data-testid="smsWhatsApp-block">
      <div className="cust-care-icon">
        <Icon className="cust-care-icon" icon={textUsLabel?.value?.includes('WhatsApp') ? whatsAppIcon : smsIcon} size="m" />
      </div>
      {smsWhatsAppBtn}
    </div>
  );
}

MessagingBlock.defaultProps = {
  textUsLabel: undefined,
};

MessagingBlock.propTypes = {
  isMobileView: PropTypes.bool.isRequired,
  customerMessageNumber: PropTypes.string.isRequired,
  smsIcon: PropTypes.string.isRequired,
  whatsAppIcon: PropTypes.string.isRequired,
  textUsLabel: textProp,
};
