import { Link, Modal, Text } from 'anf-core-react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  heading: textProp.isRequired,
  linkText: textProp.isRequired,
  children: PropTypes.node,
};

const defaultProps = { children: null };

export default function PointsHistoryModal({
  heading,
  linkText,
  children,
}) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="points-history-modal">
      <Modal
        id="points-history-modal"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        heading={<Text><TmntText tmnt={heading} /></Text>}
      >
        <div className="points-history-modal-content">
          {children}
        </div>
      </Modal>
      {/*  eslint-disable-next-line */}
      <Link onClick={() => setOpenModal(true)}>
        <TmntText tmnt={linkText} />
      </Link>
    </div>
  );
}

PointsHistoryModal.propTypes = propTypes;
PointsHistoryModal.defaultProps = defaultProps;
