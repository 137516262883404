import React, { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useQuery, useReactiveVar } from '@apollo/client';
import { SESSION_CONTEXT_QUERY } from './operations';
import { applicationContextVar } from '../ApplicationContext/ApplicationContext';
import getFrontendId from '../../../tools/getFrontendId';

const SessionContext = createContext(null);

export default SessionContext;

const propTypes = {
  children: PropTypes.node.isRequired,
  frontend: PropTypes.string.isRequired,
  clientId: PropTypes.string,
};

const defaultProps = { clientId: null };

const buildSessionContextKey = (frontend, clientId) => `${getFrontendId(frontend, clientId)}-session-context`;
const buildSessionContextString = (sessionId, ctx) => `${sessionId}:${ctx}`;

export function SessionContextProvider({ children, frontend, clientId }) {
  const appContext = useReactiveVar(applicationContextVar);
  const client = useApolloClient();
  const sessionContextKey = buildSessionContextKey(frontend, clientId);

  const isStale = (id) => {
    const current = window.sessionStorage.getItem(sessionContextKey);
    if (!current) return false; // Note: no entry, so can't be stale
    const expected = buildSessionContextString(id, appContext);
    return current !== expected;
  };

  const resetSessionContext = () => {
    window.sessionStorage.removeItem(sessionContextKey);
    client.resetStore();
  };

  const onSessionQueryCompleted = ({ userState }) => {
    const { sessionId } = userState;

    // Reset the session storage when session id or appContext changes
    if (isStale(sessionId)) resetSessionContext();

    // Set the session context string
    window.sessionStorage.setItem(
      sessionContextKey,
      buildSessionContextString(sessionId, appContext),
    );
  };

  useQuery(SESSION_CONTEXT_QUERY, {
    ssr: false,
    context: { batch: true },
    fetchPolicy: 'cache-and-network',
    onCompleted: onSessionQueryCompleted,
  });

  // Garbage collect on any re-render
  useEffect(() => { client.cache.gc(); });

  return (
    <SessionContext.Provider value={null}>
      { children }
    </SessionContext.Provider>
  );
}

SessionContextProvider.propTypes = propTypes;
SessionContextProvider.defaultProps = defaultProps;
