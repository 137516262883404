import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

export const AUTH_FORMS = { SIGN_IN: 'sign-in', JOIN: 'join' };

const AuthenticationFormContext = createContext(null);
const AuthenticationFormDispatchContext = createContext(null);

const authenticationFormReducer = (state, action) => {
  switch (action.type) {
    case 'showForm':
      return { ...state, form: action.form };
    case 'existingUser':
      return { ...state, user: action.user };
    default:
      return state;
  }
};

const AuthenticationFormContextProviderPropTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.oneOf(Object.values(AUTH_FORMS)),
};

const AuthenticationFormContextProviderDefaultProps = { form: AUTH_FORMS.SIGN_IN };

export function AuthenticationFormContextProvider({ children, form }) {
  const [state, dispatch] = useReducer(authenticationFormReducer, { form });

  return (
    <AuthenticationFormContext.Provider value={state}>
      <AuthenticationFormDispatchContext.Provider value={dispatch}>
        { children }
      </AuthenticationFormDispatchContext.Provider>
    </AuthenticationFormContext.Provider>
  );
}

AuthenticationFormContextProvider.propTypes = AuthenticationFormContextProviderPropTypes;
AuthenticationFormContextProvider.defaultProps = AuthenticationFormContextProviderDefaultProps;

export const useAuthenticationForm = () => useContext(AuthenticationFormContext);
export const useAuthenticationFormDispatch = () => useContext(AuthenticationFormDispatchContext);
