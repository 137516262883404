// Note: From CRS global/base/js/modules/globalSignOn/remembered.js
export default function maskEmail(email = '') {
  let mask = '';

  if (email) {
    const splitEmail = email.split('@');
    const [emailUsername] = splitEmail;

    // eslint-disable-next-line no-plusplus
    for (let i = 1; i < emailUsername.length; i++) {
      mask += '*';
    }

    mask += '@';
    return emailUsername[0] + mask + splitEmail[1];
  }

  return mask;
}
