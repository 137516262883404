import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import { dsButtonProp, requiredDSButtonProp } from '../../../tools/customProps';

export const DEFAULT_BUTTON_STATE_TIMEOUT = 2000;

export const BUTTON_STATES = {
  initial: 'initial',
  processing: 'processing',
  success: 'success',
  error: 'error',
};

const propTypes = {
  asyncOnClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  initial: requiredDSButtonProp, // eslint-disable-line react/require-default-props
  processing: dsButtonProp,
  success: dsButtonProp,
  successTimeout: PropTypes.number,
  error: dsButtonProp,
  errorTimeout: PropTypes.number,
};

const defaultProps = {
  onSuccess: undefined,
  onError: undefined,
  initial: null,
  processing: null,
  success: null,
  successTimeout: DEFAULT_BUTTON_STATE_TIMEOUT,
  error: null,
  errorTimeout: DEFAULT_BUTTON_STATE_TIMEOUT,
};

export default function ButtonState({
  initial,
  asyncOnClick,
  onSuccess,
  onError,
  processing,
  success,
  successTimeout,
  error,
  errorTimeout,
}) {
  const [state, setState] = useState(BUTTON_STATES.initial);
  const buttonRef = useRef(null);
  const isMounted = useRef(true);

  const handleSetState = useCallback((nState) => {
    if (!isMounted.current) return;
    setState(nState);
  }, [isMounted]);

  const resetState = useCallback((timeout, callback) => setTimeout(() => {
    handleSetState(BUTTON_STATES.initial);
    if (callback) callback();
  }, timeout), [handleSetState]);

  const handleOnStateTransition = useCallback(() => {
    switch (state) {
      case BUTTON_STATES.success:
        resetState(successTimeout, onSuccess);
        break;
      case BUTTON_STATES.error:
        resetState(errorTimeout, onError);
        break;
      case BUTTON_STATES.processing:
      default:
        break;
    }
  }, [
    state,
    successTimeout,
    errorTimeout,
    resetState,
    onSuccess,
    onError,
  ]);

  const handleAsyncOnClick = async (event) => {
    handleSetState(BUTTON_STATES.processing);
    try {
      await asyncOnClick(event);
      handleSetState(BUTTON_STATES.success);
    } catch { handleSetState(BUTTON_STATES.error); }
  };

  useEffect(() => () => { isMounted.current = false; }, []);

  useEffect(() => { handleOnStateTransition(); }, [handleOnStateTransition]);

  const getState = () => {
    switch (state) {
      case BUTTON_STATES.processing:
        if (processing) return processing;
        break;
      case BUTTON_STATES.error:
        if (error) return error;
        break;
      case BUTTON_STATES.success:
        if (success) return success;
        break;
      default:
        break;
    }
    return initial;
  };

  const stateComponent = getState();
  const stateComponentProps = stateComponent?.props;

  const onClick = (event) => {
    if (stateComponent.props.onClick) stateComponent.props.onClick(event);
    handleAsyncOnClick(event);
  };

  return ((
    <>
      <Button
        {...stateComponentProps /* eslint-disable-line react/jsx-props-no-spreading */}
        onClick={(e) => buttonRef.current.click(e)}
        isDisabled={stateComponentProps.isDisabled ?? state !== BUTTON_STATES.initial}
      />
      {/*
        Note: This hidden button holds the responsibility of handling the click event.
        If left to the DS Button which re-renders, the event is lost and doesn't fully bubble.
        Instead the button is used to click and pass events, whereas the DS Button is simply the
        visual
      */}
      <button
        ref={buttonRef}
        aria-hidden
        style={{ display: 'none' }}
        onClick={onClick}
      />
    </>
  ));
}

ButtonState.propTypes = propTypes;
ButtonState.defaultProps = defaultProps;
