import { useQuery } from '@apollo/client';
import React from 'react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { VIP_LOYALTY_QUERY } from './operations';
import LoyaltyCard from '../Common/LoyaltyCard/LoyaltyCard';
import TmntText from '../Common/Text/TmntText';

export default function VIPLoyaltyCard() {
  const { data = {}, loading: queryLoading, error } = useQuery(VIP_LOYALTY_QUERY, {
    context: { batch: true },
    ssr: false,
  });

  if (queryLoading) return null;
  if (error) return ERROR_MESSAGE;

  const { textFor = {}, config = {} } = data;
  const {
    vipTitle,
    vipDescription,
    minValueLabelProgressBar,
    maxValueLabelProgressBar,
    vipOffersText,
    vipFreeShippingText,
    vipLoyaltyPointsText,
  } = textFor;
  const { currentProgressBarValue, brand } = config;
  return (
    <LoyaltyCard
      title={vipTitle?.value}
      description={<TmntText tmnt={vipDescription} />}
      progressBarCurrentValue={currentProgressBarValue}
      progressBarMaximumValue={Number(maxValueLabelProgressBar?.value?.replace(/[^0-9]/g, ''))}
      minValueLabelProgressBar={minValueLabelProgressBar?.value}
      maxValueLabelProgressBar={maxValueLabelProgressBar?.value}
      progressBarClassName="vipProgressBar"
    >
      <div className="vip-loyalty-footer-container">
        {brand === 'hco' && <TmntText tmnt={vipLoyaltyPointsText} />}
        <TmntText tmnt={vipOffersText} />
        <TmntText tmnt={vipFreeShippingText} />
      </div>
    </LoyaltyCard>
  );
}
