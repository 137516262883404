import { makeVar } from '@apollo/client';

export const applicationContextVar = makeVar(null);
export const softAuththenticationErrorVar = makeVar(null);

export const xSegVar = makeVar(null);
export const userTypeVar = makeVar(null);
export const countryVar = makeVar(null);
export const signifydIdVar = makeVar(null);

export const placementsVar = makeVar(new Map());
