import { Text } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';
import PointsHistoryModal from './PointsHistoryModal';

const propTypes = {
  findOrderText: textProp.isRequired,
  imageURL: PropTypes.string.isRequired,
  linkText: textProp.isRequired,
  purchaseText: textProp.isRequired,
  altText: PropTypes.string.isRequired,
};

export default function FindOrderDetails({
  findOrderText,
  imageURL,
  linkText,
  purchaseText,
  altText,
}) {
  return (
    <div className="points-history-find-order">
      <Text headingLevel={3}>
        <TmntText tmnt={purchaseText} />
      </Text>
      <PointsHistoryModal heading={findOrderText} linkText={linkText}>
        <img src={imageURL} alt={altText} width="100%" height="auto" />
      </PointsHistoryModal>
    </div>
  );
}

FindOrderDetails.propTypes = propTypes;
