import React from 'react';
import PropTypes from 'prop-types';
import * as FormStructure from '../../FormStructure';

const fieldProp = PropTypes.node;

const propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
  error: PropTypes.node,
  fields: PropTypes.arrayOf(fieldProp),
  isInvalid: PropTypes.bool,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  label: undefined,
  children: undefined,
  error: undefined,
  fields: [],
  isInvalid: false,
  isLoading: false,
};

export default function FormGroup({
  children,
  error,
  fields,
  isInvalid,
  isLoading,
  label,
}) {
  return (
    <FormStructure.FormGroup>
      { label }
      {
        fields?.map((field, index) => {
          const i = index;

          if (!field) return null;

          // Note: Allow passing of props within the components fields
          const { align, isHidden } = field.props;

          return (
            <FormStructure.FormCell
              align={align}
              isHidden={isHidden}
              isLoading={isLoading}
              key={`fieldGroupKey${i}`}
            >
              { field }
            </FormStructure.FormCell>
          );
        })
      }
      {
        error && (
          <FormStructure.FormCell isHidden={!isInvalid} isLoading={isLoading}>
            { error }
          </FormStructure.FormCell>
        )
      }
      { children }
    </FormStructure.FormGroup>
  );
}

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;
