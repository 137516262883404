import { DataCard, ProgressBar } from 'anf-core-react';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  description: PropTypes.node,
  progressBarCurrentValue: PropTypes.number.isRequired,
  progressBarMaximumValue: PropTypes.number.isRequired,
  minValueLabelProgressBar: PropTypes.string,
  maxValueLabelProgressBar: PropTypes.string,
  progressBarClassName: PropTypes.string,
};

const defaultProps = {
  children: undefined,
  description: undefined,
  minValueLabelProgressBar: '',
  maxValueLabelProgressBar: '',
  progressBarClassName: '',
};

export default function LoyaltyCard({
  title,
  description,
  children,
  progressBarCurrentValue,
  progressBarMaximumValue,
  minValueLabelProgressBar,
  maxValueLabelProgressBar,
  progressBarClassName,
}) {
  return (
    <div
      className="loyalty-card-container"
    >
      <DataCard
        headerTitle={title}
        theme="primary-light"
        variant="boxed"
      >
        <div className="loyalty-card">
          <div className="loyalty-sub-title">{description}</div>
          <div className="loyalty-body">
            <ProgressBar
              currentValue={progressBarCurrentValue}
              maximumValue={progressBarMaximumValue}
              classList={progressBarClassName}
            />
            <div className="loyalty-progress-bar-label">
              <span>{minValueLabelProgressBar}</span>
              <span>{maxValueLabelProgressBar}</span>
            </div>
          </div>
          <div className="loyalty-footer">{children}</div>
        </div>
      </DataCard>
    </div>
  );
}

LoyaltyCard.defaultProps = defaultProps;

LoyaltyCard.propTypes = propTypes;
