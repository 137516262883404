import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, DataCard, PaymentIcon, Link as DSLink,
  IconBlock,
  Button,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import { textProp } from '../../tools/customProps';
import { getCardBrandIcon, maskCardNumber } from '../../tools/CreditCardHelper/CreditCardHelper';
import { cardNumberObscureChar } from '../Payments/paymentConstants';

const propTypes = {
  payment: PropTypes.shape({
    cardExpireMonth: PropTypes.string.isRequired,
    cardBrand: PropTypes.string.isRequired,
    cardExpireYear: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    displayInfo: PropTypes.string,
    id: PropTypes.string.isRequired,
    requiresCVV: PropTypes.bool,
  }),
  cardTitle: textProp.isRequired,
  cardDefaultText: textProp.isRequired,
  changeText: textProp.isRequired,
  noDefaultPayment: textProp.isRequired,
  pageLink: PropTypes.string.isRequired,
  selectPaymentText: textProp.isRequired,
};

const defaultProps = {
  payment: null,
};

export default function PaymentCard({
  payment,
  cardTitle,
  cardDefaultText,
  changeText,
  noDefaultPayment,
  pageLink,
  selectPaymentText,
}) {
  const paymentIcon = payment && getCardBrandIcon(payment.cardBrand);

  const renderHeader = () => (
    <DSLink href={pageLink}>
      <TmntText tmnt={changeText} />
    </DSLink>
  );

  const renderCardInfo = () => (
    <>
      <div className="payment-data-card__icon">
        <PaymentIcon
          iconName={paymentIcon}
          labelText={paymentIcon}
        />
      </div>
      <div className="payment-data-card__desc">
        <p>{maskCardNumber(payment.cardBrand, payment.cardNumber, cardNumberObscureChar)}</p>
        <p>{`${payment.cardExpireMonth}/${payment.cardExpireYear}`}</p>
      </div>
    </>
  );

  const renderNoDefaultPayment = () => (
    <p className="payment-data-card__no-default">
      <TmntText tmnt={noDefaultPayment} />
    </p>
  );

  const renderDefaultStatus = () => (
    <>
      <hr className="payment-data-card__hr" />
      <div className="payment-data-card__default-status">
        <IconBlock
          orientation="horizontal"
          icon={<Icon icon="check" size="m" />}
        >
          <span className="h3">
            <TmntText tmnt={cardDefaultText} />
          </span>
        </IconBlock>
      </div>
    </>
  );

  const renderSelectPayment = () => (
    <Button
      variant="tertiary-dark"
      isFullWidth
      href={pageLink}
    >
      <TmntText tmnt={selectPaymentText} />
    </Button>
  );

  return (
    <div className="payment-data-card" data-testid="default-payment">
      <DataCard
        variant="boxed"
        headerTitle={cardTitle.value}
        headerContent={renderHeader()}
      >
        <>
          <div className="payment-data-card__info">
            {payment ? renderCardInfo() : renderNoDefaultPayment()}
          </div>
          <div className="payment-data-card__ctas">
            {payment ? renderDefaultStatus() : renderSelectPayment()}
          </div>
        </>
      </DataCard>
    </div>
  );
}

PaymentCard.propTypes = propTypes;
PaymentCard.defaultProps = defaultProps;
