import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';

export const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const labelValuePairProp = PropTypes.shape({
  label: textProp.isRequired,
  value: PropTypes.string,
});

export const optionProp = PropTypes.shape({
  optionValue: PropTypes.string.isRequired,
  text: textProp.isRequired,
  description: textProp,
});

export const buttonStateProp = PropTypes.shape({
  initial: PropTypes.node.isRequired,
  processing: PropTypes.node.isRequired,
  error: PropTypes.node.isRequired,
  success: PropTypes.node.isRequired,
});

export const multiSelectProp = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: textProp.isRequired,
  description: textProp,
  error: textProp,
  optionList: PropTypes.arrayOf(optionProp),
});

export const inputProp = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: textProp.isRequired,
  description: textProp,
  error: textProp,
});

export const formGroupProp = PropTypes.shape({
  label: textProp.isRequired,
  fieldList: PropTypes.arrayOf(PropTypes.oneOfType([multiSelectProp, inputProp])).isRequired,
  error: textProp,
});

export const refProp = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.shape({
    current: PropTypes.instanceOf(
      /*
        Note: SSR workaround https://stackoverflow.com/questions/48007326/what-is-the-correct-proptype-for-a-ref-in-react
      */
      typeof Element === 'undefined' ? {} : Element,
    ),
  }),
]);

export function dsButtonProp(props, propName) {
  const prop = props[propName];
  if (!prop) return;
  if (prop?.type !== Button) {
    throw new Error(`ButtonState prop ${propName} is not of type "Button"`);
  }
}

export function requiredDSButtonProp(props, propName) {
  const prop = props[propName];
  if (!prop) { throw new Error(`ButtonState prop ${propName} is required`); }
  dsButtonProp(props, propName);
}

export function ofTypes(types, prop) {
  const findType = (type) => {
    if (prop.type) return type === prop.type;
    return type === prop;
  };

  if (!types?.find(findType)) {
    throw Error(
      `${prop?.name} is not the proper types. Type of ${prop?.type} was passed`,
    );
  }
}
