import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  SingleEntryForm,
  InputField,
  Button,
  ErrorMessage,
  LegalText,
} from 'anf-core-react';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import isValidEmail from '../../tools/isValidEmail';
import TmntText from '../Common/Text/TmntText';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import { textPairFragment } from '../../query/fragments';
import TmntHtml from '../Common/Text/TmntHtml';
import FooterEmailSubscribeModal from './FooterEmailSubscribeModal';

const footerEmailSubscribeFormQuery = gql`
  {
    textFor {
      formHeader: pair(pairKey: "footerEmailSubscribeFormHeader") {...textPair}
      formError: pair(pairKey: "footerEmailSubscribeFormError") {...textPair}
      joinButtonText: pair(pairKey: "footerEmailSubscribeFormJoinButtonText") {...textPair}
      formInputLabelText: pair(pairKey: "footerEmailSubscribeFormInputText") {...textPair}
      legal: legal(fieldKey: "footerEmailSubscribe") {
        text {...textPair}
        check
      }
    }
  }

  ${textPairFragment}
`;

export default function FooterEmailSubscribeForm() {
  const { loading, error: queryError, data: queryData } = useQuery(footerEmailSubscribeFormQuery);
  const [emailInput, setEmailInput] = useState('');
  const [emailInputInvalidErrMsg, setEmailInputInvalidErrMsg] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const inputId = 'email-subscribe-email-field';

  const validateEmail = () => {
    const isValid = isValidEmail(emailInput);
    if (!isValid) setEmailInputInvalidErrMsg(queryData.textFor.formError?.value);
    return isValid;
  };

  const dispatchSubscribeModalEvent = () => {
    setIsModalOpen(true);
  };

  const handleOnSubmit = () => {
    setEmailInputInvalidErrMsg('');
    const isSubmissionValid = validateEmail();
    if (isSubmissionValid) dispatchSubscribeModalEvent();
  };

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const {
    formHeader,
    joinButtonText,
    formInputLabelText,
    legal,
  } = queryData.textFor;

  return (
    <>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <span className="h3">{formHeader?.value}</span>
          </FormCell>
          <FormCell>
            <SingleEntryForm>
              <InputField
                autoComplete="email"
                id={inputId}
                label={formInputLabelText?.value}
                isInvalid={!!emailInputInvalidErrMsg}
                onChange={(e) => setEmailInput(e.target.value)}
                isRequired
                value={emailInput}
              >
                <Button
                  variant="secondary"
                  onClick={handleOnSubmit}
                >
                  <TmntText tmnt={joinButtonText} />

                </Button>
              </InputField>
            </SingleEntryForm>
            {
              emailInputInvalidErrMsg && (
                <ErrorMessage id="footerEmailSubscribeForm_errorMessage" variant="error">
                  {emailInputInvalidErrMsg}
                </ErrorMessage>
              )
            }
          </FormCell>
          <FormCell>
            {
              legal.text.key && (
                <LegalText>
                  <TmntHtml tmnt={legal.text} />
                </LegalText>
              )
            }
          </FormCell>
        </FormGroup>
      </FormWrapper>
      <FooterEmailSubscribeModal
        email={emailInput}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}
