import { useState } from 'react';

/*
  Note: This implementation comes from the DS <7.123.0. The choice to remove this
  stems from the DS trying to stay unopioninated on state control
*/

const useCheckboxGroup = (defaultList = []) => {
  const [checkboxList, setCheckboxList] = useState(defaultList);

  const isCheckboxChecked = (value) => checkboxList.includes(value);

  const updateCheckboxList = ({ checked, value }) => {
    let newList = [...checkboxList];

    if (checked) {
      newList.push(value);
    } else {
      newList = newList.filter((val) => val !== value);
    }

    setCheckboxList(newList);
    return newList;
  };

  return [checkboxList, setCheckboxList, updateCheckboxList, isCheckboxChecked];
};

export default useCheckboxGroup;
