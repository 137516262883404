import React from 'react';
import PropTypes from 'prop-types';
import hydrate from '../lib/hydrate';
import { INSTANCE_DATA_ATTRIBUTE } from '../lib/components/Recommendations/RecommendationsWrapper';
import RecommendationSlidersContainer from '../lib/components/Recommendations/RecommendationSlidersContainer';
import { placementsVar } from '../lib/context/ApplicationContext/ApplicationContext';

const frontend = 'RecommendationSlidersFrontend';
const instances = document.querySelectorAll(`[${INSTANCE_DATA_ATTRIBUTE}]`);

const propTypes = { clientId: PropTypes.string.isRequired };

function RecommendationSlidersFrontend({ clientId }) {
  return (
    <RecommendationSlidersContainer
      clientId={clientId}
      placements={placementsVar().get(clientId)}
    />
  );
}

RecommendationSlidersFrontend.propTypes = propTypes;

instances?.forEach(({ id }) => {
  hydrate({
    frontend,
    component: RecommendationSlidersFrontend,
    clientId: id,
  });
});
