import React, {
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Button } from 'anf-core-react';
import { ERROR_MESSAGE } from '../Messages/Messages';
import TmntText from '../Common/Text/TmntText';
import { useUpperFunnelToaster, useTealiumTrigger } from '../../hooks';
import UpperFunnelPrompt from './UpperFunnelPrompt';
import { UPPER_FUNNEL_TOASTER_QUERY } from './operations';
import { AuthenticationModalContext } from '../../context/ModalContext';

const DEFAULT_TIMER_VALUE = 7200000;

const propTypes = { children: PropTypes.node.isRequired };

export default function UpperFunnelToaster({ children }) {
  const { openModal } = useContext(AuthenticationModalContext);
  const promptRef = useRef(null);

  const { loading, error, data } = useQuery(UPPER_FUNNEL_TOASTER_QUERY);
  const { ufLoginPromptTimeout } = data?.config || {};
  const promptTimer = ufLoginPromptTimeout || DEFAULT_TIMER_VALUE;
  const [isLoaded, setIsLoaded] = useUpperFunnelToaster(promptTimer);

  const handleCloseToaster = useCallback(() => { setIsLoaded(false); }, [setIsLoaded]);
  const dataAnalyticsTrackAction = useTealiumTrigger('universal', 'UPPER_FUNNEL_SIGN_IN');

  const handleOpenLogin = () => {
    openModal();
    handleCloseToaster();
    dataAnalyticsTrackAction();
  };

  useEffect(() => {
    document.addEventListener('upper-funnel:close', handleCloseToaster);
    return () => { document.removeEventListener('upper-funnel:close', handleCloseToaster); };
  }, [handleCloseToaster]);

  if (loading) return null;
  if (error) return ERROR_MESSAGE;

  const { closeButtonLabel, signInOrJoinText } = data.textFor;

  return (
    isLoaded && (
    <UpperFunnelPrompt
      closeButtonLabel={closeButtonLabel?.value}
      id="upperfunnel-toaster"
      onClose={handleCloseToaster}
      promptRef={promptRef}
    >
      {children}
      <Button
        className="upperfunnel-login"
        isFullWidth
        onClick={handleOpenLogin}
        variant="secondary"
      >
        <TmntText tmnt={signInOrJoinText} />
      </Button>
    </UpperFunnelPrompt>
    )
  );
}

UpperFunnelToaster.propTypes = propTypes;
