import React from 'react';
import PropTypes from 'prop-types';
import hydrate from '../lib/hydrate';
import { INSTANCE_DATA_ATTRIBUTE } from '../lib/components/Recommendations/RecommendationsWrapper';
import RecommendationsContainer from '../lib/components/Recommendations/RecommendationsContainer';
import { placementsVar } from '../lib/context/ApplicationContext/ApplicationContext';

const frontend = 'RecommendationsFrontend';
const instances = document.querySelectorAll(`[${INSTANCE_DATA_ATTRIBUTE}]`);

const propTypes = { clientId: PropTypes.string.isRequired };

function RecommendationsFrontend({ clientId }) {
  return (
    <RecommendationsContainer
      clientId={clientId}
      placements={placementsVar().get(clientId)}
    />
  );
}

RecommendationsFrontend.propTypes = propTypes;

instances?.forEach(({ id }) => {
  hydrate({
    frontend,
    component: RecommendationsFrontend,
    clientId: id,
  });
});
