import { gql } from '@apollo/client';
import {
  fieldOptionListValueFragment,
  textPairFragment,
} from '../../../query/fragments';

export const BIRTHDAY_FORM_QUERY = gql`
  query BIRTHDAY_FORM_QUERY {
    textFor {
      birthday: pair(pairKey: "birthday") {...textPair}
      optional: pair(pairKey: "optional") {...textPair}
      birthYearText: textField(fieldKey: "birthYear") {
        label {...textPair}
        error {...textPair}
      }
      birthMonthList: listField(fieldKey: "birthMonths") {...fieldOptionListValues}
      birthDayList: listField(fieldKey: "birthDays") {...fieldOptionListValues}
    }
    config {
      legalSubscribeAge: int(name: "legalSubscribeAge")
    }
  }

  ${textPairFragment}
  ${fieldOptionListValueFragment}
`;
