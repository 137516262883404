import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { softAuththenticationErrorVar } from './context/ApplicationContext/ApplicationContext';

const propTypes = { children: PropTypes.node.isRequired };

const authModalOpenEventName = 'authentication-modal:open';

export default function App({ children }) {
  const softAuthError = useReactiveVar(softAuththenticationErrorVar);

  useEffect(() => {
    if (!softAuthError) return () => {};

    document.dispatchEvent(new CustomEvent(authModalOpenEventName, {
      detail: { isSignIn: true, isRememberedCheckRequired: true },
    }));

    return () => { softAuththenticationErrorVar(null); };
  }, [softAuthError]);

  return children;
}

App.propTypes = propTypes;
