import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Modal } from 'anf-core-react';
import PropTypes from 'prop-types';
import {
  addPaymentRequest,
  updatePaymentRequest,
  deletePaymentRequest,
} from './paymentOperations';
import PaymentForm from './PaymentForm';
import { PaymentsContext } from './PaymentsContextProvider';
import TmntText from '../Common/Text/TmntText';

const defaultProps = {
  payment: undefined,
};

const propTypes = {
  isEditState: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  payment: PropTypes.shape({
    cardBrand: PropTypes.string.isRequired,
    cardExpireMonth: PropTypes.string.isRequired,
    cardExpireYear: PropTypes.string.isRequired,
    cardNumber: PropTypes.string.isRequired,
    displayInfo: PropTypes.string,
    id: PropTypes.string.isRequired,
    requiresCVV: PropTypes.bool,
  }),
};

export default function PaymentModal({
  isEditState,
  isOpen,
  onClose,
  onComplete,
  payment,
}) {
  const [defaultCardBrand, setDefaultCardBrand] = useState(undefined);
  const [defaultCardNumber, setDefaultCardNumber] = useState(undefined);
  const [defaultExpDate, setDefaultExpDate] = useState(undefined);
  const paymentsContext = useContext(PaymentsContext);
  const text = paymentsContext?.text;

  useEffect(() => {
    if (payment?.cardNumber && isEditState) {
      setDefaultCardBrand(payment.cardBrand);
      setDefaultCardNumber(payment.cardNumber);
      setDefaultExpDate(`${payment.cardExpireMonth}/${payment.cardExpireYear}`);
    } else {
      setDefaultCardBrand(undefined);
      setDefaultCardNumber(undefined);
      setDefaultExpDate(undefined);
    }
  }, [payment, isEditState]);

  const onCompleted = () => onComplete();

  const [addPaymentMutation] = useMutation(addPaymentRequest, {
    onCompleted,
  });

  const [updatePaymentMutation] = useMutation(updatePaymentRequest, {
    onCompleted,
  });

  const [deletePaymentMutation] = useMutation(deletePaymentRequest, {
    onCompleted,
  });

  const submitForm = async (card) => {
    if (!isEditState) {
      return addPaymentMutation({
        variables: {
          card: {
            cardBrand: card?.brand,
            cardExpireMonth: card?.expireMonth,
            cardExpireYear: card?.expireYear,
            cardFirstSix: card?.number.substr(0, 6),
            cardLastFour: card?.number.substr(card.number.length - 4),
            cardNumber: card?.encryptedNumber,
          },
        },
      })
        .then(({ data }) => {
          const { addPayment } = data;
          return addPayment;
        });
    }

    return updatePaymentMutation({
      variables: {
        expiration: {
          cardExpireMonth: card?.expireMonth,
          cardExpireYear: card?.expireYear,
        },
        id: payment.id,
      },
    })
      .then(({ data }) => {
        const { updatePayment } = data;
        return updatePayment;
      });
  };

  const submitDeletePayment = async () => (
    deletePaymentMutation({ variables: { id: payment.id } })
      .then(({ data }) => {
        const { deletePayment } = data;
        return deletePayment;
      })
  );

  const renderHeader = () => (
    <span className="h2" data-testid="payment-modal-header">
      <TmntText tmnt={isEditState ? text?.updatePaymentText : text?.addPaymentText} />
    </span>
  );

  return (
    <Modal
      id="payment-modal"
      isOpen={isOpen}
      heading={renderHeader()}
      onClose={onClose}
    >
      <PaymentForm
        defaultCardBrand={defaultCardBrand}
        defaultCardNumber={defaultCardNumber}
        defaultExpDate={defaultExpDate}
        isEditState={isEditState}
        onRemove={submitDeletePayment}
        onSubmit={submitForm}
        onSuccessfulSubmission={onClose}
      />
    </Modal>
  );
}
PaymentModal.defaultProps = defaultProps;
PaymentModal.propTypes = propTypes;
