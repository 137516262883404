import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  ErrorMessage,
  InputField,
} from 'anf-core-react';
import ButtonState from '../Common/ButtonState/ButtonState';
import FormWrapper from '../FormStructure/FormWrapper';
import FormGroup from '../FormStructure/FormGroup';
import FormCell from '../FormStructure/FormCell';
import { useCheckboxGroup, useEmailValidation } from '../../hooks';
import TmntText from '../Common/Text/TmntText';
import Icon from '../Common/Icon/Icon';
import { ERROR_MESSAGE, LOADING_MESSAGE } from '../Messages/Messages';
import {
  formResponseFragment,
  sectionedFieldOptionListValuesFragment,
  textPairFragment,
} from '../../query/fragments';

const unsubscribeRequest = gql`
  mutation unsubscribeRequest($email: String!, $selectedBrands: [String]) {
    unsubRequest: unsubscribeRequest(email: $email, selectedBrands: $selectedBrands) {
      ...formResponse
    }
  }

  ${formResponseFragment}
`;

const unsubscribeFormQuery = gql`
  query UnsubscribeFormQuery {
    textFor {
      formTitle: pair(pairKey: "unsubscribeFormTitle") {...textPair}
      email: textField(fieldKey: "email") {
        label {...textPair}
        error {...textPair}
      }
      buttonSubmit: pair (pairKey: "submit") {...textPair}
      buttonProcessing: pair (pairKey: "processing") {...textPair}
      buttonError: pair (pairKey: "error") {...textPair}
      buttonSuccess: pair (pairKey: "success") {...textPair}
      formError: pair(pairKey: "formError") {...textPair}
      unsubscribeCheckboxes: listFieldGroup(fieldKey: "unsubscribeCheckboxes") {
        ...sectionedFieldOptionListValues
      }
      unsubscribeLegalText: pair (pairKey: "unsubscribeLegalText") {...textPair}
    }
  }

  ${textPairFragment}
  ${sectionedFieldOptionListValuesFragment}
`;

// Text storage So mutation has access.
const allBrandOptions = [];

const propTypes = { onSuccessfulSubmit: PropTypes.func };
const defaultProps = { onSuccessfulSubmit: () => {} };

let textCache = null;

export default function UnsubscribeForm({ onSuccessfulSubmit }) {
  const [emailInput, setEmailInput] = useState('');
  const {
    isInvalid: isInvalidEmail, setIsInvalid: setIsInvalidEmail, validateEmail,
  } = useEmailValidation();
  const [formErrorText, setFormErrorText] = useState('');
  const [displayFormError, setDisplayFormError] = useState(false);

  const [
    selectedCheckboxes,
    setSelectedCheckboxes,
    updateCheckboxList,
    isSelectedCheckbox,
  ] = useCheckboxGroup([]);

  const [unsubMutation] = useMutation(unsubscribeRequest, {
    onCompleted: ({ unsubRequest }) => {
      if (unsubRequest.success) {
        onSuccessfulSubmit(selectedCheckboxes);
      } else {
        unsubRequest.errors.forEach((error) => {
          if (error.field === 'email') {
            setIsInvalidEmail(true);
          } else if (error.field === 'form' && error.text && error.text.value) {
            setFormErrorText(error.text.value);
          }
        });
        setDisplayFormError(true);
      }
    },
    onError: () => {
      setFormErrorText(textCache.formError?.value);
      setDisplayFormError(true);
    },
  });

  const { loading, error: queryError, data: queryData } = useQuery(unsubscribeFormQuery);

  if (loading) return LOADING_MESSAGE;
  if (queryError) return ERROR_MESSAGE;

  const { textFor } = queryData;
  const {
    formTitle,
    email,
    unsubscribeCheckboxes,
    buttonSubmit,
    buttonProcessing,
    buttonError,
    buttonSuccess,
    formError,
    unsubscribeLegalText,
  } = textFor;

  textCache = textFor;

  const validateSubscription = () => selectedCheckboxes.length;

  const handleOnSubmit = async () => {
    setDisplayFormError(false);
    setFormErrorText(formError.value);

    const emailIsValid = validateEmail(emailInput);
    const subscriptionIsValid = validateSubscription();

    if (!emailIsValid || !subscriptionIsValid) {
      setDisplayFormError(true);
      return Promise.reject();
    }

    return new Promise((resolve, reject) => {
      unsubMutation({
        variables: {
          email: emailInput,
          selectedBrands: selectedCheckboxes,
        },
      })
        .then(({ data }) => {
          const { unsubRequest } = data;
          const { success } = unsubRequest;
          if (!success) reject();
          else resolve();
        })
        .catch(() => { reject(); });
    });
  };

  const handleOnEmailChange = (event) => {
    const { value } = event.target;
    setEmailInput(value);
  };

  const onCheckboxChange = (event) => {
    const { checked, value } = event.target;
    if (checked) { // Add new selected value and unselect unsub
      setSelectedCheckboxes([...selectedCheckboxes, value]);
    } else {
      updateCheckboxList(event.target);
    }
  };

  const generateCheckboxArr = (optionsArr) => optionsArr.map((box) => ({
    ...box,
    isChecked: isSelectedCheckbox(box.optionValue),
    onChange: onCheckboxChange,
  }));

  const renderCheckboxDescription = ({
    desc, title,
  }) => (
    <>
      <span className="h4">
        <TmntText tmnt={title} />
      </span>
      { desc && <TmntText tmnt={desc} /> }
    </>
  );

  const renderCheckbox = (checkbox, prefix) => {
    const value = checkbox.optionValue;
    const id = `unsubscribeCheckBoxes${prefix}-${value}`;

    if (!allBrandOptions.find((brand) => brand.optionValue === value)) {
      allBrandOptions.push(checkbox);
    }

    return (
      <Checkbox
        description={renderCheckboxDescription({
          desc: checkbox?.description,
          title: checkbox.text,
        })}
        isChecked={checkbox.isChecked}
        id={id}
        key={id}
        name={`unsubscribeCheckboxes_${prefix}[]`}
        onChange={checkbox.onChange}
        value={value}
      />
    );
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} name="emailUnsubscribeForm" autoComplete="off" noValidate>
      <FormWrapper>
        <FormGroup>
          <FormCell>
            <TmntText tmnt={formTitle} />
          </FormCell>
          <FormCell>
            <InputField
              autoComplete="email"
              id="emailUnsubscribeForm_email"
              isInvalid={isInvalidEmail}
              label={email?.label?.value}
              onChange={handleOnEmailChange}
              isRequired
              type="email"
              value={emailInput}
            />
            {(isInvalidEmail && email?.error) && (
              <ErrorMessage id="unsub-form-email-error-msg" variant="error">
                <TmntText tmnt={email.error} />
              </ErrorMessage>
            )}
          </FormCell>
          {
            unsubscribeCheckboxes.map(({ label, optionSections }, index) => {
              const i = index;

              return (
                <FormCell key={`unsubscribeCheckboxGroup${i}`}>
                  <CheckboxGroup title={<TmntText tmnt={label} />}>
                    {
                      optionSections.map((section, jIndex) => {
                        const j = jIndex;
                        return (
                          <React.Fragment key={`unsubscribeCheckboxSectionFragment_${j}`}>
                            {
                              generateCheckboxArr(section).map(
                                (box) => renderCheckbox(box, `${j}`),
                              )
                            }
                            <hr />
                          </React.Fragment>
                        );
                      })
                    }
                  </CheckboxGroup>
                </FormCell>
              );
            })
          }
          {displayFormError && (
            <FormCell>
              <ErrorMessage id="emailUnsubscribeForm__errorMessage">
                {formErrorText}
              </ErrorMessage>
            </FormCell>
          )}
          <FormCell>
            <ButtonState
              asyncOnClick={handleOnSubmit}
              initial={(
                <Button
                  variant="secondary"
                  type="submit"
                  isFullWidth
                  isDisabled={!(selectedCheckboxes?.length)}
                >
                  <TmntText tmnt={buttonSubmit} />
                </Button>
              )}
              processing={(
                <Button variant="secondary" isProcessing isDisabled isFullWidth>
                  <TmntText tmnt={buttonProcessing} />
                </Button>
              )}
              error={(
                <Button variant="secondary" isDisabled isFullWidth>
                  <TmntText tmnt={buttonError} />
                </Button>
              )}
              success={(
                <Button variant="secondary" isDisabled isFullWidth>
                  <Icon icon="check" />
                  <TmntText tmnt={buttonSuccess} />
                </Button>
              )}
            />
          </FormCell>
          <FormCell>
            <TmntText tmnt={unsubscribeLegalText} />
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

UnsubscribeForm.propTypes = propTypes;
UnsubscribeForm.defaultProps = defaultProps;
