export const BASE_BFF_URI = '/api/bff/customer';

export function buildUri(base, query) {
  if (!base) throw new Error('buildUri() error - base URI not found');
  if (!query) return base;
  const queryStr = new URLSearchParams(query).toString();
  return `${base}?${queryStr}`;
}

export function buildBFFUri(query) {
  return buildUri(BASE_BFF_URI, query);
}
