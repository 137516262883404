/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';

const textProp = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.string,
});

const textFieldProp = PropTypes.shape({
  label: textProp.isRequired,
  error: textProp,
});

export const emailUsFormText = PropTypes.shape({
  formFieldName: textFieldProp.isRequired,
  formFieldEmail: textFieldProp.isRequired,
  formFieldOrderNum: textProp.isRequired,
  formFieldDropDown: textFieldProp.isRequired,
  formFieldComments: textFieldProp.isRequired,
  formReasons: textProp.isRequired,
  formError: textProp.isRequired,
});
