import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import ProfileWelcomeMessage from './ProfileWelcomeMessage';
import SignOutButton from './SignOutButton';
import NavigationLinks from './NavigationLinks';
import FormWrapper from '../FormStructure/FormWrapper';
import FormGroup from '../FormStructure/FormGroup';
import FormCell from '../FormStructure/FormCell';
import { ERROR_MESSAGE } from '../Messages/Messages';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import RewardsAccordion from './RewardsAccordion';
import ProgressBarContainer from './ProgressBarContainer';
import { LOGGED_IN_MENU_QUERY } from './operations';
import RecommendationSlidersContainer from '../Recommendations/RecommendationSlidersContainer';

const propTypes = { isMobileView: PropTypes.bool };
const defaultProps = { isMobileView: false };

let textCache = {};

export default function LoggedInMenu({ isMobileView }) {
  const {
    loading: isLoading, error: queryError, data: queryData = {}, refetch,
  } = useQuery(LOGGED_IN_MENU_QUERY, {
    ssr: false,
    context: { batch: true },
    refetchPolicy: 'network-only',
  });

  useEffect(() => {
    const refetchCallback = () => refetch();
    document.addEventListener('myInfo:updated', refetchCallback);
    return () => document.removeEventListener('myInfo:updated', refetchCallback);
  }, [refetch]);

  if (queryError) return ERROR_MESSAGE;

  const { textFor, config = {}, isProgressBarEnabled } = queryData;
  textCache = textFor;

  const handleMyAbercrombie = (url) => {
    const brand = window?.digitalData?.get('page.brand') || 'anf';
    const brandRewardsPage = brand === 'hol' ? 'HollisterHouseRewards' : 'MyAbercrombie';
    const pageFMUrl = url.includes('?')
      ? `${url}&pagefm=navigation+-+${brandRewardsPage}`
      : `${url}?pagefm=navigation+-+${brandRewardsPage}`;
    window.location.assign(pageFMUrl);
  };

  const repudiationMessage = textCache?.repudiationWarning?.value
    ? (<TmntText tmnt={textCache?.repudiationWarning} />)
    : undefined;

  const {
    loyaltyTierImageSrc = '',
    isLoyaltyEnabled = false,
    isLoyaltyCrossBorderRepudiation = false,
  } = config;
  const { hasSignInRecsEnabled = false } = queryData;

  const renderRecommendations = () => {
    if (!hasSignInRecsEnabled) return null;
    return <RecommendationSlidersContainer placements={['generic_page.account_flyout1']} clientId="account-flyout-1" useGrid />;
  };

  return (
    <div className="logged-in-menu" data-testid={!isLoading ? 'logged-in-menu' : 'logged-in-menu--loading'}>
      <FormWrapper>
        <FormGroup>
          <FormCell isLoading={isLoading}>
            <span className="h1">
              <TmntText tmnt={textCache?.yourAccountHeadingText} />
            </span>
          </FormCell>
          <FormCell isLoading={isLoading}>
            {
              !textCache?.repudiationWarning?.value
                ? (
                  <ProfileWelcomeMessage
                    memberSince={(
                      textCache?.joinedDateText?.value
                      && (
                        <span className="h3">
                          <TmntText tmnt={textCache?.joinedDateText} />
                        </span>
                      )
                    )}
                    tierImageSrc={loyaltyTierImageSrc}
                    tierImageAltText={textCache?.loyaltyTierImageAltText?.value}
                    nextRewardText={(
                      isProgressBarEnabled ? '' : textCache?.nextRewardText?.value
                    )}
                  >
                    <div className="h1">
                      <TmntText tmnt={textCache?.welcomeMessageText} />
                    </div>
                    {!isProgressBarEnabled && <TmntHtml tmnt={textCache?.nextRewardText} />}
                  </ProfileWelcomeMessage>
                )
                : repudiationMessage
            }
          </FormCell>
          {isLoyaltyEnabled
            && !isLoyaltyCrossBorderRepudiation
            && isProgressBarEnabled && <ProgressBarContainer />}
          {isLoyaltyEnabled
            && !isLoyaltyCrossBorderRepudiation
            && <RewardsAccordion isMobileView={isMobileView} />}
          <FormCell isLoading={isLoading}>
            {
              !textCache?.navLinks
                ? null
                : (
                  <NavigationLinks
                    links={textCache?.navLinks.options}
                    onMyAbercrombie={handleMyAbercrombie}
                  />
                )
            }
          </FormCell>
          { renderRecommendations() }
          <FormCell isLoading={isLoading}>
            <SignOutButton>
              <TmntText tmnt={textCache?.signOutButtonText} />
            </SignOutButton>
          </FormCell>
        </FormGroup>
      </FormWrapper>
    </div>
  );
}

LoggedInMenu.propTypes = propTypes;
LoggedInMenu.defaultProps = defaultProps;
