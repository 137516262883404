import React from 'react';
import hydrate from '../lib/hydrate';
import AddressPage from '../lib/components/Address/AddressPage';
import { AddressContextProvider } from '../lib/components/Address/AddressContextProvider';

function AddressPageFrontend() {
  return (
    <AddressContextProvider>
      <AddressPage />
    </AddressContextProvider>
  );
}

hydrate({
  frontend: 'AddressPageFrontend',
  component: AddressPageFrontend,
});
