import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Link as DSLink,
  DataCard,
  FlagBlock,
  IconBlock,
  Icon,
} from 'anf-core-react';
import TmntText from '../Common/Text/TmntText';
import useButtonState from '../Common/ButtonState/useButtonState';
import { addressProps } from './addressPropTypes';
import { AddressContext } from './AddressContextProvider';

const propTypes = {
  address: addressProps.isRequired,
  isDefault: PropTypes.bool.isRequired,
  onEditAddress: PropTypes.func.isRequired,
  onSetDefault: PropTypes.func.isRequired,
};

function AddressCard({
  address,
  isDefault,
  onEditAddress,
  onSetDefault,
}) {
  const { textFor } = useContext(AddressContext);
  const [renderButton, registerPromise] = useButtonState();
  const handleEditPayment = () => onEditAddress(address.id);
  const handleMakeDefault = () => {
    try {
      const call = new Promise((resolve, reject) => {
        onSetDefault(address.id)
          .then(({ data }) => {
            const { setDefaultAddress } = data;
            if (!setDefaultAddress?.success) reject();

            resolve();
          })
          .catch(() => { reject(); });
      });

      registerPromise(call);
    } catch { /* do nothing */ }
  };

  const renderHeader = () => (
    <DSLink
      onClick={handleEditPayment}
    >
      <TmntText tmnt={textFor?.buttonEdit} />
    </DSLink>
  );

  const renderName = () => {
    switch (address.country) {
      case 'JP':
      case 'HK':
        return <p>{`${address.lastName} ${address.firstName}`}</p>;
      default:
        return <p>{`${address.firstName} ${address.lastName}`}</p>;
    }
  };

  const renderStreetAddress = () => (
    <>
      <p>{address.address1}</p>
      {(address.address2 && address.address2.length > 0) && (
        <p>{address.address2}</p>
      )}
    </>
  );

  const renderCityStateZip = () => {
    switch (address.country) {
      case 'HK':
      case 'SG':
        return (
          <p>
            {address.state && `${address.state}, `}
            {address.city && `${address.city}, `}
            {address.district && `${address.district} `}
            {address.postalCode}
          </p>
        );
      case 'JP':
      default:
        return (
          <p>
            {address.city && `${address.city}, `}
            {address.state && `${address.state} `}
            {address.postalCode}
          </p>
        );
    }
  };

  const renderFlagBlock = () => (
    <p>
      <FlagBlock countryCode={address.country} countryName={address.country} />
    </p>
  );

  const renderDefaultStatus = () => (
    <>
      <hr className="address-data-card__hr" />
      <div className="address-data-card__default-status">
        <IconBlock
          orientation="horizontal"
          icon={<Icon icon="check" size="m" />}
        >
          <span className="h3">
            <TmntText tmnt={textFor?.cardDefaultText} />
          </span>
        </IconBlock>
      </div>
    </>
  );

  const renderDefaultAction = () => renderButton({
    initial: {
      onClick: handleMakeDefault,
      children: (<TmntText tmnt={textFor?.buttonMakeDefault} />),
      isFullWidth: true,
      variant: 'tertiary-dark',
    },
    processing: {
      children: (<TmntText tmnt={textFor?.buttonProcessing} />),
      isFullWidth: true,
      variant: 'tertiary-dark',
    },
    error: {
      children: (<TmntText tmnt={textFor?.buttonPleaseTryAgain} />),
      isFullWidth: true,
      variant: 'tertiary-dark',
    },
    success: {
      children: (<TmntText tmnt={textFor?.buttonSuccess} />),
      isFullWidth: true,
      variant: 'tertiary-dark',
    },
  });

  return (
    <div className="address-data-card">
      <DataCard
        headerContent={renderHeader()}
        headerTitle={textFor?.cardTitle.value}
        variant="boxed"
      >
        <div className="address-data-card__info">
          {renderName()}
          {renderStreetAddress()}
          {renderCityStateZip()}
          {renderFlagBlock()}
        </div>
        <div className="address-data-card__ctas">
          {isDefault ? (
            renderDefaultStatus()
          ) : (
            renderDefaultAction()
          )}
        </div>
      </DataCard>
    </div>
  );
}

AddressCard.propTypes = propTypes;

export default AddressCard;
