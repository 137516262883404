import React from 'react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  headingText: textProp.isRequired,
  subText: textProp.isRequired,
};

export default function PickedForYouHeading({
  headingText,
  subText,
}) {
  return (
    <div className="picked-for-you-header-container">
      <span className="h1" data-testid="picked-for-you-header">
        <TmntText tmnt={headingText} />
      </span>
      <div className="picked-for-you-subtext" data-testid="picked-for-you-subtext">
        <TmntText tmnt={subText} />
      </div>
    </div>
  );
}

PickedForYouHeading.propTypes = propTypes;
