import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  Button, InputField, ErrorMessage, FieldGroup, TooltipWidget,
} from 'anf-core-react';
import { TmntText } from '../Common/Text';
import { FormCell, FormGroup, FormWrapper } from '../FormStructure';
import isValidOrderId from '../../tools/isValidOrderId';
import isValidEmail from '../../tools/isValidEmail';
import { ORDERHELP_FORM_QUERY } from './operations';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { textProp } from '../../tools/customProps';

export default function OrderTrackingForm({
  orderNumberText, emailAddressText, orderNumberTooltipText, orderHelpButtonText, toolTipLabel,
}) {
  const [orderNumberInput, setOrderNumberInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [orderNumberValidErrMsg, setOrderNumberValidErrMsg] = useState('');
  const [emailInputInvalidErrMsg, setEmailInputInvalidErrMsg] = useState('');
  const [isTooltipOpen, setIsToolTipOpen] = useState(false);
  const [urlParams, setUrlParams] = useState({});
  const { data: queryData, loading, error: queryError } = useQuery(ORDERHELP_FORM_QUERY);

  useEffect(() => {
    if (queryData) {
      const { config } = queryData;
      const { orderDetailDisplayUrl } = config || {};
      const extractParamsFromUrl = (url) => {
        try {
          const urlObj = new URL(url, window.location.origin);
          const params = new URLSearchParams(urlObj.search);
          return {
            storeId: params.get('storeId'),
            catalogId: params.get('catalogId'),
            langId: params.get('langId'),
          };
        } catch {
          return {};
        }
      };
      const params = extractParamsFromUrl(orderDetailDisplayUrl);
      setUrlParams(params);
    }
  }, [queryData]);

  const { textFor, config } = queryData || {};
  const {
    orderNumberErrorValidation,
    emailAddressErrorValidation,
    fromOrderNumberText,
    formError,
  } = textFor || {};

  const { orderDetailDisplayUrl } = config || {};
  const { userType } = queryData || {};

  if (loading) return <FormCell isLoading />;
  if (queryError) return ERROR_MESSAGE;

  const validateOrderNumber = () => {
    const isValidOrderNumber = isValidOrderId(orderNumberInput);
    if (!isValidOrderNumber) {
      setOrderNumberValidErrMsg(orderNumberErrorValidation?.value);
    }
    return isValidOrderNumber;
  };

  const validateEmail = () => {
    const isValidEmailId = isValidEmail(emailInput);
    if (!isValidEmailId) {
      setEmailInputInvalidErrMsg(emailAddressErrorValidation?.value);
    }
    return isValidEmailId;
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setOrderNumberValidErrMsg('');
    setEmailInputInvalidErrMsg('');
    if (validateOrderNumber() && validateEmail()) {
      document.getElementById('order-tracking__form').submit();
    }
  };

  const renderToolTipWidget = () => {
    const handleTooltipOnClick = () => setIsToolTipOpen(!isTooltipOpen);
    const handleOnKeyUp = (event) => {
      event.stopPropagation();
      setIsToolTipOpen(event.key !== 'Escape');
    };

    return (
      <TooltipWidget
        buttonLabelText={toolTipLabel}
        contentPosition="top-center"
        contentID="help-order-form-tooltip"
        isOpen={isTooltipOpen}
        onClick={handleTooltipOnClick}
        onKeyUp={handleOnKeyUp}
      >
        <TmntText tmnt={orderNumberTooltipText} />
      </TooltipWidget>
    );
  };

  return (
    <form id="order-tracking__form" action={orderDetailDisplayUrl} method="post">
      <FormWrapper>
        <FormGroup>
          <h2>{fromOrderNumberText?.value}</h2>
          <input type="hidden" name="storeId" value={urlParams.storeId || ''} />
          <input type="hidden" name="catalogId" value={urlParams.catalogId || ''} />
          <input type="hidden" name="langId" value={urlParams.langId || ''} />
          <input type="hidden" name="userType" value={userType || ''} />
          <FormCell>
            <FieldGroup legend="Help Order Form">
              <InputField
                autoComplete="off"
                id="trackorder-ordernumber-input"
                name="orderNumber"
                label={orderNumberText}
                isInvalid={orderNumberValidErrMsg !== ''}
                onChange={(e) => setOrderNumberInput(e.target.value)}
                isRequired
                value={orderNumberInput}
              >
                {renderToolTipWidget()}
                {orderNumberValidErrMsg && (
                <ErrorMessage id="orderInput_errorMessage" variant="error">
                  {orderNumberValidErrMsg}
                </ErrorMessage>
                )}
              </InputField>
              <InputField
                autoComplete="email"
                id="trackorder-email-input"
                name="orderEmail"
                label={emailAddressText}
                isInvalid={emailInputInvalidErrMsg !== ''}
                onChange={(e) => setEmailInput(e.target.value)}
                type="email"
                isRequired
                value={emailInput}
              >
                {emailInputInvalidErrMsg && (
                <ErrorMessage id="orderemail_errorMessage" variant="error">
                  {emailInputInvalidErrMsg}
                </ErrorMessage>
                )}
              </InputField>
            </FieldGroup>
          </FormCell>
          {(orderNumberValidErrMsg || emailInputInvalidErrMsg) && (
          <ErrorMessage id="orderemail_errorMessage" variant="error">
            {formError?.value}
          </ErrorMessage>
          )}
          <FormCell />
          <Button isFullWidth variant="tertiary-light" className="self-service-button" onClick={handleOnSubmit}>
            {orderHelpButtonText}
          </Button>
        </FormGroup>
      </FormWrapper>
    </form>
  );
}

OrderTrackingForm.propTypes = {
  orderNumberText: PropTypes.string.isRequired,
  emailAddressText: PropTypes.string.isRequired,
  orderNumberTooltipText: textProp.isRequired,
  orderHelpButtonText: PropTypes.string.isRequired,
  toolTipLabel: PropTypes.string.isRequired,
};

OrderTrackingForm.defaultProps = {};
