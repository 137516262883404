// Prune TextPairs with the default tmnt return
const pruneEmptyTmnts = (_, entry) => {
  const { __typename, value } = entry || {};
  // Regex to match 5 or more consecutive question marks
  const questionMarksPattern = /\?{5,}/;

  return __typename === 'TextPair' && questionMarksPattern.test(value);
};

// Prune featureFlags
const pruneFeatureFlags = (key) => key.includes('featureFlag');

const rules = [pruneEmptyTmnts, pruneFeatureFlags];

export default rules;
