import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  password: PropTypes.string.isRequired,
};

export default function PasswordStrengthMeter({ password }) {
  const [strength, setStrength] = useState('Poor');

  function evaluateStrength(passwordVal) {
    if (!passwordVal) return 'Poor';

    const hasNumbers = (/\d/.test(passwordVal));
    const hasLowercase = (/[a-z]/.test(passwordVal));
    const hasUppercase = (/[A-Z]/.test(passwordVal));
    const hasSpecialChars = (/[^A-Za-z0-9]/.test(passwordVal));

    const minReqMet = (passwordVal.length >= 8 && hasNumbers && (hasLowercase || hasUppercase));
    const betterReqMet = (
      passwordVal.length >= 12
      && hasNumbers
      && hasLowercase
      && hasUppercase
      && hasSpecialChars
    );

    if (betterReqMet) return 'Better';
    if (minReqMet) return 'Good';

    return 'Poor';
  }

  useEffect(() => { setStrength(evaluateStrength(password)); }, [password]);

  return (
    <div className="pw-strength" data-pwstrength={strength}>
      <div className="pw-strength__bar">
        <span className="pw-strength__bar-fill" />
      </div>
      <div className="pw-strength__label">
        <p>
          Password Strength:
          <span className="pw-strength__score">
            {` ${strength}`}
          </span>
        </p>
      </div>
    </div>
  );
}

PasswordStrengthMeter.propTypes = propTypes;
