import React from 'react';
import PropTypes from 'prop-types';
import TmntText from '../Common/Text/TmntText';
import TmntHtml from '../Common/Text/TmntHtml';
import { textProp } from '../../tools/customProps';

export default function NumberOfItemsHeading({
  numberOfItems,
  headingText,
  subHeadingText,
}) {
  const subHeading = () => (
    numberOfItems === 0
      ? <TmntHtml tmnt={subHeadingText} />
      : <TmntText tmnt={subHeadingText} />
  );

  return (
    <div className="review-items-header-container">
      <span className="h1" data-testid="review-items-header">
        <TmntText tmnt={headingText} />
      </span>
      <div className="review-items-header-subtext" data-testid="review-items-header-subtext">
        {subHeading()}
      </div>
    </div>
  );
}

NumberOfItemsHeading.propTypes = {
  numberOfItems: PropTypes.number.isRequired,
  headingText: textProp.isRequired,
  subHeadingText: textProp.isRequired,
};
