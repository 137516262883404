import { useState } from 'react';
import { useMutation } from '@apollo/client';
import useLocalStorage from './useLocalStorage';
import { LOGIN_MUTATION } from '../components/SignIn/operations';
import useTealiumTrigger from './useTealiumTrigger';

const triggerPasswordMigrationModal = (email) => {
  document.dispatchEvent(
    new CustomEvent('passwordMigration:openModal', { detail: { email } }),
  );
};

const triggerForgotPasswordSuccess = () => {
  document.dispatchEvent(new CustomEvent('forgotPassword:loginSuccess'));
};

// Login handlers
export default function useSignInFormLogin({
  getPrimaryFormError,
  getValues,
  onFailedSubmission = () => {},
  onSuccessfulSubmission = () => {},
  setError,
}) {
  const [isLoyaltyConversionShown, setIsLoyaltyConversionShown] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] = useState(false);
  const [, setIsRemembered] = useLocalStorage('isRemembered', false);
  const trackInvalidEmail = useTealiumTrigger('universal', 'INVALID_EMAIL');
  const trackInvalidSignIn = useTealiumTrigger('universal', 'SIGN_IN_FAIL');

  const onSuccessfulLogin = (loginRes) => {
    const { isPasswordUpdateRequired } = loginRes;

    /*
      Trigger new event to initialize and show the interupted change password flow

      See runLogin.js and forgotPassword.js within CRS. Introduce new event to trigger
      the two operations defined below:

      ```runLogin.js - onSuccess
        ...
        // Initiate forgot password flow with no event passed
        forgotPwd.initForgotPasswordModal();
        // Trigger page refresh if required, on forgot password modal closure
        $(ANF).trigger('forgotPassword:loginSuccess');
      ```
    */
    if (isPasswordUpdateRequired) triggerForgotPasswordSuccess();

    // Note: we may not need to do this, but numerous modules do rely on this value in CRS
    setIsRemembered(false);
    onSuccessfulSubmission(loginRes);
  };

  const onFailedLogin = (loginRes) => {
    const { errors } = loginRes;
    const { message = {} } = errors?.find((err) => err?.message) || {};
    const { key = '' } = message;

    switch (key) {
      case '._ERR_USER_DOES_NOT_EXIST':
        setError('email', { message });
        setError('form', { message: getPrimaryFormError() });
        trackInvalidEmail();
        break;
      case '.ERR_TERMS_NOT_ACCEPTED':
        setIsLoyaltyConversionShown(true);
        break;
      case '.RESET_PW_USER_MIGRATED_FROM_SFCC':
        triggerPasswordMigrationModal(getValues('email'));
        break;
      case '.2030': // Mismatch email/password
      case '.2490': // Account temporary lock
        setError('form', { message });
        trackInvalidSignIn();
        break;
      default:
        setError('form', { message: getPrimaryFormError() });
        trackInvalidSignIn();
        break;
    }

    onFailedSubmission(loginRes);
  };

  const tempPwdLoggedIn = localStorage.getItem('tempPwdLoggedIn');

  const [runLogin] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ login }) => {
      // TODO: Tealium events - to be handled in https://anfcorp.atlassian.net/browse/PRO-720
      const { success, isPasswordUpdateRequired } = login;

      if (isPasswordUpdateRequired && tempPwdLoggedIn) {
        setIsChangePasswordModalOpen(true);
        return;
      }
      if (success) {
        onSuccessfulLogin(login);
        return;
      }

      onFailedLogin(login);
    },
    onError: (err) => { onFailedSubmission(err); },
  });

  return [runLogin, isLoyaltyConversionShown, isChangePasswordModalOpen];
}
