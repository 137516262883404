import React, { useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ErrorMessage } from 'anf-core-react';
import { useController, useForm } from 'react-hook-form';
import { useDatabridge } from '@xp-utilities/web';
import useButtonState from '../Common/ButtonState/useButtonState';
import ControlledInputField from '../Common/ControlledInputField/ControlledInputField';
import TmntHtml from '../Common/Text/TmntHtml';
import TmntText from '../Common/Text/TmntText';
import FormCell from '../FormStructure/FormCell';
import FormGroup from '../FormStructure/FormGroup';
import FormWrapper from '../FormStructure/FormWrapper';
import { ERROR_MESSAGE } from '../Messages/Messages';
import { POINTS_HISTORY_MISSING_POINTS_FORM, POINTS_HISTORY_MISSING_POINTS_FORM_MUTATION } from './operations';
import InvisibleReCaptcha from '../Common/ReCaptcha/InvisibleReCaptcha';

let textCache = null;
export const LOYALTY_BARCODE_INPUT_NAME = 'barcode-input';

export default function MissingPointsForm() {
  const [isInvisibleReCaptchaEnabled] = useDatabridge('flag.customer-invisible-recaptcha-switches.pointsHistory', false);
  const {
    data = {},
    loading: queryLoading,
    error: queryError,
  } = useQuery(POINTS_HISTORY_MISSING_POINTS_FORM);
  const [addOrderToPointsHistory] = useMutation(POINTS_HISTORY_MISSING_POINTS_FORM_MUTATION);
  const [renderButton, registerPromise] = useButtonState();
  const {
    control, getValues, handleSubmit, setError, setValue, watch,
  } = useForm();
  const { field: barCodeInputField } = useController({
    name: LOYALTY_BARCODE_INPUT_NAME,
    control,
  });
  const [formMessageTitle, setFormMessageTitle] = useState(null);
  const [formMessageDescription, setFormMessageDescription] = useState(null);
  const [variant, setVariant] = useState('error');
  const reCaptchaRef = useRef(null);

  const handleResponse = (response) => {
    const {
      data: {
        addOrderToPointsHistory:
          { success, messageTitle, messageDescription },
      },
    } = response;

    setFormMessageTitle(messageTitle);
    setFormMessageDescription(messageDescription);
    setVariant('success');
    setValue(LOYALTY_BARCODE_INPUT_NAME, '');

    return success;
  };

  const handleAddToOrderMutation = (orderNumber) => {
    try {
      const call = new Promise((resolve, reject) => {
        addOrderToPointsHistory({
          variables: {
            orderNumber,
          },
        })
          .then((response) => {
            const success = handleResponse(response);
            if (!success) {
              setVariant('error', variant);
              setError(LOYALTY_BARCODE_INPUT_NAME);
              reject();
            }
            resolve();
          });
      });
      registerPromise(call);
    } catch (error) {
      // do nothing
    }
  };

  const handleOnReCaptchaValidation = ({ isValid }) => {
    if (!isValid) {
      setError(LOYALTY_BARCODE_INPUT_NAME);
      setFormMessageTitle(textCache?.errorSomethingWentWrong);
      return;
    }
    const { [LOYALTY_BARCODE_INPUT_NAME]: orderNumber } = getValues();
    handleAddToOrderMutation(orderNumber);
  };

  if (queryLoading) return <FormCell isLoading />;
  if (queryError) return ERROR_MESSAGE;

  const { textFor = {} } = data;

  const {
    submit, inputPlaceholder, processing, addedToList, missingPointsDefaultErrorTitle,
    missingPointsDefaultErrorDescription,
  } = textFor;
  textCache = data.textFor;

  const { name } = barCodeInputField;

  const handleOnSubmit = handleSubmit(async (formData) => {
    const { [LOYALTY_BARCODE_INPUT_NAME]: orderNumber } = formData;
    if (!watch(LOYALTY_BARCODE_INPUT_NAME)) {
      setError(LOYALTY_BARCODE_INPUT_NAME);
      setVariant('error');
      setFormMessageTitle(missingPointsDefaultErrorTitle);
      setFormMessageDescription(missingPointsDefaultErrorDescription);
    } else {
      const isReCaptchaEnabled = isInvisibleReCaptchaEnabled();
      if (isReCaptchaEnabled) {
        reCaptchaRef.current.reset();
        reCaptchaRef.current.execute();
      } else {
        handleAddToOrderMutation(orderNumber);
      }
    }
  });
  const renderErrorMessage = (_variant) => (
    <ErrorMessage variant={_variant}>
      <div className="title">
        <TmntText tmnt={formMessageTitle} />
      </div>
      <div className="description">
        <TmntHtml tmnt={formMessageDescription} />
      </div>
    </ErrorMessage>
  );
  return (
    <div className="missing-points-form">
      <form
        onSubmit={handleOnSubmit}
      >
        <FormWrapper>
          <FormGroup>
            <FormCell>
              <ControlledInputField
                label={inputPlaceholder?.value}
                name={name}
                control={control}
                isRequired={false}
                id={LOYALTY_BARCODE_INPUT_NAME}
                autoComplete="off"
              >
                {renderErrorMessage(variant)}
              </ControlledInputField>
              {variant === 'success' && renderErrorMessage(variant)}
            </FormCell>
            <FormCell>
              {
                isInvisibleReCaptchaEnabled()
                  ? (
                    <InvisibleReCaptcha
                      ref={reCaptchaRef}
                      onValidation={handleOnReCaptchaValidation}
                    />
                  )
                  : null
                }
              {renderButton({
                initial: {
                  children: <TmntText tmnt={submit} />,
                  variant: 'secondary',
                  isFullWidth: true,
                  type: 'submit',
                },
                processing: {
                  children: <TmntText tmnt={processing} />,
                  variant: 'secondary',
                  isFullWidth: true,
                },
                success: {
                  children: <TmntText tmnt={addedToList} />,
                  variant: 'secondary',
                  isFullWidth: true,
                },
                error: {
                  children: <TmntText tmnt={submit} />,
                  variant: 'secondary',
                  isFullWidth: true,
                  type: 'submit',
                },
              })}
            </FormCell>
          </FormGroup>
        </FormWrapper>
      </form>
    </div>
  );
}
