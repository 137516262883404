import React from 'react';
import { IconBlock, Icon } from 'anf-core-react';
import { textProp } from '../../tools/customProps';
import TmntText from '../Common/Text/TmntText';

const propTypes = {
  changePasswordHeadingText: textProp.isRequired,
  createNewPasswordText: textProp.isRequired,
};

export default function ChangePasswordHeading({
  changePasswordHeadingText,
  createNewPasswordText,
}) {
  return (
    <>
      <IconBlock
        orientation="vertical"
        icon={<Icon icon="lock-large-anf" size="m" />}
      >
        <h2>
          <TmntText tmnt={changePasswordHeadingText} />
        </h2>
      </IconBlock>
      <div style={{
        fontSize: '15px',
        marginTop: '10px',
        textAlign: 'center',
      }}
      >
        <TmntText tmnt={createNewPasswordText} />
      </div>
    </>
  );
}

ChangePasswordHeading.propTypes = propTypes;
