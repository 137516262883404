import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const textPairPropType = PropTypes.shape({
  key: PropTypes.string,
  value: PropTypes.string,
});

export const PaymentsContext = createContext();

export function PaymentsContextProvider({ children, context }) {
  return (
    <PaymentsContext.Provider value={context}>
      {children}
    </PaymentsContext.Provider>
  );
}

PaymentsContextProvider.defaultProps = {
  context: {
    text: {
      addPaymentText: undefined,
      buttonAddNew: undefined,
      buttonEdit: undefined,
      buttonMakeDefault: undefined,
      cardAriaEndingIn: undefined,
      cardDefaultText: undefined,
      cardTitle: undefined,
      formError: undefined,
      noPayments: undefined,
      pageTitle: undefined,
      paymentApiError: undefined,
      paymentLimitError: undefined,
      paymentModalLegalText: undefined,
      pleaseTryAgain: undefined,
      processing: undefined,
      removePaymentText: undefined,
      submitPaymentError: undefined,
      success: undefined,
      updatePaymentText: undefined,
    },
    config: {
      pxpExponent: undefined,
      pxpModulus: undefined,
    },
    ccRegexArray: undefined,
    paymentCount: undefined,
  },
};

PaymentsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  context: PropTypes.shape({
    text: PropTypes.shape({
      addPaymentText: textPairPropType,
      buttonAddNew: textPairPropType,
      buttonEdit: textPairPropType,
      buttonMakeDefault: textPairPropType,
      cardAriaEndingIn: textPairPropType,
      cardDefaultText: textPairPropType,
      cardTitle: textPairPropType,
      formError: textPairPropType,
      noPayments: textPairPropType,
      pageTitle: textPairPropType,
      paymentApiError: textPairPropType,
      paymentModalLegalText: textPairPropType,
      pleaseTryAgain: textPairPropType,
      processing: textPairPropType,
      removePaymentText: textPairPropType,
      submitPaymentError: textPairPropType,
      success: textPairPropType,
      updatePaymentText: textPairPropType,
    }),
    config: PropTypes.shape({
      pxpExponent: PropTypes.string,
      pxpModulus: PropTypes.string,
    }),
    ccRegexArray: PropTypes.arrayOf(
      PropTypes.shape({
        brand: PropTypes.string,
        icon: PropTypes.string,
        regexValue: PropTypes.instanceOf(RegExp),
      }),
    ),
    paymentCount: PropTypes.number,
  }),
};
