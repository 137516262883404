import { gql } from '@apollo/client';
import {
  textPairFragmentStr, reviewItemFragmentStr,
} from '../../query/fragmentStrings';

export const LOYALTY_POINTS_CARD_QUERY = gql`
  query LOYALTY_POINTS_CARD_QUERY {
    textFor {
      myPointsDescription: nonCachedPair(pairKey: "myPointsDescription") {...textPair}
      pointsForReview: pair(pairKey: "pointsForReview") {...textPair}
      pointsForSubmittingReviewDesc: pair(pairKey: "pointsForSubmittingReviewDesc") {...textPair}
      pointsForAddingPhoto: pair(pairKey: "pointsForAddingPhoto") {...textPair}
      pointsForAddingAPhotoDesc: pair(pairKey: "pointsForAddingAPhotoDesc") {...textPair}
    }
    config {
      maxProgressBarValue: int(name: "myPointsMaxProgressBarValue")
      currentProgressBarValue: int(name: "myPointsCurrentProgressBarValue")
    }
  }
  ${textPairFragmentStr}
`;

export const REVIEW_ITEMS_LIST_QUERY = gql`
  query REVIEW_ITEMS_LIST_QUERY {
    textFor {
      writeAReviewText: pair(pairKey: "writeAReviewText") {...textPair}
    }
    config {
      bazaarVoiceReviewSdkUrl: string(name: "bazaarVoiceReviewSdkUrl")
    }
    getAllReviewItems {
      reviewSubmittedNote {...textPair}
      headingText {...textPair}
      subHeadingText {...textPair}
      reviewItems {
        ...reviewItem
      }
    }
  }
  ${textPairFragmentStr}
  ${reviewItemFragmentStr}
`;

export const WRITE_A_REVIEW_PAGE_CONFIG = gql`
  query WRITE_A_REVIEW_BLOCK_QUERY {
    userState { isLoggedIn }
    config {
      isLoyaltyEnabled: bool(name: "isLoyaltyEnabled")
      isWriteAReviewEnabled: bool(name: "isWriteAReviewEnabled")
    }
  }
`;
