export default function sha256(text) {
  return new Promise((resolve, reject) => {
    const msgBuffer = new TextEncoder().encode(text);

    crypto.subtle.digest('SHA-256', msgBuffer)
      .then((hashBuffer) => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
        resolve(hashHex);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
